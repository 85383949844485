import { Box, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  OTPInput,
  PsButton,
  PsInput,
} from "../../../lib/psElements/psElements";
import { useAuthorizationAPICalls } from "../../../services/api/authorizationAPIMethods";
import psValidate from "../../../services/validation";
import { darkTheme } from "../../../styles/psMuiTheme/colorCodes";
import GoBackButton from "../../common/GoBackButton/GoBackButton";

interface phoneInputForLoginProps {
  formik: any;
}

interface oTPForPhoneNumberProps {
  phoneNumber: string;
  sendOtpOnMobile: any;
  handlerLoginWithPhoneAndOtp: any;
}

interface CounterComponentType {
  cb: () => void;
}

const CounterComponent: any = (props: CounterComponentType) => {
  const [counter, setCounter] = useState(0);
  const count: number = 60;
  let stampTemp: any = "";
  let tick = count;
  let intCount = 0;

  const reset = () => {
    tick = count;
    intCount = 0;
    setCounter(count);
  };

  const startCounter = () => {
    reset();
    if (stampTemp) {
      clearInterval(stampTemp);
      stampTemp = "";
    }
    stampTemp = setInterval(() => {
      intCount = intCount + 1;
      tick = tick - 1;
      setCounter(tick);
      if (intCount >= count) {
        clearInterval(stampTemp);
        setCounter(count);
        if (props.cb) {
          props.cb();
        }
      }
    }, 1000);
  };

  useEffect(() => {
    startCounter();
  }, []);
  if (counter) return <>resend OTP in {counter} seconds</>;
  return null;

  // return <>{counter && <>resend OTP in {counter} seconds</>}</>;
};
const OTPForPhoneNumber: FunctionComponent<oTPForPhoneNumberProps> = ({
  phoneNumber,
  sendOtpOnMobile,
  handlerLoginWithPhoneAndOtp,
}: oTPForPhoneNumberProps) => {
  const [otpString, setOtpString] = useState("");
  const [showHideResendOtp, setShowHideResendOtp] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const [otp6, setOtp6] = useState("");

  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const ref5 = useRef<any>(null);
  const ref6 = useRef<any>(null);

  useEffect(() => {
    setOtpString(otp1 + otp2 + otp3 + otp4 + otp5 + otp6);
  }, [otp1, otp2, otp3, otp4, otp5, otp6]);

  const handlerOTPInput = (e: any) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    const ename = name.split("-")[1];
    console.log(ename);
    switch (ename) {
      case "1":
        setOtp1(value);
        if (value && ref2) {
          ref2.current.focus();
        }
        break;
      case "2":
        setOtp2(value);
        if (value && ref3) {
          ref3.current.focus();
        }
        break;
      case "3":
        setOtp3(value);
        if (value && ref4) {
          ref4.current.focus();
        }
        break;
      case "4":
        setOtp4(value);
        if (value && ref5) {
          ref5.current.focus();
        }
        break;
      case "5":
        setOtp5(value);
        if (value && ref6) {
          ref6.current.focus();
        }
        break;
      case "6":
        setOtp6(value);
        break;
      default:
        break;
    }
  };

  const handlerResendOtp = () => {
    sendOtpOnMobile();
    setShowHideResendOtp(false);
  };

  const handlerLoginWithPhoneNumber = () => {
    // console.log("length", otpString.length, ":", otpString);
    if (otpString.length === 6) handlerLoginWithPhoneAndOtp(otpString);
  };

  return (
    <Stack direction="column" sx={{ gap: "2.5rem" }}>
      <span className="leftAlign">
        An OTP has been sent to +91-{phoneNumber}
      </span>
      <div className="otpInputGroup">
        <OTPInput
          type="text"
          name="otp-1"
          onChange={(e: any) => handlerOTPInput(e)}
          value={otp1}
          inputRef={(e: any) => (ref1.current = e)}
          inputProps={{ maxLength: 1 }}
        />
        <OTPInput
          type="text"
          name="otp-2"
          onChange={(e: any) => handlerOTPInput(e)}
          value={otp2}
          inputRef={(e: any) => (ref2.current = e)}
          inputProps={{ maxLength: 1 }}
        />
        <OTPInput
          type="text"
          name="otp-3"
          onChange={(e: any) => handlerOTPInput(e)}
          value={otp3}
          inputRef={(e: any) => (ref3.current = e)}
          inputProps={{ maxLength: 1 }}
        />
        <OTPInput
          type="text"
          name="otp-4"
          onChange={(e: any) => handlerOTPInput(e)}
          value={otp4}
          inputRef={(e: any) => (ref4.current = e)}
          inputProps={{ maxLength: 1 }}
        />
        <OTPInput
          type="text"
          name="otp-5"
          onChange={(e: any) => handlerOTPInput(e)}
          value={otp5}
          inputRef={(e: any) => (ref5.current = e)}
          inputProps={{ maxLength: 1 }}
        />
        <OTPInput
          type="text"
          name="otp-6"
          onChange={(e: any) => handlerOTPInput(e)}
          value={otp6}
          inputRef={(e: any) => (ref6.current = e)}
          inputProps={{ maxLength: 1 }}
        />
      </div>

      <Typography
        align="right"
        className="cursorPointer"
        sx={{ color: darkTheme.colors.button.textBlue }}
      >
        {!showHideResendOtp && (
          <CounterComponent
            cb={() => {
              setShowHideResendOtp(true);
            }}
          />
        )}
        {showHideResendOtp && (
          <span onClick={handlerResendOtp}>Resent OTP</span>
        )}
      </Typography>
      <PsButton.Primary
        variant="contained"
        sx={{ height: "3rem" }}
        onClick={handlerLoginWithPhoneNumber}
      >
        Submit
      </PsButton.Primary>
    </Stack>
  );
};

const PhoneInputForLogin: FunctionComponent<phoneInputForLoginProps> = ({
  formik,
}) => {
  return (
    <Stack direction="column" sx={{ gap: "2.5rem" }}>
      <PsInput
        label="Phone Number"
        type="number"
        variant="outlined"
        {...formik.getFieldProps("phoneNumber")}
        error={!!formik.errors.phoneNumber}
        helperText={
          formik.touched.phoneNumber && formik.errors.phoneNumber
            ? formik.errors.phoneNumber
            : ""
        }
      />
      <PsButton.Primary
        variant="contained"
        className="hoverOrange"
        type="submit"
        sx={{ height: "3rem" }}
      >
        Send OTP
      </PsButton.Primary>
    </Stack>
  );
};

function LoginByPhoneNumber() {
  const [otpWindow, setOtpWindow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();
  const { getLoginOtpOnPhone, loginWithPhoneAndOtp } =
    useAuthorizationAPICalls();

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
    },
    onSubmit: (values: { phoneNumber: string }) => {
      setPhoneNumber(values.phoneNumber);
      sendOtpOnMobile(values.phoneNumber);
    },
    validationSchema: Yup.object({
      phoneNumber: psValidate.schema.mobile,
    }),
  });

  const sendOtpOnMobile = (phone: string) => {
    let tempPhoneNumber = phone ? phone : phoneNumber;
    getLoginOtpOnPhone({
      otp: "",
      mobile: tempPhoneNumber,
      loginMethod: "otp_mo",
    })
      .then((result) => {
        console.log(result);
        if (result?.data?.status === 200) {
          setOtpWindow(true);
        }
      })
      .catch((e) => console.log(e));
  };

  const handlerLoginWithPhoneAndOtp = (otpString: string) => {
    loginWithPhoneAndOtp({
      otp: otpString,
      mobile: phoneNumber,
      loginMethod: "otp_mo",
    });
  };

  const handlerBackButton = () => {
    if (otpWindow) setOtpWindow(false);
    else navigate("/authorization/login");
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className="loginByPhoneNumber">
        <GoBackButton handlerBackButton={handlerBackButton} />
        <div>
          <h1 className="psHeading h1">Login with Phone Number</h1>
        </div>
        {!otpWindow && <PhoneInputForLogin {...{ formik }} />}
        {otpWindow && (
          <OTPForPhoneNumber
            {...{ phoneNumber, sendOtpOnMobile, handlerLoginWithPhoneAndOtp }}
          />
        )}
      </Box>
    </form>
  );
}

export default LoginByPhoneNumber;
