import { useDispatch } from "react-redux";
import { UserTypeEnum } from "../../constants/types/userTypes";
import { setLocalStorage, showErrorToast } from "../commonFunctions";
import {
  useStudentNaviagation,
  useTeacherNaviagation,
} from "../hooks/student/navigationHooks";
import { showHideLoader } from "../storage/common";
import { loginErrorMessage } from "../storage/error";
import { personalDetail } from "../storage/student/profile";
import psApiService from "./apiService";

interface loginUsingEmailPasswordApiParams {
  email: string;
  password: string;
  loginMethod: string;
}
interface loginByPhoneApiParams {
  mobile: string;
  otp: string;
  loginMethod: string;
}

interface regiserUsingEmailPasswordAPIParams {
  email: string;
  password: string;
  loginMethod: string;
  firstName: string;
  lastName: string;
  userType: string;
}

export const useAuthorizationAPICalls = () => {
  const dispatch = useDispatch();
  const studentNavigate = useStudentNaviagation();
  const teacherNavigate = useTeacherNaviagation();

  const storeLoginDataInStorage = (data: any) => {
    console.log(data);
    const userData = data?.data;
    setLocalStorage("loggedInUser", userData);
  };

  const navigateAsPerUserType = (data: any) => {
    console.log(data);
    const userData = data?.data;
    const userType = String(userData.userType).toLowerCase();
    dispatch(personalDetail({ userType }));
    if (userType === UserTypeEnum.Teacher) {
      teacherNavigate.navigateToTeacherDashboard();
    }
    if (userType === UserTypeEnum.Student) {
      studentNavigate.navigateToCongratulations();
    }
  };

  const errorFunction = (error: any) => {
    dispatch(showHideLoader(false));
    console.log(error);
  };

  const studentLoginHandler = (result: any) => {
    setLocalStorage("loggedInUser", result?.data.data);
    dispatch(showHideLoader(false));
    if (result?.data.status === 200 || result?.data.status === 201) {
      storeLoginDataInStorage(result?.data);
      navigateAsPerUserType(result?.data);
    } else {
      if (result?.data?.message) {
        const { message } = result?.data;
        showErrorToast(message, true);
        dispatch(loginErrorMessage(message));
      }
    }
  };

  const loginUsingEmailPassword = (
    inputObj: loginUsingEmailPasswordApiParams,
  ) => {
    console.log("login usin email and password API function");
    dispatch(showHideLoader(true));
    psApiService("student.login.email", inputObj)
      .then((result: any) => {
        studentLoginHandler(result);
      })
      .catch(errorFunction);
  };

  const getLoginOtpOnPhone = (inputObj: loginByPhoneApiParams) => {
    dispatch(showHideLoader(true));
    return psApiService("student.login.phone", inputObj)
      .then((result: any) => {
        if (result?.data.status === 200) {
        } else {
          if (result?.data?.message) {
            const { message } = result?.data;
            // showErrorToast(message, true);
            // dispatch(loginErrorMessage(message));
          }
        }
        dispatch(showHideLoader(false));
        return result;
      })
      .catch(errorFunction);
  };
  const loginWithPhoneAndOtp = (inputObj: loginByPhoneApiParams) => {
    dispatch(showHideLoader(true));
    return psApiService("student.login.phoneAndOtp", inputObj)
      .then((result: any) => {
        studentLoginHandler(result);
        // if (result?.data.status === 200) {
        //   setLocalStorage("loggedInUser", result?.data.data);
        //   dispatch(showHideLoader(false));
        //   storeLoginDataInStorage(result?.data);
        //   if (result.data.meta === null) {
        //     studentNavigate.navigateToCongratulations();
        //   } else {
        //     studentNavigate.navigateToStudentDashboard();
        //   }
        // } /* else if (result?.data.status === 201) {
        //   // if (result.data.meta === null) {
        //   navigateToCongratulations();
        //   // } else {+-*
        //   //   navigateToStudentDashboard();
        //   // }
        //   storeLoginDataInStorage(result?.data);
        // } */ else {
        //   if (result?.data?.message) {
        //     const { message } = result?.data;
        //     showErrorToast(message, true);
        //     // dispatch(loginErrorMessage(message));
        //   }
        // }
        return result;
      })
      .catch(errorFunction);
  };
  // const getStudentProfileDetails = () => {
  //   return psApiService("student.profile.get", {})
  //     .then((result: any) => {
  //       if (result?.data.status === 200) {
  //         if (result.data.meta === null) {
  //           navigateToProfileSteps();
  //         } else {
  //           navigateToStudentDashboard();
  //         }
  //         storeLoginDataInStorage(result?.data);
  //       } else {
  //         if (result?.data?.message) {
  //           const { message } = result?.data;
  //           showErrorToast(message, true);
  //           // dispatch(loginErrorMessage(message));
  //         }
  //       }
  //       return result;
  //     })
  //     .catch((error) => console.log(error));
  // };

  const registerUsingEmailPassword = (
    inputObj: regiserUsingEmailPasswordAPIParams,
  ) => {
    console.log("register using email and password API function");
    dispatch(showHideLoader(true));
    psApiService("student.register", inputObj)
      .then((result: any) => {
        studentLoginHandler(result);
        // dispatch(showHideLoader(false));
        // setLocalStorage("loggedInUser", result?.data.data);
        // if (result?.data.status === 200 || result?.data.status === 201) {
        //   studentNavigate.navigateToCongratulations();
        //   storeLoginDataInStorage(result?.data);
        // } else {
        //   if (result?.data?.message) {
        //     const { message } = result?.data;
        //     showErrorToast(message, true);
        //     dispatch(loginErrorMessage(message));
        //   }
        // }
        return result;
      })
      .catch(errorFunction);
  };
  return {
    loginUsingEmailPassword,
    getLoginOtpOnPhone,
    loginWithPhoneAndOtp,
    // getStudentProfileDetails,
    registerUsingEmailPassword,
  };
};
