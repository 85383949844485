import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import { FormControl, FormHelperText, Grid2 } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { PsInput } from "../../../../lib/psElements/psElements";
import InputFileUpload from "../../../common/buttons/Upload";

interface Props {
  formik: any;
  handleSubmit: (region: any) => void;
  handlerInputChange: (region: any) => void;
  handlePanCardFile: (file: any) => void;
}
export const BankDetail: React.FC<Props> = ({
  formik,
  handleSubmit,
  handlerInputChange,
  handlePanCardFile,
}) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ fontSize: "20px" }}
        >
          Bank Details <InfoIcon sx={{ mx: "5px" }} />
        </AccordionSummary>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <Grid2
            sx={{
              px: 10,
              display: "grid",
              gridTemplateColumns: "repeat(1, 1fr)",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid2>
              <FormControl fullWidth>
                <Grid2>
                  <Grid2 sx={{ display: "inline-block" }}>
                    <PsInput
                      label="Bank Name"
                      sx={{ width: "140%" }}
                      name="bankName"
                      value={formik.values.bankName}
                      onChange={handlerInputChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.bankName &&
                        Boolean(formik.errors.bankName)
                      }
                    />
                    <FormHelperText
                      sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                    >
                      {formik.touched.bankName && formik.errors.bankName}
                    </FormHelperText>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <Grid2>
                  <Grid2 sx={{ display: "inline-block" }}>
                    <PsInput
                      label="Bank Holder Name"
                      sx={{ width: "140%" }}
                      name="bankingName"
                      value={formik.values.bankingName}
                      onChange={handlerInputChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.bankingName &&
                        Boolean(formik.errors.bankingName)
                      }
                    />
                    <FormHelperText
                      sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                    >
                      {formik.touched.bankingName && formik.errors.bankingName}
                    </FormHelperText>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <Grid2>
                  <Grid2 sx={{ display: "inline-block" }}>
                    <PsInput
                      label="IFSC code"
                      sx={{ width: "140%" }}
                      name="IFSC"
                      value={formik.values.IFSC}
                      onChange={handlerInputChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.IFSC && Boolean(formik.errors.IFSC)}
                    />
                    <FormHelperText
                      sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                    >
                      {formik.touched.IFSC && formik.errors.IFSC}
                    </FormHelperText>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <Grid2>
                  <Grid2 sx={{ display: "inline-block" }}>
                    <PsInput
                      label="Account Number"
                      sx={{ width: "140%" }}
                      name="AccountNumber"
                      value={formik.values.AccountNumber}
                      onChange={handlerInputChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.AccountNumber &&
                        Boolean(formik.errors.AccountNumber)
                      }
                    />
                    <FormHelperText
                      sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                    >
                      {formik.touched.AccountNumber &&
                        formik.errors.AccountNumber}
                    </FormHelperText>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <Grid2>
                  <Grid2 sx={{ display: "inline-block" }}>
                    <PsInput
                      label="PAN Number"
                      sx={{ width: "140%" }}
                      name="PANno"
                      value={formik.values.PANno}
                      onChange={handlerInputChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.PANno && Boolean(formik.errors.PANno)
                      }
                    />
                    <FormHelperText
                      sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                    >
                      {formik.touched.PANno && formik.errors.PANno}
                    </FormHelperText>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <InputFileUpload
                  label="Upload Document"
                  onChange={(e) => handlePanCardFile(e)}
                  // name={Pancard.certificateBlobURL}
                  accept=".pdf,.doc,.docx"
                  name={formik.values.Pancard}
                />
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <Grid2>
                  <Grid2 sx={{ display: "inline-block", mt: "5px" }}>
                    <PsInput
                      label="Branch Name"
                      sx={{ width: "140%" }}
                      name="branch"
                      value={formik.values.branch}
                      onChange={handlerInputChange}
                      onBlur={formik.branch}
                      error={
                        formik.touched.branch && Boolean(formik.errors.branch)
                      }
                    />
                    <FormHelperText
                      sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                    >
                      {formik.touched.branch && formik.errors.branch}
                    </FormHelperText>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
          </Grid2>
        </form>
      </Accordion>
    </div>
  );
};
