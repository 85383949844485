import { FunctionComponent } from "react";

import { useDispatch, useSelector } from "react-redux";
import { showHideSideMenu } from "../../../../services/storage/common";
import { RootState } from "../../../../services/storage/store";
import MiniDrawer from "./MiniDrawer";

const SideMenu: FunctionComponent = () => {
  const {
    common: { sideMenuShowHide },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handlerShowHideMenu = () => {
    dispatch(showHideSideMenu(true));
  };
  const showHide: boolean = sideMenuShowHide;
  return (
    <>
      <MiniDrawer />
    </>
  );
};

export default SideMenu;
