import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { stepBarConstants } from "./StepbarConst";
interface Stepbar {
  currentStep: number;
}

const Stepsbar: FunctionComponent<Stepbar> = ({ currentStep }: Stepbar) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (currentStep > -1) setActiveStep(currentStep);
  }, [currentStep]);

  return (
    <Stepper activeStep={activeStep} sx={{ mb: 4 }} alternativeLabel>
      {stepBarConstants.map((item) => {
        return (
          <Step key={item.count}>
            <StepLabel>{item.label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default Stepsbar;
