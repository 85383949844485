import { useSelector } from "react-redux";
import { UserTypeEnum } from "../../constants/types/userTypes";
import { setLocalStorage } from "../commonFunctions";
import { RootState } from "../storage/store";
import {
  useStudentNaviagation,
  useTeacherNaviagation,
} from "./student/navigationHooks";

export const useAuthorizationHooks = () => {
  const studentNavigate = useStudentNaviagation();
  // const teacherNavigate = useTeacherNaviagation();
  const { profile } = useSelector((state: RootState) => state.studentProfile);
  const { navigateToTeacherDashboard } = useTeacherNaviagation();
  const logoutHandler = () => {
    setLocalStorage("loggedInUser", "");
    setLocalStorage("profileInfo", "");
    studentNavigate.navigateToLoginSelection();
  };

  const checkUserTypeToNavigateOnDashboard = () => {
    const userType = String(profile.userType).toLowerCase();
    if (userType === UserTypeEnum.Teacher) {
      navigateToTeacherDashboard();
    }
  };

  return {
    logoutHandler,
    checkUserTypeToNavigateOnDashboard,
  };
};
