import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  Accordion,
  AccordionSummary,
  Box,
  Chip,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FormikProvider } from "formik";
import React from "react";

interface Props {
  formik: any;
  handleSubmit: (region: any) => void;
  handlerInputChange: (region: any) => void;
  Subject: any;
}

export const SubjectsTaught: React.FC<Props> = ({
  formik,
  handlerInputChange,
  Subject,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleDelete = (valueToDelete: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const newValues = formik.values.subjectTaught.filter(
      (item: string) => item !== valueToDelete,
    );

    formik.setFieldValue("subjectTaught", newValues);
  };

  return (
    <div style={{ margin: "0px 0px 10px 0px " }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ fontSize: "20px" }}
        >
          Subjects Taught <InfoIcon sx={{ mx: "5px" }} />
        </AccordionSummary>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Grid2
              sx={{
                px: 10,
                display: "grid",
                gridTemplateColumns: { md: "repeat(3, 1fr)" },
                gap: "10px",
                mb: "20px",
              }}
            >
              <Grid2>
                <FormControl fullWidth>
                  <InputLabel id="select-multiple-chip-label">
                    Subject Taught
                  </InputLabel>
                  <Select
                    labelId="select-multiple-chip-label"
                    label="Subject Taught"
                    name="subjectTaught"
                    value={formik.values.subjectTaught || []}
                    onChange={handlerInputChange}
                    onBlur={formik.handleBlur}
                    MenuProps={MenuProps}
                    multiple
                    renderValue={(selected) =>
                      Array.isArray(selected) && selected.length > 0 ? (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              onDelete={handleDelete(value)}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            />
                          ))}
                        </Box>
                      ) : (
                        <span>Select a subject</span>
                      )
                    }
                  >
                    {Array.isArray(Subject) &&
                      Subject.map((subject, index) => (
                        <MenuItem key={index} value={subject}>
                          {subject}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
          </form>
        </FormikProvider>
      </Accordion>
    </div>
  );
};
