import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import _ from "lodash";
import { FunctionComponent } from "react";
import { COUNTRIES_LIST, CountryListType } from "../../../constants/country";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";

export interface CountryListComponentPropTypes {
  selectedVal: string;
  countryList: string[];
  handlerOnChange?: (e: any) => void;
  formik?: any;
}

const CountrySelect: FunctionComponent<CountryListComponentPropTypes> = (
  props,
) => {
  const { selectedVal, countryList, formik } = props;

  const handleChange = (e: any) => {
    console.log(e.target.getAttribute("data-value"));
    if (typeof props.handlerOnChange === "function") {
      const val = e.target.getAttribute("data-value");
      if (val) {
        props?.handlerOnChange({ target: { value: val } });
      }
    }
  };

  // const handleKeyUp = (e: any) => {
  //   console.log(e);
  //   if (typeof props.handlerOnChange === "function") {
  //     const val = e.target.getAttribute("data-value");
  //     if (val) {
  //       props?.handlerOnChange({ target: { value: val } });
  //     }
  //   }
  // };
  const getTheFlagCode = (countryName: string) => {
    let countryCode: CountryListType = {
      code: "",
      label: "",
      phone: "",
    };

    _.forEach(COUNTRIES_LIST, (item) => {
      if (
        String(item.label).toLowerCase() == String(countryName).toLowerCase()
      ) {
        countryCode = item;
        return false;
      }
    });
    return countryCode;
  };

  const formattedCountryFlagTitle = (code: CountryListType) => {
    return code.label + " (" + code.code + ") " + code.phone;
  };

  return (
    <Autocomplete
      id="country-select-demo"
      fullWidth
      // sx={{ width: 300 }}
      options={countryList}
      autoHighlight
      className="testCountryList"
      // getOptionLabel={(option) => option.code}
      getOptionLabel={(option) => getTheFlagCode(option).label}
      onChange={(e) => handleChange(e)}
      // onKeyUp={(e) => handleKeyUp(e)}
      value={selectedVal}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const optionObj = getTheFlagCode(option);
        return (
          <Box
            key={_.uniqueId()}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              mt: 1,
              alignItems: "center",
              display: "flex",
              "&:hover": {
                borderWidth: 0,
              },
            }}
            {...optionProps}
            className="countryOptionList hoverOrange"
            data-value={optionObj.label}
          >
            <img
              loading="lazy"
              width="40"
              srcSet={`https://flagcdn.com/w40/${optionObj.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w40/${optionObj.code.toLowerCase()}.png`}
              alt=""
            />
            {optionObj.label} ({optionObj.code}){/*  +{optionObj.phone} */}
          </Box>
        );
      }}
      renderInput={(params: any) => {
        const code = String(
          getTheFlagCode(params?.inputProps?.value).code,
        ).toLowerCase();
        return (
          <Stack direction={"row"} spacing={2} sx={{}}>
            <TextField
              {...params}
              label="Choose a country"
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                },
              }}
              error={!!formik?.errors.country}
              helperText={
                formik?.touched.country && formik?.errors.country
                  ? formik?.errors.country
                  : ""
              }
            />
            <img
              loading="lazy"
              width="40"
              height="40"
              srcSet={`https://flagcdn.com/w40/${code}.png 2x`}
              src={`https://flagcdn.com/w40/${code}.png`}
              alt=""
              style={{ margin: "auto auto auto 0.5rem" }}
            />
          </Stack>
        );
      }}
    />
  );
};

export default CountrySelect;

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
