import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import IconFullHalf from "../../../../assets/svg/student/fullLength.svg";
import IconLengthHalf from "../../../../assets/svg/student/halfLength.svg";
import { CatCards } from "./CatCards";

const lengthList = [
  {
    icon: IconLengthHalf,
    text: "Half Length",
  },
  {
    icon: IconFullHalf,
    text: "Full Length",
  },
];

interface LenthType {
  clickHander: (val: string) => void;
  formik: any;
}

const Length: FunctionComponent<LenthType> = (props: LenthType) => {
  const { formik } = props;
  const handlerSetSelected = (val: string) => {
    return (
      String(formik.values.length).toLowerCase() === String(val).toLowerCase()
    );
  };
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Length</Typography>
      <Stack direction="row" sx={{ mt: 2, gap: 4 }}>
        {lengthList.map((item) => (
          <CatCards
            icon={item.icon}
            text={item.text}
            clickHander={props.clickHander}
            selected={handlerSetSelected(item.text)}
          />
        ))}
      </Stack>
      {formik.errors.length && (
        <FormHelperText error>
          {formik.touched.length && formik.errors.length
            ? formik.errors.length
            : ""}
        </FormHelperText>
      )}
    </Box>
  );
};

export { Length };
