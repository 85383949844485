import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { UserTypeEnum } from "../../../constants/types/userTypes";

interface profileDetails {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
  birthdate: string;
  phoneno: string;
  countrycode: string;
  bio: string;
  isActive: boolean;
  isCustomer: boolean;
  language: string;
  photoUrl: string;
  class: classDetails;
  school: schoolDetails;
  userAddress: addressItem;
  board: string;
  grade: string;
  examDate: string;
  userType: UserTypeEnum;
  subjects: string[];
}

interface boardItem {
  boardName: string;
  boardLogoUrl: string;
  boardUrl: string;
}

interface addressItem {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  country: string;
  pinCode: string;
  state: string;
}
interface classDetails {
  name: string;
  branch: string;
  address: addressItem;
}

interface schoolDetails {
  name: string;
  branch: string;
  address: addressItem;
}

const initProfileSubjectlist: string[] = [];

const initProfile: profileDetails = {
  firstName: "",
  lastName: "",
  email: "",
  city: "",
  birthdate: "",
  phoneno: "",
  countrycode: "",
  bio: "",
  isActive: false,
  isCustomer: false,
  language: "",
  photoUrl: "",
  class: {
    name: "",
    branch: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      country: "",
      pinCode: "",
      state: "",
    },
  },
  school: {
    name: "",
    branch: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      country: "",
      pinCode: "",
      state: "",
    },
  },
  userAddress: {
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    country: "",
    pinCode: "",
    state: "",
  },
  board: "",
  grade: "",
  examDate: "",
  userType: UserTypeEnum.Student,
  subjects: initProfileSubjectlist,
};

const initBoardlist: boardItem[] = [];
const initLanguagelist: object[] = [];
const initGradelist: object[] = [];
const initExamDatelist: string[] = [];
const initSchoollist: string[] = [];
const initSchoolBranchlist: string[] = [];
const initCoachingClasslist: string[] = [];
const initCoachingClassBranchlist: string[] = [];

const profile = createSlice({
  name: "studentprofile",
  initialState: {
    profile: initProfile,
    listOfBoardNames: initBoardlist,
    listOfLanguageNames: initLanguagelist,
    listOfGradeNumbers: initGradelist,
    listOfExamDates: initExamDatelist,
    listOfSchoolName: initSchoollist,
    listOfSchoolBranch: initSchoolBranchlist,
    listOfCoachingClassName: initCoachingClasslist,
    listOfCoachingClassBranch: initCoachingClassBranchlist,
    school: { schoolName: "", coachingName: "" },
  },
  reducers: {
    personalDetail(state, action: { payload: object }) {
      console.log("student profile details ", action);
      // state.profile = ({
      //   firstName: action.payload.firstName,
      //   lastName: action.payload.lastName,
      //   email: action.payload.email,
      //   city: action.payload.city,
      //   birthdate: action.payload.birthdate,
      //   phoneno: action.payload.phoneno,
      //   countrycode: action.payload.countrycode,
      // })
      // const data = action.payload;
      console.log("before assignment ", {
        ...action.payload,
        ...state.profile,
      });
      state.profile = { ...state.profile, ...action.payload };
      console.log("in reducer state.profile ", state.profile);
    },
    schoolDetails(state, action: { payload: any }) {
      console.log("school details before set  ", state.profile, action.payload);
      state.profile = { ...state.profile, ...action.payload };
      console.log("school details set ", state.profile);
    },
    // boardDetails(state, action: { payload: any }) {
    //   console.log("school details before set  ", state.profile, action.payload);
    //   state.profile = { ...state.profile, ...action.payload };
    //   console.log("school details set ", state.profile);
    // },
    setBoardList(state, action) {
      let tempArray = _.concat(state.listOfBoardNames, action.payload);
      tempArray = _.uniqBy(tempArray, (item) => item.boardName);
      tempArray = _.sortBy(tempArray, (item) => item.boardName);
      state.listOfBoardNames = [...tempArray];
    },
    setLanguageList(state, action) {
      let tempArray = _.concat(state.listOfLanguageNames, action.payload);
      tempArray = _.uniq(tempArray);
      tempArray = _.sortBy(tempArray);
      state.listOfLanguageNames = tempArray;
    },
    setGradeList(state, action) {
      let tempArray = _.concat(state.listOfGradeNumbers, action.payload);
      tempArray = _.uniq(tempArray);
      tempArray = _.sortBy(tempArray);
      state.listOfGradeNumbers = tempArray;
    },
    setSchoolList(state, action) {
      let tempArray = _.concat(state.listOfSchoolName, action.payload);
      tempArray = _.uniq(tempArray);
      tempArray = _.sortBy(tempArray);
      state.listOfSchoolName = tempArray;
      console.log("state.listOfSchoolName ", state.listOfSchoolName);
    },
    setSchoolBranchList(state, action) {
      let tempArray = _.concat(state.listOfSchoolBranch, action.payload);
      tempArray = _.uniq(tempArray);
      tempArray = _.sortBy(tempArray);
      state.listOfSchoolBranch = tempArray;
      console.log("state.listOfSchoolBranch ", state.listOfSchoolBranch);
    },
    setCoachingClassList(state, action) {
      let tempArray = _.concat(state.listOfCoachingClassName, action.payload);
      tempArray = _.uniq(tempArray);
      tempArray = _.sortBy(tempArray);
      state.listOfCoachingClassName = tempArray;
      console.log(
        "state.listOfCoachingClassName ",
        state.listOfCoachingClassName,
      );
    },
    setCoachingClassBranchList(state, action) {
      let tempArray = _.concat(state.listOfCoachingClassBranch, action.payload);
      tempArray = _.uniq(tempArray);
      tempArray = _.sortBy(tempArray);
      state.listOfCoachingClassBranch = tempArray;
      console.log(
        "state.listOfCoachingClassBranch ",
        state.listOfCoachingClassBranch,
      );
    },
    setExamDatesList(state, action) {
      let tempArray = _.concat(state.listOfExamDates, action.payload);
      tempArray = _.uniq(tempArray);
      tempArray = _.sortBy(tempArray);
      state.listOfExamDates = tempArray;
    },
  },
});

export const {
  personalDetail,
  schoolDetails,
  setBoardList,
  setLanguageList,
  setGradeList,
  setSchoolList,
  setSchoolBranchList,
  setCoachingClassList,
  setCoachingClassBranchList,
  setExamDatesList,
} = profile.actions;
export default profile.reducer;
