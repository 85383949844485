import * as ER from "@emotion/react";
import {
  Backdrop,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import React, { FunctionComponent } from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import PsRoutes from "./app/routes/PsRoutes";
import reportWebVitals from "./reportWebVitals";
import { persistor, psStore } from "./services/storage/store";
import { darkTheme } from "./styles/psMuiTheme/colorCodes";
import { psDarkTheme } from "./styles/psMuiTheme/psMuiTheme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const PersistLoading: FunctionComponent = () => {
  return (
    <>
      <Backdrop
        sx={(theme: { zIndex: { drawer: number } }) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        })}
        open={true}
        // onClick={handleClose}
      >
        <CircularProgress
          color="inherit"
          variant="indeterminate"
          value={50}
        ></CircularProgress>
      </Backdrop>
    </>
  );
};

root.render(
  <React.StrictMode>
    <Provider store={psStore}>
      <PersistGate loading={<PersistLoading />} persistor={persistor}>
        <ThemeProvider theme={psDarkTheme} defaultMode="dark">
          <ER.ThemeProvider theme={darkTheme}>
            <ToastContainer />
            <CssBaseline />
            <PsRoutes />
          </ER.ThemeProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
