const regExp = {
  alphaOnly: "/^[A-Za-z\\s]+$/",
  password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,20}/,
  numberOnly: "/^[0-9\b]+$/",
  email: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/,
  alphanumeric: /^[a-zA-Z0-9]+$/,
  alphanumericunderscore: /^[a-zA-Z0-9_]*$/,
};

export default regExp;
