import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  // firstname: Yup.string().required("First Name is required"),
  // lastname: Yup.string().required("Last Name is required"),
  // email: Yup.string().email("Invalid email").required("Email is required"),
  // // Number: Yup.string()
  // //   .required('Select your country code'),
  // phone: Yup.string()
  //   .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
  //   .required("Phone number is required"),
  // Country: Yup.string().required("Country is required"),
  // State: Yup.string().required("State is required"),
  // city: Yup.string().required("City is required"),
  // allocatedInweek: Yup.string().required("  required"),
  // allocatedInMonth: Yup.string().required(" required"),
  bankName: Yup.string().required("Bank name is required"),
  bankingName: Yup.string().required("Account holder is required"),
  AccountNumber: Yup.string()
    .required("Account number is required")
    .matches(/^[0-9]+$/, "Account number must only contain digits")
    .min(9, "Account number must be at least 9 digits")
    .max(18, "Account number must not exceed 18 digits"),
  IFSC: Yup.string().required("IFSC code is required"),
  // .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format"),
  PANno: Yup.string().required("PAN number is required"),
  branch: Yup.string().required("branch name is required"),
  // .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN number format"),
  // educations: Yup.array().of(
  //   Yup.object().shape({
  //     Degree: Yup.string().required("Degree is required"),
  //     Subject: Yup.string().required("Subject is required"),
  //     Year: Yup.string().required("Year is required"),
  //   }),
  // ),
  // experience: Yup.array().of(
  //   Yup.object().shape({
  //     Institutionname: Yup.string().required("Institution Name is required"),
  //     Location: Yup.string().required("Location is required"),
  //     DurationYear: Yup.string().required("required"),
  //     DuartionMonth: Yup.string().required("required"),
  //     ExperienceType: Yup.string().required("Experience Type is required"),
  //   }),
  // ),
  // enrollment: Yup.array().of(
  //   Yup.object().shape({
  //     Board: Yup.string().required("Board Name is required"),
  //     Subject: Yup.string().required("Subject is required"),
  //   }),
  // ),
});
export default validationSchema;
