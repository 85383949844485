import { Box, Card, Typography } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { useStudentTestAPICalls } from "../../../../../services/api/student/studentTestAPIMthods";
import {
  getLocalStorage,
  showErrorToast,
  showSuccessToast,
} from "../../../../../services/commonFunctions";
import { RootState } from "../../../../../services/storage/store";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import fileUploadIcon from "../../../../assets/svg/student/fileUploadIcon.svg";
import LinearProgress from "./LinearProgress";

const FileDragDropContainer: FunctionComponent = () => {
  const [uploadingFileName, setUploadingFileName] = useState("");
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const {
    studentTest: { taskList, selectedTestIdToUploadAnswerSheet, testPaper },
  } = useSelector((state: RootState) => state);
  let userInfo: any = getLocalStorage("loggedInUser");

  const {
    generatePresignedURLToUploadTheTestAnswers,
    getAllTasks,
    assignTestToTeacher,
  } = useStudentTestAPICalls();

  const readFileAsArrayBuffer = (file: File | Blob): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error("Invalid file type. Expected File or Blob."));
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        // Need to check if result is ArrayBuffer
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read file as ArrayBuffer"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const callBackFunctionToResetTheData = () => {
    setUploadingFileName("");
    setUploadingProgress(0);
    getAllTasks();
  };

  const uploadFileToS3 = async (
    presignedUrl: string,
    file: File,
    tempFileName: string,
  ) => {
    try {
      // Verify that file is valid before proceeding
      if (!(file instanceof File)) {
        console.error("Invalid file object");
        return "";
        // throw new Error("Invalid file object");
      }

      const arrayBuffer = await readFileAsArrayBuffer(file);
      const uploadResponse = await axios.put(
        presignedUrl,
        { body: arrayBuffer },
        {
          headers: {
            "Content-Type": file.type,
            "Content-Length": file.size.toString(),
          },
          onUploadProgress: function (progressEvent: any) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setUploadingProgress(percentCompleted);
          },
        },
      );

      if (String(uploadResponse.statusText).toLowerCase() === "ok") {
        showSuccessToast(
          "File uploaded, please save the form to update the certificate.",
        );
        const aparams = {
          ...{
            useSubjectQueue: false,
            testId: selectedTestIdToUploadAnswerSheet,
          },
          ...{ answerSheetUrl: tempFileName },
          cb: callBackFunctionToResetTheData,
        };
        assignTestToTeacher(aparams);
      }
    } catch (error) {
      console.error("Error uploading to S3:", error);
      showErrorToast("Upload of answer-sheet failed.", false);
      callBackFunctionToResetTheData();
      return "";
      // throw error;
    } finally {
      callBackFunctionToResetTheData();
    }
  };

  const handlerOnDropFile = async (ev: any) => {
    console.log("File(s) dropped");
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach(async (item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile();
          console.log(`… file[${i}].name = ${file.name}`);
          const fileNameArray = _.split(file.name, ".");
          const ext = fileNameArray[fileNameArray.length - 1];
          if (String(ext).toLowerCase() === "pdf") {
            const tempFileName =
              _.split(file.name, `.${ext}`)[0] +
              testPaper.subject +
              "-ps-" +
              (userInfo?.userId || "0") +
              "-" +
              moment().format("x") +
              "." +
              ext;
            const fileName = file.name;
            const returnData = await generatePresignedURLToUploadTheTestAnswers(
              {
                fileName: tempFileName,
              },
            );
            console.log("returnData", returnData);
            // readTheFileInBase64(file, returnData);
            setUploadingFileName(fileName);
            setUploadingProgress(0);
            uploadFileToS3(returnData, file, tempFileName);
          } else {
            showErrorToast("You can upload only PDF file.", true);
          }
        }
      });
      // [...ev.dataTransfer.items].forEach((item, i) => {
      //   // If dropped items aren't files, reject them
      //   if (item.kind === "file") {
      //     const file = item.getAsFile();
      //     console.log(`… file[${i}].name = ${file.name}`);
      //   }
      // });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...ev.dataTransfer.files].forEach(async (file, i) => {
        console.log(`… file[${i}].name = ${file.name}`);
        // const fileNameArray = _.split(file.name, ".");
        // const ext = fileNameArray[fileNameArray.length - 1];
        // if (String(ext).toLowerCase() === "pdf") {
        //   const fileName = file.name;
        //   const returnData = await generatePresignedURLToUploadTheTestAnswers({
        //     fileName,
        //   });
        //   console.log("returnData", returnData);
        // } else {
        //   alert("invalid file extension");
        // }
      });
    }
  };

  const openFileUploadWindow = (e: any) => {
    console.log("openFileUploadWindow", e);
  };
  const handlerOnDragOverFile = (ev: any) => {
    ev.preventDefault();
    console.log("handlerOnDragOverFile", ev);
  };
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexFlow: "column",
          margin: "auto",
        }}
        onClick={openFileUploadWindow}
        onDrop={handlerOnDropFile}
        onDragOver={handlerOnDragOverFile}
      >
        {!uploadingFileName && (
          <>
            <Card
              sx={{
                alignItems: "center",
                display: "flex",
                flexFlow: "column",
                mt: 5.5,
                mb: 3,
                cursor: "pointer",
              }}
            >
              <img
                src={fileUploadIcon}
                width={24}
                height={24}
                alt="upload file icon"
              />
              <Typography
                sx={{
                  fontSize: 14,
                  color: darkTheme.colors.orange,
                  fontFamily: "Inter",
                  fontWeight: 400,
                  lineHeight: 2.45,
                  textAlign: "center",
                }}
              >
                Upload file
              </Typography>
            </Card>

            <Typography
              variant="body2"
              sx={{
                fontFamily: "Inter",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 2.45,
                textAlign: "center",
              }}
            >
              Click to browse or drag and drop a file to upload.
            </Typography>
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                fontFamily: "Inter",
                fontSize: 12,
                fontWeight: 400,
                lineHeight: 2.1,
                textAlign: "center",
                mb: 6,
              }}
            >
              Max size: 15MB * Supported format: PDF & Word
            </Typography>
          </>
        )}

        {uploadingFileName && (
          <LinearProgress
            progress={uploadingProgress}
            fileName={uploadingFileName}
          />
        )}
      </Box>
    </>
  );
};

export default FileDragDropContainer;
