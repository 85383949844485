import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../commonFunctions";
import { useTeacherNavigation } from "../../hooks/teacher/navigationHooks";
import { showHideLoader } from "../../storage/common";
import {
  setCityOfState,
  setCountryList,
  setStateOfCountry,
} from "../../storage/teacher/Teacherlocation";
import {
  setCertificate,
  setGetDetails,
  setGetTask,
  setPDF,
  setProfileData,
  setQnPapaerAnswersheet,
  setQnPaperFormat,
  setSubject,
  setGetperticularTask,
} from "../../storage/teacher/TProfile";
import psApiService from "../apiService";

export const TeacherAPIcalls = () => {
  const teachernavigation = useTeacherNavigation();
  const dispatch = useDispatch();
  const errorFunction = (error: any) => {
    dispatch(showHideLoader(false));
    console.log(error);
    return "";
  };

  const fetchProfileget = () => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          dispatch(setProfileData(result.data.data || []));
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const fetchtask = () => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.apigettask.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setGetTask(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const fetchQnPaperformat = () => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.apiqnpapaerformat.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setQnPaperFormat([result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const fetchQnPapaerAnswersheet = () => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.apiqnpapaerformat.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setQnPapaerAnswersheet([result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };
  const getCityList = (country: string, state: string) => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.location.city.get", { country, state })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(
              setCityOfState({
                stateName: state,
                cityList: result.data.data,
              }),
            );
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getStateList = (country: string) => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.location.state.get", { country })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(
              setStateOfCountry({
                countryCode: country,
                stateList: result.data.data,
              }),
            );
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getCountryList = () => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.location.country.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(
              setCountryList({
                countryList: result.data.data,
              }),
            );
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const fetchSubject = () => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.subject.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          dispatch(setSubject(result.data.data || []));
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const fetchPDf = (id: any) => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.pdf.get", { key: `${id}.pdf` })
      .then((result: any) => {
        if (result?.data.status === 200) {
          dispatch(setPDF(result.data.data || []));
          // console.log(id, "Get ID");
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const PostProfile = (values: any) => {
    const teacherprofile: object = {
      // firstName: values.firstName || "",
      // lastname: values.lastname || "",
      // email: values.email || "",
      // country: values.country || "",
      // State: values.State || "",
      // city: values.city || "",
      // Number: values.Number || "",
      // phone: values.phone || "",
      // Associate: values.Associate || "",
      experienceHistory: values.experience.map((experience: any) => {
        return {
          institutionName: experience.Institutionname || "",
          type: experience.ExperienceType || "",
          location: experience.Location || "",
          years: experience.DurationYear || "",
          months: experience.DuartionMonth || "",
          isWorkingHere: experience.Workinghere ? true : false,
        };
      }),
      // subjectsTaught: values.subjectTaught.map((subjectTaught: any) => {
      //   return {
      //     subject: subjectTaught.subject || "",
      //   };
      // }),
      subjectsTaught: values.subjectTaught,
      educationHistory: values.educations.map((educations: any) => {
        return {
          degree: educations.Degree || "",
          subject: educations.Subject || "",
          year: educations.Year || "",
          certificateBlobURL: educations.certificate[0] || "",
        };
      }),
      bankingInfo: {
        bankingName: values.AccountHolderName || "",
        bankName: values.bankName || "",
        accountNo: values.AccountNumber || "",
        ifscCode: values.IFSC || "",
        branch: values.branch || "",
        panNo: values.PANno || "",
        pancardBlobURL: values.pancard[0] || "",
        // bankName:
        // branch:
      },
      schedule: {
        dailyLimit: values.allocatedInMonth || "",
        weeklyLimit: values.allocatedInWeek || "",
      },
    };

    dispatch(showHideLoader(true));
    psApiService("teacher.profile.post", teacherprofile)
      .then((result: any) => {
        if (result?.data.status === 200) {
          teachernavigation.navigateToTeacherDashboard();
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const updateProfile = (values: any) => {
    dispatch(showHideLoader(true));
    const teacherprofile: object = {
      // firstName: values.firstName || "",
      // lastname: values.lastname || "",
      // email: values.email || "",
      // country: values.country || "",
      // State: values.State || "",
      // city: values.city || "",
      // Number: values.Number || "",
      // phone: values.phone || "",
      // Associate: values.Associate || "",
      experienceHistory: values?.experience?.map((experience: any) => {
        return {
          institutionName: experience.Institutionname || "",
          type: experience.ExperienceType || "",
          location: experience.Location || "",
          years: experience.DurationYear || "",
          months: experience.DuartionMonth || "",
          isWorkingHere: experience.Workinghere ? true : false,
        };
      }),
      // subjectsTaught: values.subjectTaught.map((subjectTaught: any) => {
      //   return {
      //     subject: subjectTaught.subject || "",
      //   };
      // }),
      subjectsTaught: values?.subjectTaught,
      educationHistory: values?.educations?.map((educations: any) => {
        return {
          degree: educations.Degree || "",
          subject: educations.Subject || "",
          year: educations.Year || "",
          certificateBlobURL: educations?.certificateBlobURL || "",
        };
      }),
      bankingInfo: {
        bankingName: values.bankingName || "",
        bankName: values.bankName || "",
        accountNo: values.AccountNumber || "",
        ifscCode: values.IFSC || "",
        branch: values.branch || "",
        panNo: values.PANno || "",
        pancardBlobURL: values.panblobURL || "",
        // bankName:
        // branch:
      },
      schedule: {
        dailyLimit: values.allocatedInMonth || "",
        weeklyLimit: values.allocatedInWeek || "",
      },
    };
    psApiService("teacher.profile.update", teacherprofile)
      .then((result: any) => {
        if (result?.data.status === 200) {
          teachernavigation.navigateToTeacherProfile();
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const readFileAsArrayBuffer = (file: File | Blob): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        reject(new Error("Invalid file type. Expected File or Blob."));
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        // Need to check if result is ArrayBuffer
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error("Failed to read file as ArrayBuffer"));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  const uploadFileToS3 = async (presignedUrl: string, file: File) => {
    try {
      dispatch(showHideLoader(true));
      console.log("File object:", file);
      console.log("File type:", file instanceof File);
      console.log("Blob type:", file instanceof Blob);
      // Verify that file is valid before proceeding
      if (!(file instanceof File)) {
        console.error("Invalid file object");
        return "";
        // throw new Error("Invalid file object");
      }

      const arrayBuffer = await readFileAsArrayBuffer(file);

      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: arrayBuffer,
        headers: {
          "Content-Type": file.type,
          "Content-Length": file.size.toString(),
        },
      });
      console.log("uploadResponse", uploadResponse);
      if (uploadResponse.ok) {
        showSuccessToast(
          "File uploaded, please save the form to update the certificate.",
        );
        dispatch(setCertificate(uploadResponse.url));
        console.log("File uploaded successfully:", uploadResponse.url);
        return uploadResponse.url;
      } else {
        console.error("Failed to upload file:", uploadResponse.statusText);
        return "";
        // throw new Error(`Failed to upload file: ${uploadResponse.statusText}`);
      }
    } catch (error) {
      console.error("Error uploading to S3:", error);
      return "";
      // throw error;
    } finally {
      dispatch(showHideLoader(false)); // Don't forget to hide loader
    }
  };

  async function Getcertificate(file: any): Promise<string> {
    try {
      dispatch(showHideLoader(true));
      const result: any = await psApiService("teacher.profile.file.get", {
        key: `${file?.name}`,
      });

      if (result?.data.status === 200) {
        console.log(result.data.data, "Get url");
        const presignedUrl = result.data.data;
        const link = await uploadFileToS3(presignedUrl, file);
        console.log("link", link);
        return link;
      } else if (result?.data?.message) {
        const { message } = result?.data;
        showErrorToast(message, true);
        return "";
      }
      return "";
    } catch (error) {
      errorFunction(error);
      return "";
    } finally {
      dispatch(showHideLoader(false));
    }
  }

  const fetchQnpaperDetails = () => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.qnpaperdetails.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          dispatch(setGetDetails(result.data.data || []));
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const fetchPerticularTask = (testID: number) => {
    dispatch(showHideLoader(true));
    psApiService("teacher.profile.perticularTask.get", { testID: testID })
      .then((result: any) => {
        if (result?.data.status === 200) {
          dispatch(setGetperticularTask([result.data.data]));
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  return {
    PostProfile,
    updateProfile,
    fetchProfileget,
    fetchtask,
    fetchQnPaperformat,
    fetchQnPapaerAnswersheet,
    getCityList,
    getCountryList,
    getStateList,
    fetchSubject,
    fetchPDf,
    fetchQnpaperDetails,
    Getcertificate,
    fetchPerticularTask,
  };
};
