import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CanvasContainer,
  PageContainer,
  SelectionRect,
  StyledCanvas,
} from "../Theme/PDFtheme";

const PdfPage = ({
  pdfDocuments,
  index,
  canvasRef,
  isRegionCapture,
  currentTool,
  startRegionSelection,
  updateRegionSelection,
  endRegionSelection,
  startDrawing,
  draw,
  stopDrawing,
  isSelectingRegion,
  selectionStart,
  selectionEnd,
  selectionRectRef,
  currentPage,
  setCurrentPage,
}: any) => {
  const pageRef = useRef(null);
  const [lastActivity, setLastActivity] = useState<number>(Date.now());

  const autoSave = () => {
    const saveData = {
      pageIndex: currentPage,
      timestamp: new Date().toISOString(),
      changes: canvasRef.current?.toDataURL() || null,
    };
    localStorage.setItem("userProgress", JSON.stringify(saveData));
    //   fetchpost(saveData)
  };

  useEffect(() => {
    const saveInterval = setTimeout(() => {
      if (Date.now() - lastActivity >= 3000) {
        autoSave();
      }
    }, 3000);

    return () => clearTimeout(saveInterval);
  }, [lastActivity, autoSave]);

  const handleActivity = useCallback(() => {
    setLastActivity(Date.now());
  }, []);

  const handleMouseDown = (e: any) => {
    if (!canvasRef.current) return;
    handleActivity();
    if (isRegionCapture) {
      startRegionSelection(e, canvasRef.current);
    } else {
      startDrawing(e, canvasRef.current);
    }
  };

  const handleMouseMove = (e: any) => {
    if (!canvasRef.current) return;
    handleActivity();
    if (isRegionCapture) {
      updateRegionSelection(e, canvasRef.current);
    } else {
      draw(e, canvasRef.current);
    }
  };

  const handleMouseUpOrLeave = () => {
    if (!canvasRef.current) return;
    handleActivity();
    if (isRegionCapture) {
      endRegionSelection(canvasRef.current);
    } else {
      stopDrawing(canvasRef.current);
    }
  };

  const handlePageChange = useCallback((newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  useEffect(() => {
    if (!pageRef.current) return;
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.1) {
          const pageElement = entry.target as HTMLElement;
          const pageIndex = parseInt(
            pageElement.getAttribute("data-index") || "0",
            10,
          );
          handlePageChange(pageIndex);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: [0.1, 0.5, 1.0],
      rootMargin: "-10% 0px",
    });

    observer.observe(pageRef.current);

    return () => {
      if (pageRef.current) {
        observer.unobserve(pageRef.current);
      }
    };
  }, [index, handlePageChange]);

  return (
    <>
      <PageContainer
        ref={pageRef}
        data-index={index}
        elevation={10}
        className={`page ${currentPage === index ? "active-page" : ""}`}
        style={{
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" fontWeight="600">
            Page {index + 1}
          </Typography>
        </Box>

        <CanvasContainer>
          <StyledCanvas
            ref={canvasRef}
            $tool={currentTool}
            $isRegionCapture={isRegionCapture}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUpOrLeave}
            onMouseLeave={handleMouseUpOrLeave}
          />

          {isSelectingRegion && selectionStart && selectionEnd && (
            <SelectionRect
              ref={selectionRectRef}
              sx={{
                left: Math.min(selectionStart.x, selectionEnd.x),
                top: Math.min(selectionStart.y, selectionEnd.y),
                width: Math.abs(selectionEnd.x - selectionStart.x),
                height: Math.abs(selectionEnd.y - selectionStart.y),
              }}
            />
          )}
        </CanvasContainer>
      </PageContainer>
    </>
  );
};

export default PdfPage;
