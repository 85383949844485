import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../services/storage/store";
import IconMath from "../../../../assets/svg/student/calculator-fill.svg";
import IconPhysics from "../../../../assets/svg/student/lightbulb-flash-fill.svg";
import { CatCards } from "./CatCards";

interface iconObjType {
  [key: string]: any;
}

const iconList: iconObjType = {
  maths: IconMath,
  physics: IconPhysics,
  chemistry: IconPhysics,
  science: IconPhysics,
  biology: IconPhysics,
};

interface SubjectType {
  clickHander: (val: string) => void;
  formik: any;
}

const Subject: FunctionComponent<SubjectType> = (props: SubjectType) => {
  const { studentTest, studentProfile } = useSelector(
    (state: RootState) => state,
  );
  const { formik } = props;
  const [subjectList, setSubjectList] = useState<string[]>([]);

  const subjectListTemp = studentProfile.profile.subjects;
  useEffect(() => {
    // console.log(studentTest);
    // const { subjectList } = studentTest;
    const {
      profile: { subjects },
    } = studentProfile;
    console.log(subjects);
    if (subjects) {
      setSubjectList(subjects);
    }
  }, [studentProfile.profile.subjects]);

  const handlerSetSelected = (val: string) => {
    return (
      String(formik.values.subject).toLowerCase() === String(val).toLowerCase()
    );
  };

  console.log("subjectListTemp", subjectListTemp);
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Subject</Typography>
      <Stack direction="row" sx={{ mt: 2, gap: 4 }}>
        {subjectList.map((item: string) => {
          const icon = iconList[item.toLowerCase()];
          return (
            <CatCards
              icon={icon}
              text={item}
              clickHander={props.clickHander}
              selected={handlerSetSelected(item)}
            />
          );
        })}
      </Stack>
      {formik.errors.subject && (
        <FormHelperText error>
          {formik.touched.subject && formik.errors.subject
            ? formik.errors.subject
            : ""}
        </FormHelperText>
      )}
    </Box>
  );
};

export { Subject };
