import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useNavigate } from "react-router-dom";
import { PsButton } from "../../../../lib/psElements/psElements";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";
const SingleLineChart: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"papers" | "time" | "income">(
    "papers",
  );
  const getSeriesData = () => {
    switch (activeTab) {
      case "papers":
        return [45, 52, 38, 24, 1, 26, 10, 20, 33, 5, 55, 10];
      case "time":
        return [40, 49, 60, 30, 40, 42, 48, 50, 55, 58, 61, 65];
      case "income":
        return [30, 40, 20, 15, 2, 40, 20, 15, 50, 10, 72, 5];
      default:
        return [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10];
    }
  };

  const series = [
    {
      name: "Performance",
      data: getSeriesData(),
    },
  ];

  const options: ApexOptions = {
    chart: {
      height: 400,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [4],
      curve: "smooth",
      colors: [`${darkTheme.colors.teacher.Dashboard.charts.optioncolor}`],
    },
    markers: {
      size: 6,
      strokeWidth: 2,
      colors: [`${darkTheme.colors.teacher.Dashboard.charts.optioncolor}`],
      hover: {
        size: 8,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    xaxis: {
      categories: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      labels: {
        style: {
          colors: `${darkTheme.colors.teacher.Dashboard.charts.color}`,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: `${darkTheme.colors.teacher.Dashboard.charts.color}`,
        },
      },
    },
    tooltip: {
      theme: "dark",
      marker: {},
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        return `
          <div style="padding: 8px; background:${darkTheme.colors.teacher.Dashboard.charts.backgroundcolor}; color: white; border: 1px solid ${darkTheme.colors.teacher.Dashboard.charts.borderColor}; border-radius: 4px;">
            ${w.config.series[seriesIndex].name}: ${w.config.series[seriesIndex].data[dataPointIndex]}
          </div>
        `;
      },
    },
    grid: {
      borderColor: `${darkTheme.colors.teacher.Dashboard.charts.borderColor}`,
    },
  };
  const tabs: {
    id: "papers" | "time" | "income";
    label: string;
    activeColor: string;
  }[] = [
    {
      id: "papers",
      label: "Papers Corrected",
      activeColor: `${darkTheme.colors.teacher.Dashboard.charts.activecolor}`,
    },
    {
      id: "time",
      label: "Time Spent",
      activeColor: `${darkTheme.colors.teacher.Dashboard.charts.activecolor}`,
    },
    {
      id: "income",
      label: "Income Generated",
      activeColor: `${darkTheme.colors.teacher.Dashboard.charts.activecolor}`,
    },
  ];

  return (
    <div
      style={{
        backgroundColor: `${darkTheme.colors.teacher.Dashboard.charts.backgroundcolor}`,
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          color: "white",
        }}
      >
        <h3 style={{ fontSize: "20px" }}>Performance</h3>

        <div style={{ display: "flex", gap: "8px" }}>
          {tabs.map((tab) => (
            <PsButton.CustomChartButton
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              sx={{
                color:
                  activeTab === tab.id
                    ? tab.activeColor
                    : `${darkTheme.colors.teacher.Dashboard.task.white}`,
                borderColor:
                  activeTab === tab.id
                    ? tab.activeColor
                    : `${darkTheme.colors.teacher.Dashboard.charts.tabactiveColor}`,
              }}
            >
              {tab.label}
            </PsButton.CustomChartButton>
          ))}
        </div>
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={400}
      />
    </div>
  );
};

export default SingleLineChart;
