import {
  Autocomplete,
  FormControl,
  Grid2,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { PsButton, PsInput, StepBox } from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/student/studentProfileAPIMethods";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import validation from "../../../services/validation";
import CountrySelect from "../../common/CountrySelect/CountrySelect";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const PersonalDetails: FunctionComponent = () => {
  const [tempState, setTempState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    phone: "",
    country: "India",
    state: "",
    city: "",
  });
  const studentNaviage = useStudentNaviagation();
  const {
    studentProfile: { profile },
    location,
  } = useSelector((state: RootState) => state);
  const { getStateList, getCityList, getCountryList } =
    useStudentProfileAPICalls();
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: tempState,
    onSubmit: (values: {
      firstName: any;
      lastName: any;
      email: any;
      city: any;
      birthDate: any;
      phone: any;
      country: any;
      state: any;
    }) => {
      const {
        firstName,
        lastName,
        email,
        city,
        birthDate,
        phone,
        country,
        state,
      } = values;
      dispatch(
        personalDetail({
          ...profile,
          firstName,
          lastName,
          email,
          birthdate: birthDate,
          phoneno: phone,
          userAddress: { ...profile?.userAddress, state, city, country },
        }),
      );
      studentNaviage.navigateToSchoolDetails();
    },
    validationSchema: Yup.object({
      firstName: validation.schema.firstName,
      lastName: validation.schema.lastName,
      email: validation.schema.email,
      phone: validation.schema.mobile,
      city: validation.schema.city,
      birthDate: validation.schema.birthDateMin6Year,
      state: validation.schema.state,
    }),
  });

  const reassignTheStateValues = () => {
    setTempState({
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      city: profile.userAddress.city,
      birthDate: profile.birthdate,
      phone: profile.phoneno,
      country: profile.userAddress.country,
      state: profile.userAddress.state,
    });
  };

  useEffect(() => {
    reassignTheStateValues();
    getCountryList();
  }, []);

  useEffect(() => {
    reassignTheStateValues();
  }, [profile]);

  useEffect(() => {
    getStateList(formik.values.country);
  }, [formik.values.country]);

  useEffect(() => {
    getCityList("India", formik.values.state);
  }, [formik.values.state]);

  const filterCityListFromReducer = (): [] => {
    let returnArray: any = [];
    const stateName: string = formik.values.state;
    if (location?.city) {
      returnArray = location.city;
    }
    return returnArray;
  };

  const handlerCountryValueChange = (e: any) => {
    // console.log("country handle change triggered", e.target.value, e.target, e);
    const country = e?.target.value;
    formik.setValues({
      ...formik.values,
      country,
      state: "",
      city: "",
    });
    console.log(e);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="personalDetails">
        <StepsBar currentStep={0} />
        <StepBox>
          <GoBackArrowIos
            handlerBackButton={() => {
              studentNaviage.navigateToDashboard();
            }}
            sx={{ ml: 2, mt: 2, mb: 1 }}
          />
          <Typography
            align="left"
            sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
            variant="h5"
          >
            Personal Details
          </Typography>
          <Grow in={true}>
            <Grid2
              container
              spacing={{ xs: 2, md: 2.5 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ m: 3, mb: 21.75 }}
            >
              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <PsInput
                  label="First Name"
                  {...formik.getFieldProps("firstName")}
                  error={!!formik.errors.firstName}
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                      ? formik.errors.firstName
                      : ""
                  }
                />
              </Grid2>
              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <PsInput
                  label="Last Name"
                  {...formik.getFieldProps("lastName")}
                  error={!!formik.errors.lastName}
                  helperText={
                    formik.touched.lastName && formik.errors.lastName
                      ? formik.errors.lastName
                      : ""
                  }
                />
              </Grid2>

              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <PsInput
                  label="Birth Date"
                  type="date"
                  {...formik.getFieldProps("birthDate")}
                  error={!!formik.errors.birthDate}
                  helperText={
                    formik.touched.birthDate && formik.errors.birthDate
                      ? formik.errors.birthDate
                      : ""
                  }
                />
              </Grid2>
              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={"male"}
                    label="Age"
                    // onChange={handleChange}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <PsInput
                  label="Email Address"
                  type="email"
                  {...formik.getFieldProps("email")}
                  error={!!formik.errors.email}
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : ""
                  }
                />
              </Grid2>

              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <Stack direction={"row"} gap={2}>
                  {
                    // <CountryListComponent
                    //   selectedVal={formik.values.country}
                    //   countryList={location.country}
                    //   handlerOnChange={handlerCountryValueChange}
                    // />

                    <CountrySelect
                      selectedVal={formik.values.country}
                      countryList={location.country}
                      handlerOnChange={handlerCountryValueChange}
                      formik={formik}
                    />
                  }
                  <PsInput
                    label="Phone Number"
                    {...formik.getFieldProps("phone")}
                    error={!!formik.errors.phone}
                    helperText={
                      formik.touched.phone && formik.errors.phone
                        ? formik.errors.phone
                        : ""
                    }
                  />
                </Stack>
              </Grid2>
              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  options={location?.state}
                  onChange={(e: any) => {
                    const state = e?.target.innerText;
                    formik.setValues({ ...formik.values, state, city: "" });
                  }}
                  value={formik.values.state}
                  // {...formik.getFieldProps("state")}
                  renderInput={(params: any) => (
                    <PsInput
                      {...params}
                      label="State"
                      // {...formik.getFieldProps("state")}
                      error={!!formik.errors.state}
                      helperText={
                        formik.touched.state && formik.errors.state
                          ? formik.errors.state
                          : ""
                      }
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ md: 6 }} sx={{ mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  options={filterCityListFromReducer()}
                  onChange={(e: any) => {
                    const city = e?.target.innerText;
                    formik.setValues({ ...formik.values, city });
                  }}
                  value={formik.values.city}
                  renderInput={(params: any) => (
                    <PsInput
                      {...params}
                      label="City"
                      error={!!formik.errors.city}
                      helperText={
                        formik.touched.city && formik.errors.city
                          ? formik.errors.city
                          : ""
                      }
                    />
                  )}
                />
              </Grid2>
            </Grid2>
          </Grow>
        </StepBox>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ mt: 4.5 }}
          alignSelf={"flex-end"}
        >
          <PsButton.Outlined
            variant="outlined"
            sx={{ width: 120 }}
            onClick={studentNaviage.navigateToDashboard}
          >
            Cancel
          </PsButton.Outlined>
          <PsButton.Primary
            variant="contained"
            sx={{ width: 240 }}
            type="submit"
          >
            Proceed
          </PsButton.Primary>
        </Stack>
      </div>
    </form>
  );
};
export default PersonalDetails;
