const psConstants = {
  email: {
    require: "Please enter your email address",
    invalid: "Please enter a valid email address",
  },
  parentEmail: {
    required: "Please enter student's email address",
  },
  password: {
    required: "Please enter your password",
    newPassword: "Please enter new password",
    oldPassword: "Please enter current password",
    confirmNewPassword: "Please enter confirm new password",
    passwordPattern:
      "Password should be min 8 characters long with one special character, number, lower and upper case letter",
    same: "Mismatch new password and confirm new password",
    confirmed: "Password and Confirm password do not match",
    foo_confirmation: "Password and Confirm password do not match",
  },
  firstName: {
    required: "Please enter first name",
    alphaOnly: "Please enter a valid first name",
  },
  lastName: {
    required: "Please enter last name",
    alphaOnly: "Please enter a valid last name",
  },
  companyName: {
    required: "Please enter company name",
    alphaOnly: "Please enter a valid company name",
  },
  address: {
    required: "Please enter address",
    alphaOnly: "Please enter a valid address",
  },
  fullName: {
    required: "Please enter name",
    alphaOnly: "Please enter a valid name",
  },
  mobile: {
    required: "Please enter mobile number",
  },
  designation: {
    required: "Please enter designation",
    alphaOnly: "Please enter a valid designation",
  },
  OTP: {
    required: "Please enter OTP value",
  },
  acceptTermsCondition: {
    required: "Please accept terms and conditions",
  },
  day: { required: "Please select day" },
  month: { required: "Please select month" },
  year: { required: "Please select year" },
  city: { required: "Please enter city" },
  state: { required: "Please enter state" },
  country: { required: "Please enter country" },
  summary: { required: "Please enter summary" },
  instituteName: { required: "Please enter institute" },
  startDate: { required: "Please select date" },
  endDate: { required: "Please select date" },
  maxChar150: "Can not exceed 150 characters.",
  maxChar50: "Can not exceed 50 characters.",
  minChar8: "Minimum 8 characters require.",
};

export default psConstants;
