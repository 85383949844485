import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { PsButton, PsInput } from "../../../lib/psElements/psElements";
import { useAuthorizationAPICalls } from "../../../services/api/authorizationAPIMethods";
import { RootState } from "../../../services/storage/store";
import validation from "../../../services/validation";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";
import GoBackButton from "../../common/GoBackButton/GoBackButton";

function LoginByEmail() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [inputObj, setInputObj] = useState({
    email: "",
    password: "",
    loginMethod: "email",
  });
  const { loginUsingEmailPassword } = useAuthorizationAPICalls();
  const { loginError } = useSelector((state: RootState) => state.error);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      loginUsingEmailPassword({ ...values, loginMethod: "email" });
      //       curl --location 'http://ec2-3-80-240-173.compute-1.amazonaws.com:8085/api/auth/authenticate' \
      // --header 'Content-Type: application/json' \
      // --data-raw '{
      // 	"email": "testmate2025@gmail.com",
      // 	"password": "Test@1234",
      // 	"loginMethod": "email",
      //     "firstName": "Test",
      //     "lastName": "Mate"
      // }'
      // axios({
      //   url: "/api/auth/authenticate",
      //   data: {
      //     email: "testmate2025@gmail.com",
      //     password: "Test@1234",
      //     loginMethod: "email",
      //     firstName: "Test",
      //     lastName: "Mate",
      //   },
      // })
      //   .then((result) => console.log(result))
      //   .catch((err) => console.log(err));
    },
    validationSchema: Yup.object({
      email: validation.schema.email,
      password: validation.schema.password,
    }),
  });

  const navigateToLogin = () => {
    navigate("/authorization/login");
  };
  const navigateToCreateAccount = () => {
    navigate("/authorization/create");
  };

  /** Code to show hide password */
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // setShowPassword(true);
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    // setShowPassword(false);
  };
  /** END Code to show hide password */

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <GoBackButton handlerBackButton={navigateToLogin} />
        <Stack direction="column" sx={{ gap: "1.5rem" }}>
          <h1 className="psHeading h1">Login with email</h1>
          <PsInput
            type="email"
            variant="outlined"
            label="Email address"
            error={!!formik.errors.email}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
            {...formik.getFieldProps("email")}
          />
          <FormControl sx={{ m: 0, width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              error={!!formik.errors.password}
              {...formik.getFieldProps("password")}
            />
            <FormHelperText
              sx={{ mt: 1, ml: 0 }}
              error={!!formik.errors.password}
            >
              {formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""}
            </FormHelperText>

            {/* {loginError.message && (
              <FormHelperText
                sx={{ mt: 1, ml: 0 }}
                error={!!loginError.message}
              >
                {loginError.message ? loginError.message : ""}
              </FormHelperText>
            )} */}
          </FormControl>
          <Typography align="right" className="cursorPointer">
            Forgot password?
          </Typography>
          <PsButton.Primary
            variant="contained"
            sx={{ height: "3rem" }}
            onClick={() => console.log("login button clicked")}
            type="submit"
          >
            Login
          </PsButton.Primary>
          <span
            className="createAccount"
            onClick={() => navigateToCreateAccount()}
          >
            <Typography align="center" noWrap>
              Don’t have a account?&nbsp;
              <span
                style={{ color: PsTheme.colors.action.primary }}
                className="cursorPointer"
              >
                Create new account
              </span>
            </Typography>
          </span>
        </Stack>
      </Box>
    </form>
  );
}

export default LoginByEmail;
