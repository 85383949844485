import { Fade, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import * as React from "react";
import { FunctionComponent } from "react";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";
import ChapterWiseView from "./ChapterWiseView/ChapterWiseView";
import MistakeWiseView from "./MistakeWiseView/MistakeWiseView";
import TestWiseView from "./TestWiseView/TestWiseView";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #ffffff",
  "& .MuiTabs-indicator": {
    backgroundColor: darkTheme.colors.orange,
    borderBottom: "4px solid " + darkTheme.colors.orange,
  },
});

const AntTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(2),
  color: darkTheme.colors.font.light,
  fontFamily: [
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    // color: "#40a9ff",
    color: darkTheme.colors.orange,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: darkTheme.colors.orange,
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&.Mui-focusVisible": {
    // backgroundColor: "#d1eaff",
    backgroundColor: darkTheme.colors.orange,
  },
}));

interface StyledTabProps {
  label: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const StudentDashboard: FunctionComponent = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fade in={true}>
      <Box>
        <Box
          sx={{
            mt: 4,
            ml: 0,
            mr: 0,
            mb: 0,
            width: "100%",
            // backgroundColor: darkTheme.colors.surface.five,
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: darkTheme.colors.surface.five }}>
              <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab label="Test wise view" sx={{ ml: 4 }} />
                <AntTab label="Chapter wise view" />
                <AntTab label="Mistake wise view" />
              </AntTabs>
            </Box>
          </Box>
        </Box>
        <Box>
          <CustomTabPanel value={value} index={0}>
            <TestWiseView />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <ChapterWiseView />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <MistakeWiseView />
          </CustomTabPanel>
        </Box>
      </Box>
    </Fade>
  );
};

export default StudentDashboard;
