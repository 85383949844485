import { Box, Typography, styled } from "@mui/material";
import { useEffect, useRef } from "react";
import { PageNavigation, PageThumb } from "../Theme/PDFtheme";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";

const PagesNavigator = ({ canvasRefs, currentPage, setCurrentPage }: any) => {
  const navigationRef: any = useRef(null);

  const scrollToPage = (index: any) => {
    setCurrentPage(index);
    const pageElement: any = document.querySelector(`[data-index="${index}"]`);

    if (pageElement) {
      // Get header height - add a class or id to your header
      const headerHeight =
        (document.querySelector(".sticky-header") as HTMLElement)
          ?.offsetHeight || 0;

      // Calculate offset with extra spacing
      const extraSpacing = 20; // Add extra pixels for comfort
      const offset = headerHeight + extraSpacing;

      // First scroll into view
      pageElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // Then adjust for header and extra space
      setTimeout(() => {
        window.scrollBy({
          top: -offset,
          behavior: "smooth",
        });
      }, 100);
    }
  };

  useEffect(() => {
    const thumbElement = navigationRef.current?.querySelector(
      `[data-thumb-index="${currentPage}"]`,
    );
    if (thumbElement) {
      thumbElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [currentPage]);

  return canvasRefs?.length > 0 ? (
    <PageNavigation ref={navigationRef} className="scroll-container-vertical">
      {canvasRefs?.map((canvasRef: any, index: any) => (
        <PageThumb
          key={index}
          data-thumb-index={index}
          active={currentPage === index}
          onClick={() => scrollToPage(index)}
          elevation={currentPage === index ? 2 : 1}
        >
          <Typography variant="subtitle1">Page {index + 1}</Typography>
          <Box className="thumbnail-container">
            <img
              width={150}
              src={canvasRef?.imageUrl}
              alt={`Page ${index + 1}`}
              loading="lazy"
            />
          </Box>
        </PageThumb>
      ))}
    </PageNavigation>
  ) : null;
};

// Styled components
const StyledPageNavigation = styled(PageNavigation)`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 200px;
  overflow-y: auto;
  background: ${darkTheme.colors.teacher.pdf.pdfheader.white};
  padding: 16px;

  .thumbnail-container {
    display: flex;
    justify-content: center;
    padding-left: 16px;
    margin-left: 16px;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export { StyledPageNavigation as PageNavigation, PagesNavigator };
