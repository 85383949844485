import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLocalStorage, showErrorToast } from "../../commonFunctions";
import { useStudentNaviagation } from "../../hooks/student/navigationHooks";
import { showHideLoader } from "../../storage/common";
import { RootState } from "../../storage/store";
import {
  setCityOfState,
  setCountryList,
  setStateOfCountry,
} from "../../storage/student/location";
import {
  personalDetail,
  setBoardList,
  setCoachingClassBranchList,
  setCoachingClassList,
  setExamDatesList,
  setGradeList,
  setLanguageList,
  setSchoolBranchList,
  setSchoolList,
} from "../../storage/student/profile";
import psApiService from "../apiService";

interface loginUsingEmailPasswordApiParams {
  email: string;
  password: string;
  loginMethod: string;
}
interface loginByPhoneApiParams {
  mobile: string;
  otp: string;
  loginMethod: string;
}

export const useStudentProfileAPICalls = () => {
  const studentNaviage = useStudentNaviagation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    studentProfile: { profile },
  } = useSelector((state: RootState) => state);

  // const storeLoginDataInStorage = (data: any) => {
  //   console.log(data);
  //   const userData = data?.data;
  //   setLocalStorage("loggedInUser", userData);
  // };

  const storeProfileDataInStorage = (data: any) => {
    const userData = data?.data;
    setLocalStorage("profileInfo", userData);
  };

  const errorFunction = (error: any) => {
    dispatch(showHideLoader(false));
    console.log(error);
  };

  const getSchoolList = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.school.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setSchoolList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getSchoolBranchList = (schoolName: string) => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.school.branch.get", { schoolName })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setSchoolBranchList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getCoachingClassList = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.coachingclass.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setCoachingClassList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getCoachingClassBranchList = (className: string) => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.coachingclass.branch.get", { className })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setCoachingClassBranchList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getBoardDetails = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.board.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setBoardList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
    // dispatch(
    //   setBoardList([
    //     {
    //       boardName: "CBSE",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //     {
    //       boardName: "ICSE",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //     {
    //       boardName: "GSEB/Gujarat",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //     {
    //       boardName: "KSEAB/Karnataka",
    //       boardLogoUrl: null,
    //       boardUrl: null,
    //     },
    //   ])
    // );
  };
  const getLanguageDetails = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.language.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setLanguageList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
    dispatch(setLanguageList(["English", "Hindi", "Gujarati", "Marathi"]));
    // dispatch(setLanguageList(["one", "two", "three", "four"]));
    // dispatch(setLanguageList(["five", "six", "seven", "nine"]));
    // dispatch(setLanguageList(["ten", "elavon", "twelve", "thirteen"]));
  };
  const getGradeDetails = () => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.grade.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setGradeList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
    dispatch(setGradeList(["7", "8", "9", "10", "11", "12"]));
    // dispatch(setLanguageList(["one", "two", "three", "four"]));
    // dispatch(setLanguageList(["five", "six", "seven", "nine"]));
    // dispatch(setLanguageList(["ten", "elavon", "twelve", "thirteen"]));
  };
  const getExamDateList = (boardName: string) => {
    dispatch(showHideLoader(true));
    psApiService("student.profile.examdate.get", { boardName })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setExamDatesList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getStudentProfileDetails = () => {
    dispatch(showHideLoader(true));
    return psApiService("student.profile.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          const data = result.data?.data;
          console.log("parsing data ", data);
          const personalDetailObject = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            bio: data.bio,
            class: {
              name: data.classDto.name,
              branch: data.classDto.branch,
              address: data.classDto.address,
            },
            isActive: data.isActive,
            isCustomer: data.isCustomer,
            language: data.language,
            phoneno: data.phoneNumber,
            photoUrl: data.photoUrl,
            school: {
              name: data.school.name,
              branch: data.school.branch,
              address: data.school.address,
            },
            socialLinks: data.socialLinks,
            grade: data.grade,
            board: data.board,
            userAddress: data.userAddress,
            city: data.userAddress?.city || "",
            userType: data.userType,
            birthdate: data?.dob || "",
            subjects: data?.subjects,
          };
          dispatch(personalDetail(personalDetailObject));
          storeProfileDataInStorage(result?.data);
        } else {
          if (result?.data?.message) {
            const { message } = result?.data;
            showErrorToast(message, true);
          }
        }
        dispatch(showHideLoader(false));
        return result;
      })
      .catch(errorFunction);
  };

  const getStateList = (country: string) => {
    dispatch(showHideLoader(true));
    psApiService("student.location.state.get", { country })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(
              setStateOfCountry({
                countryCode: country,
                stateList: result.data.data,
              }),
            );
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };
  const getCityList = (country: string, state: string) => {
    dispatch(showHideLoader(true));
    psApiService("student.location.city.get", { country, state })
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(
              setCityOfState({
                stateName: state,
                cityList: result.data.data,
              }),
            );
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const getCountryList = () => {
    dispatch(showHideLoader(true));
    psApiService("student.location.country.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(
              setCountryList({
                countryList: result.data.data,
              }),
            );
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  const updateProfileData = () => {
    dispatch(showHideLoader(true));

    const profileData: object = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      phoneNumber: profile.phoneno || 0,
      parentEmail: profile.email,
      address: {
        addressLine1: profile.userAddress?.addressLine1 || "",
        addressLine2: profile.userAddress?.addressLine2 || "",
        addressLine3: profile.userAddress?.addressLine3 || "",
        city: profile.userAddress?.city || "",
        state: profile.userAddress?.state || "",
        country: profile.userAddress?.country || "",
        pinCode: profile.userAddress?.pinCode || "",
      },
      isActive: true,
      isCustomer: true,
      photoUrl: profile.photoUrl,
      subjects: ["Maths", "Science", "Physics", "Chemistry", "Biology"],
      grade: profile.grade,
      board: profile.board,
      school: {
        name: profile.school.name,
        branch: profile.school.branch,
        address: {
          addressLine1: profile.school.address?.addressLine1 || "",
          addressLine2: profile.school.address?.addressLine2 || "",
          addressLine3: profile.school.address?.addressLine3 || "",
          city: profile.school.address?.city || "",
          state: profile.school.address?.state || "",
          country: profile.school.address?.country || "",
          pinCode: profile.school.address?.pinCode || "",
        },
      },
      classDto: {
        name: profile.class.name,
        branch: profile.class.branch,
        address: {
          addressLine1: profile.class.address?.addressLine1 || "",
          addressLine2: profile.class.address?.addressLine2 || "",
          addressLine3: profile.class.address?.addressLine3 || "",
          city: profile.class.address?.city || "",
          state: profile.class.address?.state || "",
          country: profile.class.address?.country || "",
          pinCode: profile.class.address?.pinCode || "",
        },
      },
      language: profile.language,
      userType: {
        userType: "Student",
        subUserType: "Student",
      },
      socialLinks: {
        faceBookLink: "",
        instagramLink: "",
        linkedInLink: "",
        twitterLink: "",
      },
      bio: "",
      dob: profile.birthdate,
      gender: "",
    };

    // console.log('profile data to update ', profileData);

    psApiService("student.profile.update", profileData)
      .then((result: any) => {
        if (result?.data.status === 200) {
          studentNaviage.navigateToDashboard();
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction);
  };

  return {
    getBoardDetails,
    getLanguageDetails,
    getGradeDetails,
    getStudentProfileDetails,
    getSchoolList,
    getSchoolBranchList,
    getCoachingClassList,
    getCoachingClassBranchList,
    getExamDateList,
    getStateList,
    getCityList,
    getCountryList,
    updateProfileData,
  };
};
