import {
  Button,
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  PsButton,
  PsInput,
  StepBox,
} from "../../../../lib/psElements/psElements";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
import { AppDispatch, RootState } from "../../../../services/storage/store";
import { EducationData } from "../../../../services/storage/teacher/TProfile";
import InputFileUpload from "../../../common/buttons/Upload";
import { GoBackArrowIos } from "../../../common/GoBackButton/GoBackButton";
import Stepsbar from "./Stepbar";
// import { Postprofile } from '../../../../services/api/ApiStore'

export const Eduction = () => {
  const teachernavigation = useTeacherNavigation();
  const { education } = useSelector(
    (state: RootState) => state.TeacherprofileData,
  );

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const navigateToPersonalinformation = () => {
    teachernavigation.navigateToTeacherPersonalinformation();
  };

  const handleFileChange = (e: any, fieldName: any) => {
    const file = e.target?.files?.[0];
    if (file) {
      dispatch({
        type: "EducationData",
        payload: { [fieldName]: file },
      });
    } else {
      console.error("No file selected or 'files' is undefined");
    }
  };
  const years = Array.from(
    { length: new Date().getFullYear() - 1999 },
    (_, i) => 2000 + i,
  );

  const validationSchema = Yup.object().shape({
    education: Yup.array().of(
      Yup.object().shape({
        Degree: Yup.string().required("Degree name is required"),
        Subject: Yup.string().required("Subject is required"),
        Year: Yup.string().required("Year is required"),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: {
      education: [
        {
          Degree: education.Degree || "",
          Subject: education.Subject || "",
          Year: education.Year || "",
          Certificate: education.Certificate || "",
        },
      ],
    },

    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        dispatch(EducationData(values));
        teachernavigation.navigateToTeacherExperience();
      } catch (error) {
        console.error("Submission error:", error);
        toast.error("An unexpected error occurred");
      }
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        padding: "0px 20px 0px 20px",
      }}
    >
      <Stepsbar currentStep={1} />
      <FormikProvider value={formik}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <StepBox sx={{ px: "30px", height: "500px" }}>
            <GoBackArrowIos
              sx={{ ml: 2, mt: 2, mb: 1 }}
              handlerBackButton={() => {
                navigateToPersonalinformation();
              }}
            />
            <Typography
              align="left"
              sx={{ ml: 3, fontWeight: 600, mb: 2.5 }}
              variant="h5"
            >
              Eduction
            </Typography>
            <FieldArray name="education">
              {({ push, remove }) => (
                <>
                  {formik.values.education.map((eduction, index) => (
                    <Grid2
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          xs: "repeat(2, 1fr)",
                          sm: "repeat(2, 1fr)",
                          md: "repeat(2, 1fr)",
                          lg: "repeat(4, 1fr)",
                        },
                        gap: "30px",
                        mt: "10px",
                      }}
                    >
                      <Grid2
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Grid2>
                          <PsInput
                            label={`Degree ${index + 1}`}
                            value={formik.values.education[index].Degree}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name={`education[${index}].Degree`}
                            sx={{ width: "260px" }}
                            error={
                              formik.touched.education?.[index]?.Degree &&
                              Boolean(
                                (
                                  formik.errors.education as FormikErrors<{
                                    Degree: string;
                                    Location: string;
                                    Year: string;
                                    Mt: string;
                                    ExperienceType: string;
                                  }>[]
                                )?.[index]?.Degree,
                              )
                            }
                          />
                          <FormHelperText
                            sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                          >
                            {formik.touched.education?.[index]?.Degree &&
                              (
                                formik.errors.education as FormikErrors<{
                                  Degree: string;
                                  Subject: string;
                                  Year: string;
                                }>[]
                              )?.[index]?.Degree}
                          </FormHelperText>
                        </Grid2>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Grid2>
                          <PsInput
                            label="Subject"
                            value={formik.values.education[index].Subject}
                            onChange={formik.handleChange}
                            name={`education[${index}].Subject`}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.education?.[index]?.Subject &&
                              Boolean(
                                (
                                  formik.errors.education as FormikErrors<{
                                    Degree: string;
                                    Subject: string;
                                    Year: string;
                                  }>[]
                                )?.[index]?.Subject,
                              )
                            }
                            sx={{ width: "260px" }}
                          />
                          <FormHelperText
                            sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                          >
                            {formik.touched.education?.[index]?.Subject &&
                              (
                                formik.errors.education as FormikErrors<{
                                  Degree: string;
                                  Subject: string;
                                  Year: string;
                                }>[]
                              )?.[index]?.Subject}
                          </FormHelperText>
                        </Grid2>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <Grid2>
                          <FormControl>
                            <InputLabel id="board-select-label">
                              Year
                            </InputLabel>
                            <Select
                              labelId="board-select-label"
                              id="board-select"
                              label="Year"
                              name={`education[${index}].Year`}
                              value={formik.values.education[index].Year}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              sx={{ width: "260px" }}
                              error={
                                formik.touched.education?.[index]?.Year &&
                                Boolean(
                                  (
                                    formik.errors.education as FormikErrors<{
                                      Degree: string;
                                      Subject: string;
                                      Year: string;
                                    }>[]
                                  )?.[index]?.Year,
                                )
                              }
                            >
                              {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                            >
                              {formik.touched.education?.[index]?.Year &&
                                (
                                  formik.errors.education as FormikErrors<{
                                    Degree: string;
                                    Subject: string;
                                    Year: string;
                                  }>[]
                                )?.[index]?.Year}
                            </FormHelperText>
                          </FormControl>
                        </Grid2>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Grid2>
                          <InputFileUpload
                            label="Upload Document"
                            accept=".pdf,.doc,.docx"
                            multiple
                            name=""
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                `education[${index}].Certificate`,
                              )
                            }
                          />
                        </Grid2>
                      </Grid2>

                      {index > 0 && (
                        <Grid2>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => remove(index)}
                          >
                            Remove Experience {index + 1}
                          </Button>
                        </Grid2>
                      )}
                    </Grid2>
                  ))}
                  <Grid2 sx={{ px: 0, margin: "10px 0px 10px 0px " }}>
                    <PsButton.Outlined
                      variant="outlined"
                      type="button"
                      onClick={() =>
                        push({
                          Degree: "",
                          Subject: "",
                          Year: "",
                          Certificate: "",
                        })
                      }
                    >
                      + Add another Education
                    </PsButton.Outlined>
                  </Grid2>
                </>
              )}
            </FieldArray>
          </StepBox>

          <Stack
            direction={"row"}
            gap={1}
            sx={{ mt: 4.5, justifyContent: "flex-end", mb: 2 }}
            alignSelf={"flex-end"}
          >
            <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
              Cancel
            </PsButton.Outlined>
            <PsButton.Primary
              variant="contained"
              sx={{ width: 240 }}
              type="submit"
            >
              Proceed
            </PsButton.Primary>
          </Stack>
        </form>
      </FormikProvider>
    </div>
  );
};
