import profiledata from "./student/profiledata.json";

const ApiJson: any = {
  student: {
    login: {
      email: {
        url: "/api/auth/authenticate",
        method: "POST",
        data: {
          email: "",
          password: "",
          loginMethod: "",
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
      phone: {
        url: "/api/auth/send-otp",
        method: "POST",
        data: {
          otp: "",
          mobile: "",
          loginMethod: "otp_mo",
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
      phoneAndOtp: {
        url: "/api/auth/authenticate",
        method: "POST",
        data: {
          otp: "",
          mobile: "",
          loginMethod: "otp_mo",
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
    },
    register: {
      url: "/api/auth/authenticate",
      method: "POST",
      data: {
        email: "",
        password: "",
        loginMethod: "email",
        firstName: "",
        lastName: "",
        userType: "Student",
      },
      showResultMessage: true,
      showErrorMessage: true,
    },
    profile: {
      get: {
        url: "/api/profile/user",
        method: "GET",
        data: {},
        showResultMessage: false,
        showErrorMessage: true,
      },
      update: {
        url: "/api/profile/user/update",
        method: "PUT",
        data: profiledata,
        showResultMessage: false,
        showErrorMessage: true,
      },
      board: {
        get: {
          url: "/api/board/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      language: {
        get: {
          url: "/api/language/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      grade: {
        get: {
          url: "/api/grade/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      school: {
        get: {
          url: "/api/school/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
        branch: {
          get: {
            url: "/api/school/branch?school=:schoolName",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
      },
      coachingclass: {
        get: {
          url: "/api/class/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
        branch: {
          get: {
            url: "/api/class/branch?class=:className",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
      },
      examdate: {
        get: {
          url: "/api/board/exam-months?boardName=:boardName",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
    },
    location: {
      state: {
        get: {
          url: "/api/address/:country/states",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      city: {
        get: {
          url: "/api/address/:country/:state/cities",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      country: {
        get: {
          url: "/api/address/countries/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
    },
    subject: {
      get: {
        url: "/api/subject/all",
        method: "GET",
        data: {},
        showResultMessage: false,
        showErrorMessage: true,
      },
    },
    chapter: {
      get: {
        url: "/api/chapter/user?subject=:subjectName&group=:groupName",
        method: "GET",
        data: {},
        showResultMessage: false,
        showErrorMessage: true,
      },
    },
    test: {
      paper: {
        generate: {
          url: "/api/question-paper/new",
          method: "POST",
          data: {
            subject: "",
            grade: "",
            board: "",
            difficulty: "",
            length: "",
            chapters: [],
            focusOnPreviousMistakes: "",
            pastPaperInclusion: "",
          },
          showResultMessage: false,
          showErrorMessage: true,
        },
        upload: {
          getpresignedurl: {
            url: "/api/aws/s3/upload-presigned-url?key=:fileName&type=answer-sheet",
            // url: "/api/aws/s3/generate-presigned-url?key=:fileName&type=answer-sheet",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
        assingtoteacher: {
          url: "/api/answer-sheet/upload",
          method: "POST",
          data: {
            testId: "",
            answerSheetUrl: "",
            useSubjectQueue: false,
          },
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      task: {
        getall: {
          url: "/api/tasks/student?subject=",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
        getbysubject: {
          url: "/api/tasks/student?subject=:subjectName",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
    },
  },
  ////Teacher Api
  teacher: {
    profile: {
      post: {
        url: "/api/",
        method: "POST",
        data: {
          Accountnumber: "",
          IFSC: "",
          Name: "",
          PANno: "",
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
      get: {
        url: "api/profile/teacher",
        method: "GET",
        data: {},
        showResultMessage: true,
        showErrorMessage: true,
      },
      update: {
        url: "/api/profile/teacher/update",
        method: "PUT",
        data: {
          subjectsTaught: [],
          schedule: {
            dailyLimit: "",
            weeklyLimit: "",
          },
          experienceHistory: [
            {
              institutionName: "",
              type: "",
              location: "",
              years: "",
              months: "",
              isWorkingHere: true,
            },
          ],
          educationHistory: [
            {
              degree: "",
              subject: "",
              year: "",
              certificateBlobURL: "",
            },
          ],
          bankingInfo: {
            bankingName: "",
            accountNo: "",
            ifscCode: "",
            panNo: "",
            bankName: " ",
            branch: "",
            pancardBlobURL: "",
          },
        },
        showResultMessage: true,
        showErrorMessage: true,
      },
      apigettask: {
        get: {
          url: "/api/tasks/teacher",
          method: "Get",
          data: {},
          showResultMessage: true,
          showErrorMessage: true,
        },
      },
      apiqnpapaerformat: {
        get: {
          url: "/api/retrieveQnPaperFormatAndContent",
          method: "Get",
          data: {},
          showResultMessage: true,
          showErrorMessage: true,
        },
      },
      apiqnpapaeranswersheet: {
        get: {
          url: "/api/retriveQnPaperAnsSheetPDF",
          method: "Get",
          data: {},
          showResultMessage: true,
          showErrorMessage: true,
        },
      },
      location: {
        state: {
          get: {
            url: "/api/address/:country/states",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
        city: {
          get: {
            url: "/api/address/:country/:state/cities",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
        country: {
          get: {
            url: "/api/address/countries/all",
            method: "GET",
            data: {},
            showResultMessage: false,
            showErrorMessage: true,
          },
        },
      },
      subject: {
        get: {
          url: "/api/subject/all",
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      pdf: {
        get: {
          url: `api/aws/s3/generate-presigned-url?key=:key&type=answer-sheet`,
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
        post: {
          url: `api/`,
          method: "POST",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      qnpaperdetails: {
        get: {
          url: `api/questions/paper/details/EFq6av9mb-2OMjK`,
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      file: {
        post: {
          url: `api/aws/s3/generate-presigned-url?key=:key&type=teacher-document`,
          method: "POST",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
        get: {
          url: `/api/aws/s3/upload-presigned-url?key=:key&type=teacher-document`,
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
      perticularTask: {
        get: {
          url: `api/test/details?testId=:testID`,
          method: "GET",
          data: {},
          showResultMessage: false,
          showErrorMessage: true,
        },
      },
    },
  },
};

export { ApiJson };
