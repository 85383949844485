import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import moment from "moment";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStudentTestAPICalls } from "../../../../../services/api/student/studentTestAPIMthods";
import { RootState } from "../../../../../services/storage/store";
import {
  setSelectedTestIdToUploadAnswerSheet,
  TaskListType,
} from "../../../../../services/storage/student/test";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";

const TaskList: FunctionComponent = () => {
  const {
    studentTest: { taskList, selectedTestIdToUploadAnswerSheet },
  } = useSelector((state: RootState) => state);
  const { getAllTasks } = useStudentTestAPICalls();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllTasks();
  }, []);

  const dateFormatForTheList = (dateString: string) => {
    return moment(dateString).format("DD MMMM YY");
  };

  const handlerRadioButtonClickToSelectTestID = (testId: string) => {
    dispatch(setSelectedTestIdToUploadAnswerSheet(testId));
  };

  return (
    <Box>
      <Typography sx={{ width: "100%", textAlign: "center", mt: 3 }}>
        You’ve {taskList?.length} test papers pending for submission.
      </Typography>
      <Typography sx={{ width: "100%", textAlign: "center", mt: 1, mb: 3 }}>
        Which one are you submitting?
      </Typography>
      <List sx={{ maxHeight: 300, overflowY: "auto", scrollbarWidth: "thin" }}>
        <ListItem
          sx={{
            background: darkTheme.colors.surface.two,
            textAlign: "left",
            gap: 2,
          }}
        >
          <ListItemText sx={{ maxWidth: 120 }}>Generated On</ListItemText>
          <ListItemText sx={{ maxWidth: 70 }}>Subject</ListItemText>
          <ListItemText sx={{ maxWidth: 70 }}>Chapters</ListItemText>
          <ListItemText sx={{ maxWidth: 70 }}>Length</ListItemText>
          <ListItemText sx={{ maxWidth: 70 }}>Difficulty</ListItemText>
          <ListItemButton>
            <Radio checked={false} sx={{ visibility: "hidden" }} />
          </ListItemButton>
        </ListItem>
        <Divider />
        {taskList?.map((item: TaskListType) => {
          return (
            <>
              <ListItem sx={{ pt: 0, pb: 0, gap: 2 }}>
                <ListItemText sx={{ maxWidth: 120 }}>
                  {dateFormatForTheList(item?.createdDate)}
                </ListItemText>
                <ListItemText sx={{ maxWidth: 70 }}>
                  {item?.subject}
                </ListItemText>
                <ListItemText sx={{ maxWidth: 70 }}>
                  {item?.subject}
                </ListItemText>
                <ListItemText sx={{ maxWidth: 70 }}>
                  {item?.subject}
                </ListItemText>
                <ListItemText sx={{ maxWidth: 60 }}>
                  {item?.subject}
                </ListItemText>
                <ListItemButton>
                  <Radio
                    checked={
                      String(item?.testId).toString() ===
                      selectedTestIdToUploadAnswerSheet
                    }
                    onChange={() =>
                      handlerRadioButtonClickToSelectTestID(
                        String(item?.testId).toString(),
                      )
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    </Box>
  );
};

export default TaskList;
