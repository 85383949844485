import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useStudentProfileAPICalls } from "../../services/api/student/studentProfileAPIMethods";
import {
  clearLocalStorage,
  getLocalStorage,
} from "../../services/commonFunctions";
import { useStudentNaviagation } from "../../services/hooks/student/navigationHooks";
import LeftSection from "../components/Authorization/LeftSection";
import RightSection from "../components/Authorization/RightSection";

export function Authorization() {
  const studentNaviage = useStudentNaviagation();
  const { getStudentProfileDetails } = useStudentProfileAPICalls();
  useEffect(() => {
    checkLoginInformation();
  }, []);

  const checkLoginInformation = () => {
    const loggedInUser = getLocalStorage("loggedInUser");
    const profileInfo = getLocalStorage("profileInfo");
    if (loggedInUser) {
      // alert(JSON.stringify(profileInfo, null, 2));
      if (loggedInUser?.userId && loggedInUser?.tokenValue) {
        if (profileInfo?.userType) {
          if (
            String(profileInfo.userType?.userType).toLowerCase() === "student"
          ) {
            getStudentProfileDetails();
            studentNaviage.navigateToDashboard();
          }
        } else {
          studentNaviage.navigateToCongratulations();
        }
      }
    } else {
      clearLocalStorage();
    }
  };

  return (
    <Stack direction="row" className="psAuthorization">
      <LeftSection />
      <RightSection />
    </Stack>
  );
}

{
  /* <Grid2 container className="psAuthorization">
      <Grid2 size={6}>
        <LeftSection />
      </Grid2>
      <Grid2 size={6}>
        <RightSection />
      </Grid2>
    </Grid2> */
}
{
  /* <Stack direction="row" className="psAuthorization">
      <LeftSection />
      <RightSection />
    </Stack> */
}
