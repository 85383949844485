import { useDispatch, useSelector } from "react-redux";
import {
  setLocalStorage,
  showErrorToast,
  showSuccessToast,
} from "../../commonFunctions";
import { useStudentNaviagation } from "../../hooks/student/navigationHooks";
import { showHideLoader } from "../../storage/common";
import { RootState } from "../../storage/store";
import {
  setDataToGenerateTestPaper,
  setTaskList,
  setTestChaptersList,
  setTestPaperLink,
  setTestSujectList,
  setUploadModalFlag,
} from "../../storage/student/test";
import psApiService from "../apiService";

interface loginUsingEmailPasswordApiParams {
  email: string;
  password: string;
  loginMethod: string;
}
interface loginByPhoneApiParams {
  mobile: string;
  otp: string;
  loginMethod: string;
}

interface chapterListAPIParams {
  subjectName: string;
  groupName: string;
}
interface gTestPaperAPIParams {
  subject: string;
  grade: string;
  board: string;
  difficulty: string;
  length: string;
  chapters: string[];
  focusOnPreviousMistakes: boolean;
  pastPaperInclusion: string;
}

interface assingnTestToTeacherTypes {
  testId: string;
  answerSheetUrl: string;
  useSubjectQueue: boolean;
  cb: () => void;
}

export const useStudentTestAPICalls = () => {
  const studentNaviage = useStudentNaviagation();
  const dispatch = useDispatch();
  const {
    studentProfile: { profile },
  } = useSelector((state: RootState) => state);

  const storeLoginDataInStorage = (data: any) => {
    console.log(data);
    const userData = data?.data;
    setLocalStorage("loggedInUser", userData);
  };

  const storeProfileDataInStorage = (data: any) => {
    const userData = data?.data;
    setLocalStorage("profileInfo", userData);
  };

  const errorFunction = (error: any) => {
    dispatch(showHideLoader(false));
    console.log(error);
  };

  const getSubjectList = () => {
    dispatch(showHideLoader(true));
    psApiService("student.subject.get", {})
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setTestSujectList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction)
      .finally(() => {
        dispatch(showHideLoader(false));
      });
  };
  const getChaptersList = (params: chapterListAPIParams) => {
    dispatch(showHideLoader(true));
    psApiService("student.chapter.get", params)
      .then((result: any) => {
        if (result?.data.status === 200) {
          if (result?.data?.data && Array.isArray(result.data.data)) {
            dispatch(setTestChaptersList([...result.data.data]));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction)
      .finally(() => {
        dispatch(showHideLoader(false));
      });
  };
  const generateTestPaper = (params: gTestPaperAPIParams) => {
    dispatch(showHideLoader(true));
    psApiService("student.test.paper.generate", params)
      .then((result: any) => {
        console.log(result);
        if (result?.data.status === 200) {
          if (result?.data?.data) {
            dispatch(setTestPaperLink(result.data.data));
            dispatch(
              setDataToGenerateTestPaper({
                testName: "",
                subject: "",
                difficulty: "",
                length: "",
                focusOnPreviousMistakes: false,
                includePastBoardPapers: "",
                viewSelected: false,
                selectedChapters: [],
              }),
            );
            studentNaviage.navigateToTestPage();
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction)
      .finally(() => {
        dispatch(showHideLoader(false));
      });
  };
  const generatePresignedURLToUploadTheTestAnswers = (params: {
    fileName: string;
  }) => {
    dispatch(showHideLoader(true));
    return psApiService("student.test.paper.upload.getpresignedurl", params)
      .then((result: any) => {
        console.log(result);
        if (result?.data.status === 200) {
          if (result?.data?.data) {
            return result?.data?.data;
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction)
      .finally(() => {
        dispatch(showHideLoader(false));
      });
  };

  const getAllTasks = () => {
    dispatch(showHideLoader(true));
    psApiService("student.test.task.getall", {})
      .then((result: any) => {
        console.log(result);
        if (result?.data.status === 200) {
          if (result?.data?.data) {
            dispatch(setTaskList(result.data.data));
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction)
      .finally(() => {
        dispatch(showHideLoader(false));
      });
  };
  /** Call the API after upload success of answer-sheet
   * after call the API test will assign to teacher for
   * check the paper and answer
   */
  const assignTestToTeacher = (props: assingnTestToTeacherTypes) => {
    dispatch(showHideLoader(true));
    psApiService("student.test.paper.assingtoteacher", props)
      .then((result: any) => {
        console.log(result);
        if (result?.data.status === 200) {
          if (result?.data?.data) {
            console.log("assing asnwer to teacher ", result.data.data);
            showSuccessToast("Submission of answer sheet successful", false);
            dispatch(setUploadModalFlag(false));
            studentNaviage.navigateToDashboard();
            if (typeof props.cb === "function") props.cb();
          }
        } else if (result?.data?.message) {
          const { message } = result?.data;
          showErrorToast(message, true);
        }
        dispatch(showHideLoader(false));
      })
      .catch(errorFunction)
      .finally(() => {
        dispatch(showHideLoader(false));
        if (props.cb && typeof props.cb === "function") props.cb();
      });
  };

  return {
    getSubjectList,
    getChaptersList,
    generateTestPaper,
    generatePresignedURLToUploadTheTestAnswers,
    getAllTasks,
    assignTestToTeacher,
  };
};
