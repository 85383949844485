import {
  Button,
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  PsButton,
  PsCheckbox,
  StepBox,
} from "../../../../lib/psElements/psElements";
import { GoBackArrowIos } from "../../../common/GoBackButton/GoBackButton";
import Stepsbar from "./Stepbar";

import {
  FieldArray,
  Form,
  FormikErrors,
  FormikProvider,
  useFormik,
} from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../services/storage/store";
import { EnrollmentData } from "../../../../services/storage/teacher/TProfile";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";

export const Enrollment = () => {
  const teachernavigation = useTeacherNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const { enrollment } = useSelector(
    (state: RootState) => state.TeacherprofileData,
  );

  const navigate = useNavigate();

  const boardNames = [
    "ICSE",
    "CBSE",
    "Maharastra",
    "MP",
    "Gujrat",
    "Karnataka",
    "Rajasthan",
    "UP",
  ];

  const subjectNames = [
    "Maths",
    "Gujrati",
    "Science",
    "Physics",
    "English",
    "Hindi",
    "Chemistry",
    "Biology",
  ];

  const navigateToExperience = () => {
    teachernavigation.navigateToTeacherExperience();
  };

  const validationSchema = Yup.object().shape({
    enrollments: Yup.array()
      .of(
        Yup.object().shape({
          Board: Yup.string().required("Board name is required"),
          subject: Yup.string().required("Subject is required"),
        }),
      )
      .min(1, "At least one enrollment is required"),
  });

  const formik = useFormik({
    initialValues: {
      enrollments: [
        {
          Board: enrollment.Board || "",
          subject: enrollment.subject || "",
          grade: enrollment.grade ? [enrollment.grade] : "9",
        },
      ],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        dispatch(EnrollmentData(values));
        teachernavigation.navigateToTeacherSchedule();
      } catch (error) {
        console.error("Submission error:", error);
        toast.error("An unexpected error occurred");
      }
    },
  });
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        padding: "0px 20px 0px 20px",
      }}
    >
      <Stepsbar currentStep={3} />
      <FormikProvider value={formik}>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <StepBox sx={{ px: "30px", minHeight: "500px" }}>
            <GoBackArrowIos
              sx={{ ml: 2, mt: 2, mb: 1 }}
              handlerBackButton={navigateToExperience}
            />
            <Typography
              align="left"
              sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
              variant="h5"
            >
              Enrollment
            </Typography>

            <FieldArray name="enrollments">
              {({ push, remove }) => (
                <>
                  {formik.values.enrollments.map((enrollment, index) => (
                    <Grid2
                      key={index}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: {
                          xs: "repeat(1, 1fr)",
                          sm: "repeat(1, 1fr)",
                          md: "repeat(2, 1fr)",
                          lg: "repeat(3, 1fr)",
                        },
                        gap: "10px",
                        mb: 2,
                        position: "relative",
                      }}
                    >
                      <Grid2
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id={`board-select-label-${index}`}>
                            Board
                          </InputLabel>
                          <Select
                            labelId={`board-select-label-${index}`}
                            id={`board-select-${index}`}
                            name={`enrollments.${index}.Board`}
                            value={enrollment.Board}
                            label="Board"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.enrollments?.[index]?.Board &&
                              Boolean(
                                (
                                  formik.errors.enrollments as FormikErrors<{
                                    Board: string;
                                    subject: string;
                                    grade: string[];
                                  }>[]
                                )?.[index]?.Board,
                              )
                            }
                          >
                            {boardNames.map((board) => (
                              <MenuItem key={board} value={board}>
                                {board}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                          >
                            {formik.touched.enrollments?.[index]?.Board &&
                              (
                                formik.errors.enrollments as FormikErrors<{
                                  Board: string;
                                  subject: string;
                                  grade: string[];
                                }>[]
                              )?.[index]?.Board}
                          </FormHelperText>
                        </FormControl>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel id={`subject-select-label-${index}`}>
                            Subject
                          </InputLabel>
                          <Select
                            labelId={`subject-select-label-${index}`}
                            id={`subject-select-${index}`}
                            name={`enrollments.${index}.subject`}
                            value={enrollment.subject}
                            label="Subject"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.enrollments?.[index]?.subject &&
                              Boolean(
                                (
                                  formik.errors.enrollments as FormikErrors<{
                                    Board: string;
                                    subject: string;
                                    grade: string[];
                                  }>[]
                                )?.[index]?.subject,
                              )
                            }
                          >
                            {subjectNames.map((subject) => (
                              <MenuItem key={subject} value={subject}>
                                {subject}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                          >
                            {formik.touched.enrollments?.[index]?.subject &&
                              (
                                formik.errors.enrollments as FormikErrors<{
                                  Board: string;
                                  subject: string;
                                  grade: string[];
                                }>[]
                              )?.[index]?.subject}
                          </FormHelperText>
                        </FormControl>
                      </Grid2>
                      <Grid2
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Typography>Grades:</Typography>
                        <Grid2 sx={{ display: "inline-flex", gap: "15px" }}>
                          <PsCheckbox
                            sx={{
                              "&.Mui-checked": {
                                color: `${darkTheme.colors.teacher.theme.yellow}`,
                              },
                            }}
                            name={`enrollments.${index}.grade`}
                            checked={enrollment.grade.includes("9")}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              formik.setFieldValue(
                                `enrollments.[${index}].grade`,
                                isChecked ? "9" : "10",
                              );
                            }}
                          />
                          <Typography>9</Typography>
                        </Grid2>

                        <Grid2 sx={{ display: "inline-flex", gap: "15px" }}>
                          <PsCheckbox
                            sx={{
                              "&.Mui-checked": {
                                color: `${darkTheme.colors.teacher.theme.yellow}`,
                              },
                            }}
                            name={`enrollments.${index}.grade`}
                            checked={enrollment.grade.includes("10")}
                            onChange={(e: any) => {
                              const isChecked = e.target.checked;
                              formik.setFieldValue(
                                `enrollments.[${index}].grade`,
                                isChecked ? "10" : "9",
                              );
                            }}
                          />
                          <Typography>10</Typography>
                        </Grid2>
                      </Grid2>
                      {index > 0 && (
                        <Grid2>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => remove(index)}
                          >
                            Remove Experience {index + 1}
                          </Button>
                        </Grid2>
                      )}
                    </Grid2>
                  ))}

                  <Grid2 sx={{ px: 0, margin: "20px 0px 10px 0px" }}>
                    <PsButton.Outlined
                      variant="outlined"
                      type="button"
                      onClick={() =>
                        push({ Board: "", subject: "", grade: [] })
                      }
                    >
                      + Add another Education
                    </PsButton.Outlined>
                  </Grid2>
                </>
              )}
            </FieldArray>
          </StepBox>

          <Stack
            direction="row"
            gap={1}
            sx={{ mt: 4.5, justifyContent: "flex-end", mb: 2 }}
          >
            <PsButton.Outlined
              variant="outlined"
              sx={{ width: 120 }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </PsButton.Outlined>
            <PsButton.Primary
              variant="contained"
              sx={{ width: 240 }}
              type="submit"
            >
              Proceed
            </PsButton.Primary>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
};
