import { createSlice } from "@reduxjs/toolkit";
import { UserTypeEnum } from "../../constants/types/userTypes";

interface CommonStoreType {
  loaderShowHide: boolean;
  sideMenuShowHide: boolean;
  userType: UserTypeEnum;
}

const _initialState: CommonStoreType = {
  loaderShowHide: false,
  sideMenuShowHide: false,
  userType: UserTypeEnum.Student,
};

const common = createSlice({
  name: "common",
  initialState: _initialState,
  reducers: {
    showHideLoader(state, action: { payload: boolean }) {
      state.loaderShowHide = action.payload;
    },
    showHideSideMenu(state, action: { payload: boolean }) {
      state.sideMenuShowHide = action.payload;
    },
  },
});

export const { showHideLoader, showHideSideMenu } = common.actions;
export default common.reducer;
