import { createSlice } from "@reduxjs/toolkit";

const location = createSlice({
  name: "Teacherlocation",
  initialState: {
    country: [],
    state: [],
    city: [],
  },

  reducers: {
    setStateOfCountry(
      state,
      action: { payload: { countryCode: string; stateList: [] } },
    ) {
      const { countryCode, stateList } = action.payload;
      state.state = stateList;
    },
    setCityOfState(
      state,
      action: { payload: { stateName: string; cityList: [] } },
    ) {
      const { cityList } = action.payload;
      state.city = cityList;
    },
    setCountryList(state, action: { payload: { countryList: [] } }) {
      const { countryList } = action.payload;
      if (countryList) state.country = countryList;
    },
  },
});

export const { setStateOfCountry, setCityOfState, setCountryList } =
  location.actions;
export default location.reducer;
