import { Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const TeacherProfile = () => {
  return (
    <div className="stepsContainer">
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          fontSize: "35px",
          mb: "10px",
        }}
      >
        Let us know you a bit more
      </Typography>
      <Outlet />
    </div>
  );
};

export default TeacherProfile;
