import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { darkTheme } from "../../../styles/psMuiTheme/colorCodes";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface InputFileUploadProps {
  label?: string;
  accept?: string;
  multiple?: boolean;
  onChange?: (files: File | File[] | null) => void;
  name: string;
}

const InputFileUpload: React.FC<InputFileUploadProps> = ({
  label = "Upload files",
  name = "",

  accept = "*",
  multiple = false,
  onChange,
}) => {
  const [fileNames, setFileNames] = useState<string | string[] | null>(
    name || null,
  );

  // const handleFileChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>,
  // ) => {
  //   const files = event.target.files;
  //   if (files) {
  //     const fileArray = Array.from(files);
  //     const fileNamesArray = fileArray.map((file) => file.name);
  //     setFileNames(multiple ? fileNamesArray : fileNamesArray[0]);
  //     const blobPromises = fileArray.map((file) => convertToBlobString(file));
  //     const blobStrings = await Promise.all(blobPromises);
  //     if (onChange) {
  //       onChange(multiple ? blobStrings : blobStrings[0] || null);
  //     }
  //   }
  // };

  // const convertToBlobString = (file: File): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //     reader.readAsDataURL(file);
  //   });
  // };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const fileNamesArray = fileArray.map((file) => file.name);
      setFileNames(multiple ? fileNamesArray : fileNamesArray[0]);

      if (onChange) {
        onChange(multiple ? fileArray : fileArray[0] || null);
      }
    }
  };

  const handleClearAll = () => {
    setFileNames(null);
    if (onChange) {
      onChange(null);
    }
  };
  return (
    <div>
      <Button
        component="label"
        variant="outlined"
        startIcon={<CloudUploadIcon />}
        sx={{
          textWrap: "nowrap",
          mt: "0px",
          border: "none",
          color: `${darkTheme.colors.teacher.theme.yellow}`,
        }}
      >
        {label}
        <VisuallyHiddenInput
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={handleFileChange}
        />
      </Button>
      {fileNames && (
        <Typography
          variant="body2"
          sx={{ mt: 1, color: "gray", wordBreak: "break-word" }}
        >
          {multiple
            ? `Selected files: ${Array.isArray(fileNames) ? fileNames.join(", ") : fileNames}`
            : `Selected file: ${fileNames}`}
        </Typography>
      )}
    </div>
  );
};

export default InputFileUpload;
