import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { PsButton } from "../../../../../lib/psElements/psElements";
import { RootState } from "../../../../../services/storage/store";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import FileDragDropContainer from "./FileDragDropContainer";
import TaskList from "./TaskList";

interface UploadTestPaperPropType {
  open: boolean;
  openCallBack: any;
}

const UploadTestPaper: FunctionComponent<UploadTestPaperPropType> = (
  props: UploadTestPaperPropType,
) => {
  const { open, openCallBack } = props;
  const {
    studentTest: { taskList },
  } = useSelector((state: RootState) => state);

  const handlerCancelButton = () => {
    openCallBack(false);
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      sx={{
        borderRadius: 2,
        ".MuiPaper-root": {
          borderRadius: 2,
        },
      }}
    >
      <DialogContent
        sx={{
          background: darkTheme.colors.menu.background,
        }}
      >
        <Box
          sx={{
            minHeight: 200,
            background: darkTheme.colors.menu.background,
            border: `1px dashed ${darkTheme.colors.font.dark}`,
            borderRadius: 2,
            alignItems: "center",
            display: "flex",
            flexFlow: "column",
          }}
        >
          <FileDragDropContainer />
        </Box>
        <TaskList />
      </DialogContent>

      <DialogActions
        sx={{ background: darkTheme.colors.menu.background, p: 4, gap: 2 }}
      >
        <PsButton.CustomTheme
          variant="outlined"
          fullWidth
          onClick={handlerCancelButton}
        >
          Cancel
        </PsButton.CustomTheme>
        <PsButton.Custom variant="contained" fullWidth>
          Submit
        </PsButton.Custom>
      </DialogActions>
    </Dialog>
  );
};

export default UploadTestPaper;
