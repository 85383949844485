// / PdfViewer.tsx
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { TeacherAPIcalls } from "../../../../../services/api/teacher/ProfileAPI";
import { RootState } from "../../../../../services/storage/store";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import { useCanvasDrawing } from "../../../../common/hook/useCanvasDrawing";
import { ImagePreviewModal } from "../../../ImagePreviewDialog";
import { PagesNavigator } from "../LeftPanel/PdfPages";
import PdfPage from "../MiddlePanel/PdfPage";
import { ToolPanel } from "../MiddlePanel/ToolPanel";
import { DeleteConfirmationModal } from "../RightPanel/DeleteConfirmDialog";
import { RightPanel } from "../RightPanel/Rightpanel";
import debounce from "lodash/debounce";
import {
  ContentAreaMain,
  LeftSection,
  LoadingOverlay,
  PDFContainer,
  PdfNavigationSection,
  RightSection,
  ToolPanelContainer,
} from "../Theme/PDFtheme";
import { PDFfooter } from "./PDFfooter";
import { PDFheader } from "./PDFheader";
import questionWisePaperPatternList from "../RightPanel/ICSE";

interface Region {
  id: number;
  image: string;
  timestamp: string;
  pageNumber: number;
  dimensions: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  description: string;
}

export const PdfViewer: React.FC = () => {
  // const [file, setFile] = useState<File | null>(null);
  const [canvasRefs, setCanvasRefs] = useState<
    React.RefObject<HTMLCanvasElement | null>[]
  >([]);
  // const [canvasRefs, setCanvasRefs] = useState<React.RefObject<HTMLCanvasElement | null>[]>([]);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState("");

  // const [isDownloading, setIsDownloading] = useState(false);
  const [isSelectingRegion, setIsSelectingRegion] = useState(false);
  // State management
  const [activeSection, setActiveSection] = useState(1);
  const [activeQuestion, setActiveQuestion] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const handleAccordionChange = (sectionId: number) => {
    setActiveSection(activeSection === sectionId ? 0 : sectionId);
  };

  const {
    // Id
    PDF,
    testID,
    perticularTask,
  } = useSelector((state: RootState) => state.TeacherprofileData);
  const { fetchPDf, fetchPerticularTask } = TeacherAPIcalls();
  React.useEffect(() => {
    fetchPDf("6922jMuOV8m7-GAg");
    fetchPerticularTask(Number(testID)); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestionClick = (questionId: number) => {
    setActiveQuestion(questionId);
  };
  const [selectionStart, setSelectionStart] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<{
    x: number;
    y: number;
  } | null>(null);

  /// for json
  interface QuestionPattern {
    level1: string;
    level2: string;
    level3: string;
    questionNumber: string;
    questionType: string;
  }

  interface CapturedRegion {
    id: number;
    title: string;
    isExpanded: boolean;
    questions: {
      id: number;
      questionNumber: string;
      totalMarks: number;
      inputMarks: number;
      regions: any[];
      subquestions?: {
        id: number;
        Subquestion: string;
        totalmarks: number;
      }[];
      subQue?: {
        id: number;
        totalMarks: number;
        obtainedMarks: number;
        attempt: string;
        Qngraphics: string;
        modelAnswer: string;
      }[];
    }[];
  }

  const mapPaperPatternToCapturedRegions = (
    paperPatterns: QuestionPattern[],
  ): CapturedRegion[] => {
    // Group questions by level1 (Section)
    const sectionGroups = paperPatterns.reduce(
      (acc, pattern) => {
        if (!acc[pattern.level1]) {
          acc[pattern.level1] = [];
        }
        acc[pattern.level1].push(pattern);
        return acc;
      },
      {} as Record<string, QuestionPattern[]>,
    );

    return Object.entries(sectionGroups).map(
      ([section, questions], sectionIndex) => ({
        id: sectionIndex + 1,
        title: `Section ${section}`,
        isExpanded: false,
        questions: questions.reduce(
          (questionAcc, pattern, questionIndex) => {
            const existingQuestion = questionAcc.find(
              (q) => q.questionNumber === `Q-${questionIndex + 1}`,
            );

            if (!existingQuestion) {
              questionAcc.push({
                id: questionIndex + 1,
                questionNumber: `Q-${questionIndex + 1}`,
                totalMarks: 0,
                inputMarks: 0,
                regions: [],
                subquestions: [
                  {
                    id: 1,
                    Subquestion: pattern.level3,
                    totalmarks: 0,
                  },
                ],
                subQue: [
                  {
                    id: 1,
                    totalMarks: 0,
                    obtainedMarks: 0,
                    attempt: "No",
                    Qngraphics: "",
                    modelAnswer: "",
                  },
                ],
              });
            }

            return questionAcc;
          },
          [] as CapturedRegion["questions"],
        ),
      }),
    );
  };
  const mappedRegions = mapPaperPatternToCapturedRegions(
    questionWisePaperPatternList,
  );
  // console.log("mappedRegions", mappedRegions);
  const [capturedRegions, setCapturedRegions] = useState<any>([
    {
      id: 1,
      title: "Section A",
      isExpanded: true,
      questions: [
        {
          id: 1,
          questionNumber: "Q-1",
          totalMarks: 20,
          inputMarks: 18,
          regions: [],
          subquestions: [
            {
              id: 1,
              Subquestion: "Sub Q-1",
              totalmarks: 20,
            },
          ],
          subQue: [
            {
              id: 1,
              totalMarks: 4,
              obtainedMarks: 3,
              attempt: "Yes",
              Qngraphics: "",
              modelAnswer: "Lorem ipsum dolor sit amet consectetur sint!",
            },
          ],
        },
        {
          id: 2,
          questionNumber: "Q-2",
          totalMarks: 15,
          inputMarks: 0,
          regions: [],
        },
      ],
    },
    {
      id: 2,
      title: "Section B",
      isExpanded: false,
      questions: [
        {
          id: 3,
          questionNumber: "Q-3",
          totalMarks: 25,
          inputMarks: 0,
          regions: [],
          subquestions: [
            {
              id: 1,
              Subquestion: "Sub Q-1",
              totalmarks: 20,
            },
          ],
        },
      ],
    },
  ]);
  interface PDFPage {
    pageNumber: number;
    imageUrl: string;
  }

  interface PDFDocument {
    id: string;
    name: string;
    pages: PDFPage[];
    totalPages: number;
    createdAt: string;
    blobUrl?: string;
  }

  const [isRegionCapture, setIsRegionCapture] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Region | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [regionToDelete, setRegionToDelete] = useState<Region | null>(null);
  const [pdfDocuments, setPdfDocuments] = useState<PDFDocument[]>([]);
  // Refs
  const containerRef = useRef<HTMLDivElement | null>(null);

  const selectionRectRef = useRef<HTMLDivElement>(null);

  const {
    currentTool,

    currentColor,
    lineWidth,
    fontSize,
    textInput,
    stampType,
    history,
    currentStep,
    setCurrentTool,
    setCurrentColor,
    setLineWidth,
    setFontSize,
    setTextInput,
    setStampType,
    setCurrentStep,
    setHistory,
    startDrawing,
    draw,
    stopDrawing,
    handleUndo,
    handleRedo,
  }: any = useCanvasDrawing({
    initialColor: `${darkTheme.colors.teacher.pdf.pdfheader.initialColor}`,
    initialLineWidth: 2,
    initialFontSize: 24,
  });
  // Initialize PDF.js
  useEffect(() => {
    const pdfjsScript = document.createElement("script");
    pdfjsScript.src =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.min.js";
    pdfjsScript.onload = () => {
      if (window && (window as any).pdfjsLib) {
        (window as any).pdfjsLib.GlobalWorkerOptions.workerSrc =
          "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js";
      }
    };
    document.head.appendChild(pdfjsScript);

    return () => {
      document.head.removeChild(pdfjsScript);
    };
  }, []);

  // Initialize history when a new canvas is created
  useEffect(() => {
    if (canvasRefs[0]?.current) {
      const initialState: any = {
        id: Date.now(),
        dataUrl: canvasRefs[0].current.toDataURL(),
        tool: "initial",
        timestamp: Date.now(),
      };
      setHistory([initialState]);
      setCurrentStep(0);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRefs]);

  useEffect(() => {
    const handleKeyboardShortcuts = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        e.preventDefault();
        if (e.shiftKey) {
          handleRedo();
        } else {
          handleUndo();
        }
      } else if ((e.ctrlKey || e.metaKey) && e.key === "y") {
        e.preventDefault();
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeyboardShortcuts);
    return () => window.removeEventListener("keydown", handleKeyboardShortcuts); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, history]);

  const startRegionSelection = (
    e: React.MouseEvent,
    canvas: HTMLCanvasElement,
  ) => {
    if (!isRegionCapture) return;

    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setSelectionStart({ x, y });
    setIsSelectingRegion(true);
  };

  const updateRegionSelection = (
    e: React.MouseEvent,
    canvas: HTMLCanvasElement,
  ) => {
    if (!isSelectingRegion || !isRegionCapture) return;

    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setSelectionEnd({ x, y });
  };

  const captureRegion = (canvas: HTMLCanvasElement) => {
    if (!selectionStart || !selectionEnd) return;

    const scale = canvas.width / canvas.getBoundingClientRect().width;
    const x = Math.min(selectionStart.x, selectionEnd.x) * scale;
    const y = Math.min(selectionStart.y, selectionEnd.y) * scale;
    const width = Math.abs(selectionEnd.x - selectionStart.x) * scale;
    const height = Math.abs(selectionEnd.y - selectionStart.y) * scale;

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = width;
    tempCanvas.height = height;
    const ctx = tempCanvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(canvas, x, y, width, height, 0, 0, width, height);
      const regionImage = tempCanvas.toDataURL("image/png");
      const timestamp = new Date().toLocaleString();
      const pageNumber =
        canvasRefs.findIndex((ref) => ref.current === canvas) + 1;

      const newRegion = {
        id: Date.now(),
        image: regionImage,
        timestamp,
        pageNumber,
        dimensions: { width, height, x, y },
        description: "",
      };

      setCapturedRegions((prevSections: any) =>
        prevSections.map((section: any) => {
          if (section.sectionName === activeSection) {
            return {
              ...section,
              questions: section.questions.map((question: any) => {
                if (question.questionId === activeQuestion) {
                  return {
                    ...question,
                    regions: [...question.regions, newRegion],
                  };
                }
                return question;
              }),
            };
          }
          return section;
        }),
      );
    }

    setSelectionStart(null);
    setSelectionEnd(null);
    setIsSelectingRegion(false);
  };
  const endRegionSelection = (canvas: HTMLCanvasElement) => {
    if (isSelectingRegion && isRegionCapture) {
      captureRegion(canvas);
    }
  };

  // const saveToHistory = (canvas: HTMLCanvasElement, tool: string) => {
  //   const newState: any = {
  //     id: Date.now(),
  //     dataUrl: canvas.toDataURL(),
  //     tool,
  //     timestamp: Date.now(),
  //   };

  //   const newHistory = history.slice(0, currentStep + 1);

  //   // Limit history size to prevent memory issues
  //   if (newHistory.length >= 50) {
  //     newHistory.shift();
  //   }

  //   newHistory.push(newState);
  //   setHistory(newHistory);
  //   setCurrentStep(newHistory.length - 1);
  // };
  const renderPageToCanvas = async (
    pdf: any,
    pageNumber: number,
    canvasRef: React.RefObject<HTMLCanvasElement>,
  ) => {
    // Get the specified page from the PDF
    const page = await pdf.getPage(pageNumber);

    // Set the scale for rendering (1.5 provides good resolution)
    const scale = 1.5;

    // Get the viewport at the specified scale
    const viewport = page.getViewport({ scale });

    // Get the canvas element
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Get the 2D rendering context
    const context = canvas.getContext("2d");
    if (!context) return;

    // Set canvas dimensions to match the viewport
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Prepare the rendering context object
    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    };

    // Render the page to the canvas
    await page.render(renderContext).promise;
  };

  // PDF Functions URL
  const TEST_PDF_URL = "https://gbihr.org/images/docs/test.pdf";
  const loadPdfFromUrl = async (pdfUrl: string) => {
    setLoading(true);
    // setError("");

    try {
      // Fetch the PDF and convert to blob
      const response = await fetch(pdfUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Convert blob to ArrayBuffer for PDF.js
      const arrayBuffer = await new Response(blob).arrayBuffer();
      const typedarray = new Uint8Array(arrayBuffer);

      // Load PDF using PDF.js
      const pdf = await (window as any).pdfjsLib.getDocument(typedarray)
        .promise;
      const numPages = pdf.numPages;

      // Create canvas refs
      const refs = Array.from({ length: numPages }, () =>
        React.createRef<HTMLCanvasElement>(),
      );
      setCanvasRefs(refs);

      // Process pages
      const pdfPages: PDFPage[] = [];
      for (let i = 0; i < numPages; i++) {
        const canvas = document.createElement("canvas");
        await renderPageToCanvas(pdf, i + 1, { current: canvas });
        pdfPages.push({
          pageNumber: i + 1,
          imageUrl: canvas.toDataURL("image/png"),
        });
      }

      // Create document object
      const newPdfDocument: PDFDocument = {
        id: Date.now().toString(),
        name: "Test PDF Document",
        pages: pdfPages,
        totalPages: numPages,
        createdAt: new Date().toISOString(),
        blobUrl: blobUrl,
      };
      setPdfDocuments((prev) => [...prev, newPdfDocument]);

      // Render to canvas refs
      for (let i = 0; i < numPages; i++) {
        if (refs[i].current) {
          await renderPageToCanvas(
            pdf,
            i + 1,
            refs[i] as React.RefObject<HTMLCanvasElement>,
          );
        }
      }

      setHistory([]);
      setCurrentStep(-1);
    } catch (err: any) {
      console.error("Error processing PDF:", err);
      // setError("Error processing PDF file: " + err.message);
    } finally {
      setLoading(false);
    }
  };
  const cleanup = () => {
    pdfDocuments.forEach((doc) => {
      if (doc.blobUrl) {
        URL.revokeObjectURL(doc.blobUrl);
      }
    });
  };

  const loadPdf = useCallback(
    debounce(() => {
      loadPdfFromUrl(TEST_PDF_URL);
    }, 300),
    [],
  );
  useEffect(() => {
    if (PDF) {
      loadPdf();
    }

    return () => {
      loadPdf.cancel();
      cleanup();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PDF, loadPdf]);

  // const downloadImage = (image: Region) => {
  //   const link = document.createElement("a");
  //   link.href = image.image;
  //   link.download = `screenshot-page${
  //     image.pageNumber
  //   }-${new Date().getTime()}.png`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  // const deleteRegion = (region: Region) => {
  //   setRegionToDelete(region);
  //   setShowDeleteConfirm(true);
  // };

  const handleDeleteConfirm = () => {
    if (regionToDelete) {
      setCapturedRegions((prev: any) =>
        prev.filter((region: any) => region.id !== regionToDelete.id),
      );
      if (selectedImage?.id === regionToDelete.id) {
        setSelectedImage(null);
      }
      setShowDeleteConfirm(false);
      setRegionToDelete(null);
      console.log("Delete confirmation completed.");
    }
  };

  // const handleDragOver = (e: React.DragEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  return (
    <>
      {" "}
      <Box sx={{ height: "100vh" }}>
        <PDFheader perticularTask={perticularTask} />
        <Divider />

        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100vh",
            display: "flex",
          }}
        >
          {/* Left side Page Navigation Section */}
          <PdfNavigationSection>
            {
              <PagesNavigator
                canvasRefs={pdfDocuments?.[0]?.pages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            }
          </PdfNavigationSection>
          {/* Middle Section */}
          <LeftSection>
            <Box sx={{ maxWidth: "100%", margin: "0 auto" }} ref={containerRef}>
              <ToolPanelContainer>
                <ToolPanel
                  currentTool={currentTool}
                  setCurrentTool={setCurrentTool}
                  stampType={stampType}
                  setStampType={setStampType}
                  textInput={textInput}
                  setTextInput={setTextInput}
                  currentColor={currentColor}
                  setCurrentColor={setCurrentColor}
                  lineWidth={lineWidth}
                  setLineWidth={setLineWidth}
                  fontSize={fontSize}
                  setFontSize={setFontSize}
                  undo={handleUndo}
                  redo={handleRedo}
                  currentStep={currentStep}
                  historyLength={history.length}
                  isRegionCapture={isRegionCapture}
                  setIsRegionCapture={setIsRegionCapture}
                />
              </ToolPanelContainer>

              {loading && (
                <LoadingOverlay>
                  <CircularProgress size={32} />
                  <Typography color="text.secondary">
                    Processing PDF...
                  </Typography>
                </LoadingOverlay>
              )}
              {/* Display PDF Pages */}
              <PDFContainer ref={containerRef} sx={{}}>
                {canvasRefs.map((canvasRef, index) => (
                  <PdfPage
                    pdfDocuments={pdfDocuments}
                    key={index}
                    index={index}
                    canvasRef={canvasRef}
                    isRegionCapture={isRegionCapture}
                    currentTool={currentTool}
                    startRegionSelection={startRegionSelection}
                    updateRegionSelection={updateRegionSelection}
                    endRegionSelection={endRegionSelection}
                    startDrawing={startDrawing}
                    draw={draw}
                    stopDrawing={stopDrawing}
                    isSelectingRegion={isSelectingRegion}
                    selectionStart={selectionStart}
                    selectionEnd={selectionEnd}
                    selectionRectRef={selectionRectRef}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                ))}
              </PDFContainer>
            </Box>
          </LeftSection>
          {/* Right section */}
          <RightSection>
            <ContentAreaMain>
              {/* <MainSection> */}
              <RightPanel
                capturedRegions={capturedRegions}
                handleQuestionClick={handleQuestionClick}
                handleAccordionChange={handleAccordionChange}
                activeQuestion={activeQuestion}
                setCapturedRegions={setCapturedRegions}
                activeSection={activeSection}
                isRegionCapture={isRegionCapture}
                setIsRegionCapture={setIsRegionCapture}
                setCurrentTool={setCurrentTool}
              />
            </ContentAreaMain>

            {/* {isDownloading && (
              <ProgressIndicator>
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Typography variant="body2">
                    {Math.round(downloadProgress)}%
                  </Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={downloadProgress}
                  sx={{
                    height: 4,
                    borderRadius: 2,
                    bgcolor: "grey.200",
                    "& .MuiLinearProgress-bar": {
                      bgcolor: "success.main",
                    },
                  }}
                />
              </ProgressIndicator>
            )} */}

            <ImagePreviewModal
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />

            <DeleteConfirmationModal
              showDeleteConfirm={showDeleteConfirm}
              setShowDeleteConfirm={setShowDeleteConfirm}
              setRegionToDelete={setRegionToDelete}
              handleDeleteConfirm={handleDeleteConfirm}
            />
          </RightSection>
        </Box>
        <PDFfooter />
      </Box>
    </>
  );
};
