import { useEffect, useRef, useState } from "react";
import { darkTheme } from "../../../styles/psMuiTheme/colorCodes";

interface CanvasState {
  id: number;
  dataUrl: string;
  tool: string;
  timestamp: number;
}

interface UseCanvasDrawingProps {
  initialColor?: string;
  initialLineWidth?: number;
  initialFontSize?: number;
}

export const useCanvasDrawing = ({
  initialColor = `${darkTheme.colors.teacher.pdf.pdfheader.initialColor}`,
  initialLineWidth = 2,
  initialFontSize = 24,
}: UseCanvasDrawingProps = {}) => {
  // Drawing States
  const [currentTool, setCurrentTool] = useState<string>("none");
  const [isDrawing, setIsDrawing] = useState(false);
  const [currentColor, setCurrentColor] = useState(initialColor);
  const [lineWidth, setLineWidth] = useState(initialLineWidth);
  const [fontSize, setFontSize] = useState(initialFontSize);
  const [textInput, setTextInput] = useState("");
  const [stampType, setStampType] = useState("check");

  // History States
  const [history, setHistory] = useState<CanvasState[]>([]);
  const [currentStep, setCurrentStep] = useState(-1);

  // Refs
  const activeCanvas = useRef<HTMLCanvasElement | null>(null);
  const lastPos = useRef({ x: 0, y: 0 });
  const drawingContexts = useRef(new Map());

  // Initialize keyboard shortcuts
  useEffect(() => {
    const handleKeyboardShortcuts = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        e.preventDefault();
        if (e.shiftKey) {
          handleRedo();
        } else {
          handleUndo();
        }
      } else if ((e.ctrlKey || e.metaKey) && e.key === "y") {
        e.preventDefault();
        handleRedo();
      }
    };

    window.addEventListener("keydown", handleKeyboardShortcuts);
    return () => window.removeEventListener("keydown", handleKeyboardShortcuts);
  }, [currentStep, history]);

  // History Management Functions
  const saveToHistory = (canvas: HTMLCanvasElement, tool: string) => {
    const newState: CanvasState = {
      id: Date.now(),
      dataUrl: canvas.toDataURL(),
      tool,
      timestamp: Date.now(),
    };

    const newHistory = history.slice(0, currentStep + 1);
    if (newHistory.length >= 50) newHistory.shift();

    newHistory.push(newState);
    setHistory(newHistory);
    setCurrentStep(newHistory.length - 1);
  };

  const loadCanvasState = (canvas: HTMLCanvasElement, state: CanvasState) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const img = new Image();
    img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);
    };
    img.src = state.dataUrl;
  };

  // Canvas Drawing Functions
  const getCanvasPoint = (
    canvas: HTMLCanvasElement,
    clientX: number,
    clientY: number,
  ) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: (clientX - rect.left) * (canvas.width / rect.width),
      y: (clientY - rect.top) * (canvas.height / rect.height),
    };
  };

  const initializeDrawingContext = (ctx: CanvasRenderingContext2D) => {
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = currentColor;
    ctx.lineWidth = lineWidth;
  };

  const startDrawing = (e: React.MouseEvent, canvas: HTMLCanvasElement) => {
    if (currentTool === "none") return;

    activeCanvas.current = canvas;
    const point = getCanvasPoint(canvas, e.clientX, e.clientY);

    switch (currentTool) {
      case "scribble":
      case "eraser":
        setIsDrawing(true);
        const ctx = canvas.getContext("2d");
        if (ctx) {
          initializeDrawingContext(ctx);
          if (currentTool === "eraser") {
            ctx.strokeStyle = "#FFFFFF";
            ctx.lineWidth = lineWidth * 4;
          }
          ctx.beginPath();
          ctx.moveTo(point.x, point.y);
          lastPos.current = point;
          drawingContexts.current.set(canvas, ctx);
        }
        break;
      case "stamp":
        drawStamp(canvas, point.x, point.y);
        break;
      case "text":
        if (textInput.trim()) {
          addText(canvas, point.x, point.y);
        }
        break;
    }
  };

  const draw = (e: React.MouseEvent, canvas: HTMLCanvasElement) => {
    if (!isDrawing || (currentTool !== "scribble" && currentTool !== "eraser"))
      return;

    const ctx = drawingContexts.current.get(canvas);
    if (!ctx) return;

    const point = getCanvasPoint(canvas, e.clientX, e.clientY);
    ctx.beginPath();
    ctx.moveTo(lastPos.current.x, lastPos.current.y);

    const midPoint = {
      x: (lastPos.current.x + point.x) / 2,
      y: (lastPos.current.y + point.y) / 2,
    };

    ctx.quadraticCurveTo(
      lastPos.current.x,
      lastPos.current.y,
      midPoint.x,
      midPoint.y,
    );
    ctx.stroke();
    lastPos.current = point;
  };

  const stopDrawing = (canvas: HTMLCanvasElement) => {
    if (isDrawing) {
      const ctx = drawingContexts.current.get(canvas);
      if (ctx) {
        ctx.closePath();
        if (currentTool === "eraser") {
          ctx.globalCompositeOperation = "source-over";
        }
        drawingContexts.current.delete(canvas);
        saveToHistory(canvas, currentTool);
      }
    }
    setIsDrawing(false);
  };

  const drawStamp = (canvas: HTMLCanvasElement, x: number, y: number) => {
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.fillStyle = currentColor;
      ctx.font = `${fontSize}px Arial`;
      const symbol = stampType === "check" ? "✓" : "✗";
      ctx.fillText(symbol, x - fontSize / 2, y + fontSize / 2);
      saveToHistory(canvas, "stamp");
    }
  };

  const addText = (canvas: HTMLCanvasElement, x: number, y: number) => {
    const ctx = canvas.getContext("2d");
    if (ctx && textInput.trim()) {
      ctx.fillStyle = currentColor;
      ctx.font = `${fontSize}px Arial`;
      ctx.fillText(textInput, x, y);
      saveToHistory(canvas, "text");
    }
  };

  const handleUndo = () => {
    if (currentStep > 0 && activeCanvas.current) {
      const newStep = currentStep - 1;
      setCurrentStep(newStep);
      loadCanvasState(activeCanvas.current, history[newStep]);
    }
  };

  const handleRedo = () => {
    if (currentStep < history.length - 1 && activeCanvas.current) {
      const newStep = currentStep + 1;
      setCurrentStep(newStep);
      loadCanvasState(activeCanvas.current, history[newStep]);
    }
  };

  return {
    // States
    currentTool,
    isDrawing,
    currentColor,
    lineWidth,
    fontSize,
    textInput,
    stampType,
    history,
    currentStep,

    // Setters
    setCurrentTool,
    setCurrentColor,
    setLineWidth,
    setFontSize,
    setTextInput,
    setStampType,
    setCurrentStep,
    setHistory,

    // Drawing Functions
    startDrawing,
    draw,
    stopDrawing,
    handleUndo,
    handleRedo,

    // Canvas State
    activeCanvas: activeCanvas.current,
  };
};
