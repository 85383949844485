import { Card, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";

interface CatCardsType {
  icon: string;
  text: string;
  clickHander?: (val: string) => void;
  selected: boolean;
}

const CatCards: FunctionComponent<CatCardsType> = (props: CatCardsType) => {
  const handlerClickOnCard = () => {
    if (props.clickHander && typeof props.clickHander === "function") {
      props.clickHander(props.text);
    }
  };
  return (
    <Card
      sx={[
        {
          width: 140,
          height: 90,
          alignItems: "center",
          textAlign: "center",
          borderRadius: 4,
          // border: "1px solid",
          backgroundColor: darkTheme.colors.surface.six,
        },
        props.selected
          ? {
              border: "1px solid",
              borderColor: darkTheme.colors.orange,
              background: `${darkTheme.colors.orange}20`,
            }
          : {},
      ]}
      className="hoverOrange"
      onClick={handlerClickOnCard}
    >
      <img
        src={props.icon}
        style={{
          width: "2.25rem",
          height: "2.25rem",
          marginTop: "0.75rem",
        }}
      />
      <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
        {props.text}
      </Typography>
    </Card>
  );
};

export { CatCards };
