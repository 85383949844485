//dummy data for ICSE board json data

const questionWisePaperPatternList = [
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q1",
    questionNumber: "01",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q2",
    questionNumber: "02",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q3",
    questionNumber: "03",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q4",
    questionNumber: "04",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q5",
    questionNumber: "05",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q6",
    questionNumber: "06",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q7",
    questionNumber: "07",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q8",
    questionNumber: "08",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q9",
    questionNumber: "09",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q10",
    questionNumber: "10",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q11",
    questionNumber: "11",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q12",
    questionNumber: "12",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q13",
    questionNumber: "13",
    questionType: "MCQ",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q14",
    questionNumber: "14",
    questionType: "ASSERTION",
  },
  {
    level1: "A",
    level2: "Q1",
    level3: "Sub Q15",
    questionNumber: "15",
    questionType: "ASSERTION",
  },
  {
    level1: "A",
    level2: "Q2",
    level3: "Sub Q1",
    questionNumber: "16",
    questionType: "MQ",
  },
  {
    level1: "A",
    level2: "Q2",
    level3: "Sub Q2",
    questionNumber: "17",
    questionType: "MQ",
  },
  {
    level1: "A",
    level2: "Q2",
    level3: "Sub Q3",
    questionNumber: "18",
    questionType: "MQ",
  },
  {
    level1: "A",
    level2: "Q3",
    level3: "Sub Q1",
    questionNumber: "19",
    questionType: "MQ",
  },
  {
    level1: "A",
    level2: "Q3",
    level3: "Sub Q2",
    questionNumber: "20",
    questionType: "MQ",
  },
  {
    level1: "A",
    level2: "Q3",
    level3: "Sub Q3",
    questionNumber: "21",
    questionType: "LQ",
  },
  {
    level1: "B",
    level2: "Q4",
    level3: "Sub Q1",
    questionNumber: "22",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q4",
    level3: "Sub Q2",
    questionNumber: "23",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q4",
    level3: "Sub Q3",
    questionNumber: "24",
    questionType: "MQ",
  },
  {
    level1: "B",
    level2: "Q5",
    level3: "Sub Q1",
    questionNumber: "25",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q5",
    level3: "Sub Q2",
    questionNumber: "26",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q5",
    level3: "Sub Q3",
    questionNumber: "27",
    questionType: "MQ",
  },
  {
    level1: "B",
    level2: "Q6",
    level3: "Sub Q1",
    questionNumber: "28",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q6",
    level3: "Sub Q2",
    questionNumber: "29",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q6",
    level3: "Sub Q3",
    questionNumber: "30",
    questionType: "MQ",
  },
  {
    level1: "B",
    level2: "Q7",
    level3: "Sub Q1",
    questionNumber: "31",
    questionType: "LQ",
  },
  {
    level1: "B",
    level2: "Q7",
    level3: "Sub Q2",
    questionNumber: "32",
    questionType: "LQ",
  },
  {
    level1: "B",
    level2: "Q8",
    level3: "Sub Q1",
    questionNumber: "33",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q8",
    level3: "Sub Q2",
    questionNumber: "34",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q8",
    level3: "Sub Q3",
    questionNumber: "35",
    questionType: "MQ",
  },
  {
    level1: "B",
    level2: "Q9",
    level3: "Sub Q1",
    questionNumber: "36",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q9",
    level3: "Sub Q2",
    questionNumber: "37",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q9",
    level3: "Sub Q3",
    questionNumber: "38",
    questionType: "MQ",
  },
  {
    level1: "B",
    level2: "Q10",
    level3: "Sub Q1",
    questionNumber: "39",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q10",
    level3: "Sub Q2",
    questionNumber: "40",
    questionType: "SQ",
  },
  {
    level1: "B",
    level2: "Q10",
    level3: "Sub Q3",
    questionNumber: "41",
    questionType: "MQ",
  },
];

export default questionWisePaperPatternList;
