import { Box, Card, Fade, Stack, Typography } from "@mui/material";
import jsPDF from "jspdf";
import moment from "moment";
import { FunctionComponent, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PsButton } from "../../../../../lib/psElements/psElements";
import { useStudentNaviagation } from "../../../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../../../services/storage/store";
import { setUploadModalFlag } from "../../../../../services/storage/student/test";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import IconCalendar from "../../../../assets/svg/student/calendar.svg";
import IconCopy from "../../../../assets/svg/student/copy.svg";
import IconLength from "../../../../assets/svg/student/length.svg";
import { GoBackArrowIos } from "../../../../common/GoBackButton/GoBackButton";

export const ViewTestPaper: FunctionComponent = () => {
  const { studentTest } = useSelector((state: RootState) => state);
  const { testPaper, testPaperLink } = studentTest;
  const studentNavigate = useStudentNaviagation();
  const [testPaperURI, setTestPaperURI] = useState<any>("");
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const doc = new jsPDF();

  const pdfLoadCallBack = (data: string) => {
    const URI = doc.output("bloburl");
    console.log("uri", URI);
    setTestPaperURI(data);
    window.open(URI, "_blank");
    return data;
  };

  useMemo(() => {
    doc.loadFile(testPaperLink, false, pdfLoadCallBack);
  }, [testPaperLink]);

  const handlerSubmitAnswerSheet = () => {
    dispatch(setUploadModalFlag(true));
  };

  return (
    <>
      <Fade in={true}>
        <Box
          sx={{
            mt: 4,
            ml: 4,
            mr: 3,
            mb: 5,
            width: 1184,
          }}
        >
          <GoBackArrowIos
            handlerBackButton={studentNavigate.navigateToCreateNewTestPage}
          />
          <Typography variant="h2" sx={{ width: 680, mb: 1.5 }}>
            Test paper-{testPaper.testName}
          </Typography>
          <Box
            sx={{
              direction: "row",
              display: "flex",
              gap: 3,
            }}
          >
            <Card
              sx={[
                {
                  width: 304,
                  height: 49,
                  alignItems: "left",
                  textAlign: "left",
                  backgroundColor: darkTheme.colors.surface.one,
                },
              ]}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 1.5, mb: 1.5, ml: 2, mr: 2 }}
              >
                <img
                  src={IconCalendar}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    // marginTop: "0.75rem",
                  }}
                />
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Generated on: {moment().format("DD MMM YYYY")}
                </Typography>
              </Stack>
            </Card>

            <Card
              sx={[
                {
                  width: 304,
                  height: 49,
                  alignItems: "left",
                  textAlign: "left",
                  backgroundColor: darkTheme.colors.surface.one,
                },
                {},
              ]}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 1.5, mb: 1.5, ml: 2, mr: 2 }}
              >
                <img
                  src={IconLength}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                />
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Length: {testPaper.length}
                </Typography>
              </Stack>
            </Card>
          </Box>

          <Box
            sx={{
              direction: "row",
              display: "flex",
              gap: 3,
              mt: 1.5,
            }}
          >
            <Card
              sx={[
                {
                  width: "100%",
                  height: 49,
                  alignItems: "left",
                  textAlign: "left",
                  backgroundColor: darkTheme.colors.surface.one,
                },
                {},
              ]}
            >
              <Stack
                direction="row"
                spacing={1}
                sx={{ mt: 1.5, mb: 1.5, ml: 2 }}
              >
                <img
                  src={IconCopy}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    // marginTop: "0.75rem",
                  }}
                />
                <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
                  Test text
                </Typography>
              </Stack>
            </Card>
          </Box>

          <Box sx={{ mt: 4 }}>
            <object
              data={testPaperLink}
              type="application/pdf"
              width="100%"
              height={677}
            >
              <div>No PDF viewer available</div>
            </object>
          </Box>

          <Box
            sx={{
              backgroundColor: darkTheme.colors.surface.one,
              mt: 5,
              p: 2.25,
              pr: 3,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: "flex-end" }}
            >
              <PsButton.Primary
                variant="contained"
                onClick={handlerSubmitAnswerSheet}
              >
                Submit Now
              </PsButton.Primary>
              <PsButton.Primary
                variant="contained"
                onClick={studentNavigate.navigateToDashboard}
              >
                Submit Later
              </PsButton.Primary>
            </Stack>
          </Box>
        </Box>
      </Fade>
    </>
  );
};

export default ViewTestPaper;
