import { CardContent, Grid2, Grow, Stack, Typography } from "@mui/material";
import { uniqueId } from "lodash";
import moment from "moment";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BoardCard,
  GradeChip,
  PsButton,
  StepBox,
} from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/student/studentProfileAPIMethods";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const ExaminationDetails: FunctionComponent = () => {
  const studentNaviage = useStudentNaviagation();
  const { getExamDateList, updateProfileData } = useStudentProfileAPICalls();
  const { listOfExamDates, profile } = useSelector(
    (state: RootState) => state.studentProfile,
  );
  const { examDate, board } = profile;

  const dispatch = useDispatch();

  useEffect(() => {
    getExamDateList(board);
  }, []);

  const handlerDateCardClick = (examDate: string) => {
    dispatch(personalDetail({ ...profile, examDate }));
  };

  const isDateCardSelected = (_examDate: string) => {
    if (String(examDate).toLowerCase() === String(_examDate).toLowerCase()) {
      return true;
    }
    return false;
  };

  const monthString = (monthName: string) => {
    const currentMonth = moment().month();
    const displayMonth = moment().month(monthName).month();
    if (currentMonth <= displayMonth)
      return moment().month(monthName).format("MMMM YY");
    else return moment().add(1, "year").month(monthName).format("MMMM YY");
  };

  return (
    <div className="schoolDetails">
      <StepsBar currentStep={5} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={studentNaviage.navigateToGradeDetails}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          When are you going to take your exam?
        </Typography>
        <Grow in={listOfExamDates.length > 0}>
          <Grid2
            container
            spacing={{ xs: 2, md: 2.5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ m: 3, mb: 21.75 }}
          >
            {listOfExamDates.map((item: string) => {
              return (
                <Grid2
                  size={{ md: 3 }}
                  sx={{ mt: 1.5 }}
                  key={uniqueId("exam-list-")}
                >
                  <BoardCard
                    variant="outlined"
                    style={
                      isDateCardSelected(item)
                        ? { border: "1px solid #FD9B18" }
                        : {}
                    }
                    onClick={() => handlerDateCardClick(item)}
                  >
                    <CardContent
                      sx={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <GradeChip label={""} sx={{ mb: 0.5 }} />
                      <Typography align="center">
                        {monthString(item)}
                      </Typography>
                    </CardContent>
                  </BoardCard>
                </Grid2>
              );
            })}
          </Grid2>
        </Grow>
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined
          variant="outlined"
          sx={{ width: 120 }}
          onClick={studentNaviage.navigateToDashboard}
        >
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={() => {
            updateProfileData();
          }}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default ExaminationDetails;
