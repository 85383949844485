import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useAuthorizationHooks } from "../../../../services/hooks/authorizationHooks";
import { useStudentNaviagation } from "../../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../../services/storage/store";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";
import { firstLetterCapital } from "../../../../utils";
import logo from "../../../assets/svg/logo.svg";
import IconLogout from "../../../assets/svg/menu/logout.svg";
import { SMListItemButton, SMListItemIcon } from "./customItems";
import "./sideMenu.scss";

interface logoutItemType {
  open: boolean;
}
const LogoutItem: FunctionComponent<logoutItemType> = (
  props: logoutItemType,
) => {
  const studentNaviage = useStudentNaviagation();
  const { logoutHandler } = useAuthorizationHooks();
  const handlerLogoutButton = () => {
    logoutHandler();
  };
  const { open } = props;
  return (
    <Tooltip title="Logout">
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <SMListItemButton
            onClick={handlerLogoutButton}
            sx={[
              open
                ? {
                    justifyContent: "initial",
                  }
                : {
                    justifyContent: "center",
                  },
            ]}
          >
            <SMListItemIcon
              sx={[
                open
                  ? {
                      mr: 3,
                    }
                  : {
                      mr: "auto",
                    },
              ]}
            >
              <img
                src={IconLogout}
                className={`${!open ? "logout avatar24" : ""}`}
              />
            </SMListItemIcon>
            {open && (
              <ListItemText
                primary="logout"
                sx={{
                  color: darkTheme.colors.menu.logout,
                }}
              />
            )}
          </SMListItemButton>
        </ListItem>
      </List>
    </Tooltip>
  );
};

interface userAvatarPropType {
  open: boolean;
}

const UserAvatar: FunctionComponent<userAvatarPropType> = (
  props: userAvatarPropType,
) => {
  const studentNaviage = useStudentNaviagation();
  const {
    profile: { photoUrl, firstName, lastName },
  } = useSelector((state: RootState) => state.studentProfile);
  const handlerAvatarClick = () => {
    studentNaviage.navigateToProfileSteps();
  };
  const { open } = props;

  const userName = firstLetterCapital(firstName + " " + lastName);
  const imageURL = photoUrl || "";
  return (
    <Tooltip title={userName}>
      <List>
        <ListItem disablePadding>
          <SMListItemButton
            onClick={handlerAvatarClick}
            sx={[
              open
                ? {
                    justifyContent: "initial",
                  }
                : {
                    justifyContent: "center",
                  },
            ]}
          >
            <SMListItemIcon
              sx={[
                open
                  ? {
                      mr: 3,
                      ml: 5,
                    }
                  : {
                      mr: "auto",
                    },
              ]}
            >
              <Stack direction={"column"} sx={{ alignItems: "center" }}>
                <Avatar
                  alt={userName}
                  src={imageURL}
                  className={`avatar ${!open ? "avatar24" : ""}`}
                />
                {open && <ListItemText primary={userName} />}
              </Stack>
            </SMListItemIcon>
          </SMListItemButton>
        </ListItem>
      </List>
    </Tooltip>
  );
};

interface psLogoPropType {
  open: boolean;
}
const PsLogoItem: FunctionComponent<psLogoPropType> = (
  props: psLogoPropType,
) => {
  const studentNaviage = useStudentNaviagation();
  const handlerLogoClick = () => {
    studentNaviage.navigateToDashboard();
  };
  const { open } = props;
  return (
    <img
      className={`logo cursorPointer ${!open ? "logo20" : ""}`}
      src={logo}
      alt="Pariksathi logo"
      onClick={handlerLogoClick}
    />
  );
};

export { LogoutItem, PsLogoItem, UserAvatar };
