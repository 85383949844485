// used for passoword encryption
import regularExpressions from "../constants/regExpressions";
import validationMessages from "../constants/validationMessages";

const API_PORT = "";

let getAPIURL = () => {
  let returnUrl = "http://ec2-3-80-240-173.compute-1.amazonaws.com:8085";
  console.log("window.location.hostname", window.location.hostname);
  switch (window.location.hostname) {
    case "3.80.240.173":
    case "dev.pariksaathi.com":
    case "pariksaathi.com":
      returnUrl = "https://dev.pariksaathi.com:8081";
      break;
    case "127.0.0.1":
    case "localhost":
      // returnUrl = "http://ec2-3-80-240-173.compute-1.amazonaws.com";
      returnUrl = "http://dev.pariksaathi.com:8085";
      break;
    default:
      // returnUrl = "http://ec2-3-80-240-173.compute-1.amazonaws.com";
      returnUrl = "http://dev.pariksaathi.com:8085";
      // returnUrl = "http://ec2-3-80-240-173.compute-1.amazonaws.com";
      break;
  }
  return returnUrl;
};

const psConfig = {
  IV_LENGTH: 16,
  ENCRYPTION_KEY: "sd5b75nb7577#^%$%*&G#CGF*&%@#%*&",
  CRYPTER_KEY:
    "0xffffffff,0xffffffff,0xffffffff,0xffffffff,0xffffffff,0xfffffff8",
  regularExpressions,
  validationMessages,
  azureStorageAccount: "spikeviewmediastorage",
  azureContainer: "spikeview-media",
  profileFolder: "profile",
  API_URL_JAVA: getAPIURL() + API_PORT,
};

export default psConfig;
