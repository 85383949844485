import { createSlice } from "@reduxjs/toolkit";

export interface testDataType {
  testName: string;
  subject: string;
  difficulty: string;
  length: string;
  includePastBoardPapers: string;
  focusOnPreviousMistakes: boolean;
  viewSelected: boolean;
  selectedChapters: ChapterItemType | never[];
}

interface ChapterItemType {
  value: string;
  label: string;
  selected: boolean;
}

export interface TaskListType {
  createdDate: string;
  grade: string;
  subject: string;
  testId: number;
  selected?: boolean;
  chapters?: string;
}

const initialTestData: testDataType = {
  testName: "",
  subject: "",
  difficulty: "",
  length: "",
  focusOnPreviousMistakes: false,
  includePastBoardPapers: "",
  viewSelected: false,
  selectedChapters: [],
};

const initialTaskList: TaskListType[] | null | undefined | never = [];

const initialList: any = [];
const test = createSlice({
  name: "studenttest",
  initialState: {
    testPaper: initialTestData,
    subjectList: initialList,
    chaptersList: initialList,
    testPaperLink: "",
    taskList: initialTaskList,
    selectedTestIdToUploadAnswerSheet: "",
    openUploadModal: false,
  },
  reducers: {
    setDataToGenerateTestPaper(state, action: { payload: object }) {
      state.testPaper = { ...state.testPaper, ...action.payload };
    },
    setTestSujectList(state, action) {
      state.subjectList = action.payload;
    },
    setTestChaptersList(state, action) {
      state.chaptersList = action.payload;
    },
    setTestPaperLink(state, action) {
      state.testPaperLink = action.payload;
    },
    setTaskList(state, action) {
      state.taskList = action.payload;
    },
    setSelectedTestIdToUploadAnswerSheet(state, action) {
      state.selectedTestIdToUploadAnswerSheet = action.payload;
    },
    setUploadModalFlag(state, action) {
      state.openUploadModal = action.payload;
    },
  },
});

export const {
  setDataToGenerateTestPaper,
  setTestSujectList,
  setTestChaptersList,
  setTestPaperLink,
  setTaskList,
  setSelectedTestIdToUploadAnswerSheet,
  setUploadModalFlag,
} = test.actions;

export default test.reducer;
