import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import { FieldArray, FormikProvider } from "formik";
import React from "react";
import { PsInput } from "../../../../lib/psElements/psElements";
import InputFileUpload from "../../../common/buttons/Upload";
interface Props {
  formik: any;
  handleSubmit: (region: any) => void;
  handlerInputChange: (region: any) => void;
  handleFileUploadChange: (file: any, index: any) => void;
  Subject: any;
  Getcertificate: any;
}

export const EducationDetails: React.FC<Props> = ({
  formik,
  handlerInputChange,
  handleFileUploadChange,
  Getcertificate,
  Subject,
}) => {
  const years = Array.from(
    { length: new Date().getFullYear() - 1999 },
    (_, i) => 2000 + i,
  );

  return (
    <div style={{ margin: "0px 0px 10px 0px " }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ fontSize: "20px" }}
        >
          Education <InfoIcon sx={{ mx: "5px" }} />
        </AccordionSummary>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <FieldArray name="educations">
              {({ push, remove }) => (
                <>
                  {(formik.values.educations || []).map(
                    (education: any, index: number) => (
                      <Grid2
                        key={index}
                        sx={{
                          px: 10,
                          display: "grid",
                          gridTemplateColumns: {
                            lg: "repeat(4, 1fr)",
                            md: "repeat(3, 1fr)",
                          },
                          gap: "10px",
                          mb: "20px",
                        }}
                      >
                        <Grid2>
                          <FormControl fullWidth>
                            <PsInput
                              label={`Degree ${index + 1}`}
                              name={`educations[${index}].Degree`}
                              value={formik.values.educations[index].Degree}
                              onChange={handlerInputChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.educations?.[index]?.Degree &&
                                Boolean(
                                  formik.errors.educations?.[index]?.Degree,
                                )
                              }
                            />
                            <FormHelperText
                              sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                            >
                              {formik.touched.educations?.[index]?.Degree &&
                                formik.errors.educations?.[index]?.Degree}
                            </FormHelperText>
                          </FormControl>
                        </Grid2>

                        <Grid2>
                          <FormControl fullWidth>
                            <InputLabel
                              id={`subject-select-label-${index + 1}`}
                            >
                              Subject {index + 1}
                            </InputLabel>
                            <Select
                              label={`Subject ${index + 1}`}
                              name={`educations[${index}].Subject`}
                              value={formik.values.educations[index].Subject}
                              onChange={handlerInputChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.educations?.[index]?.Subject &&
                                Boolean(
                                  formik.errors.educations?.[index]?.Subject,
                                )
                              }
                            >
                              {Array.isArray(Subject) &&
                                Subject.map((subject, index) => (
                                  <MenuItem key={index} value={subject}>
                                    {subject}
                                  </MenuItem>
                                ))}
                            </Select>

                            <FormHelperText
                              sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                            >
                              {formik.touched.educations?.[index]?.Subject &&
                                formik.errors.educations?.[index]?.Subject}
                            </FormHelperText>
                          </FormControl>
                        </Grid2>
                        <Grid2>
                          <FormControl
                            fullWidth
                            error={
                              formik.touched.educations?.[index]?.Year &&
                              Boolean(formik.errors.educations?.[index]?.Year)
                            }
                          >
                            <InputLabel id={`year-select-label-${index}`}>
                              Year
                            </InputLabel>
                            <Select
                              labelId={`year-select-label-${index}`}
                              label={`Year ${index}`}
                              name={`educations[${index}].Year`}
                              value={formik.values.educations[index].Year}
                              onChange={handlerInputChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.educations?.[index]?.Year &&
                                Boolean(formik.errors.educations?.[index]?.Year)
                              }
                            >
                              {years.map((year) => (
                                <MenuItem key={year} value={year}>
                                  {year}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                            >
                              {formik.touched.educations?.[index]?.Year &&
                                formik.errors.educations?.[index]?.Year}
                            </FormHelperText>
                          </FormControl>
                        </Grid2>

                        <Grid2>
                          <FormControl fullWidth>
                            <InputFileUpload
                              label="Upload Document"
                              onChange={(e) => handleFileUploadChange(e, index)}
                              name={education.certificateBlobURL}
                              accept=".pdf,.doc,.docx"
                            />
                          </FormControl>
                        </Grid2>
                        {/* <Grid2></Grid2>
                        <Grid2></Grid2> */}
                        {index > 0 && (
                          <Grid2>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => remove(index)}
                            >
                              Remove Education {index + 1}
                            </Button>
                          </Grid2>
                        )}
                      </Grid2>
                    ),
                  )}

                  <Grid2 sx={{ px: 10, margin: "0px 0px 10px 0px" }}>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() =>
                        push({
                          Degree: "",
                          Subject: "",
                          Year: "",
                        })
                      }
                    >
                      + Add another Education
                    </Button>
                  </Grid2>
                </>
              )}
            </FieldArray>
          </form>
        </FormikProvider>
      </Accordion>
    </div>
  );
};
