// import { toast } from "react-toastify";
import * as Cookie from "es-cookie";
import moment from "moment";
// import crypto from "crypto";
// import Cryptr from "cryptr";
import { toast } from "react-toastify";
// import { PsEncryption } from "./encription/encryptDecrypt";
// import Config from '../../common/core/config';
// import stripe  from 'stripe';
// import stripe from 'https://js.stripe.com/v3/';
// const { Buffer } = global;

// const pubKey = "12345678";
export const handleApiError = (errorMessage: string, autoClose: boolean) => {
  toast.error(errorMessage, {
    position: "top-right",
    hideProgressBar: true,
    autoClose: autoClose === false ? false : undefined,
  });
};
export const showErrorToast = (errorMessage: string, autoClose: boolean) => {
  handleApiError(errorMessage, autoClose);
};

export const showSuccessToast = (
  message: string,
  autoClose: boolean = true,
) => {
  toast.success(message, {
    position: "top-right",
    hideProgressBar: autoClose,
  });
};

export const showWarningToast = (
  message: string,
  autoClose: boolean = true,
) => {
  toast.warn(message, {
    position: "top-right",
    hideProgressBar: autoClose,
  });
};

export const setCookie = (key: string, value: any) => {
  Cookie.set(key, JSON.stringify(value));
};

export const getCookie = (key: string) => {
  return Cookie.get(key);
};

export const removeCookie = (key: string) => {
  Cookie.remove(key);
};

// const cryptr = new Cryptr(CONSTANTS.CRYPTER_KEY);
// let toastId = "";

//used to encryption of localstorage data
export const encryptedData = async (data: any) => {
  // return await PsEncryption.getObject().encryptData(data);
  // let enc = new TextEncoder();
  // let encodedData = enc.encode(data);
  // crypto.subtle.encrypt(
  //   {
  //     name: "RSA-OAEP",
  //   },
  //   pubKey,
  //   encodedData
  // );
  // return cryptr.encrypt(data);
  // return data;
};

//used to decrypt localstorage data
export const decryptedData = async (data: any) => {
  // return await PsEncryption.getObject().decryptData(data);
  // return cryptr.decrypt(data);
  // return data;
};

// used to set localstorage item
export const setLocalStorage = (key: string, value: any) => {
  value = JSON.stringify(value);
  localStorage.setItem(key, value);
};

// used to get localstorage item
export const getLocalStorage = (key: string) => {
  let data: any = localStorage.getItem(key);
  if (data && data !== "undefined" && data !== "null") {
    data = JSON.parse(data);
    return data;
  }
  return null;
};

// used to remove localstorage item
export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

// used to clear localstorage
export const clearLocalStorage = () => {
  localStorage.clear();
};

// used to render validation message
export const renderMessage = (message: string) => {
  return <span className="error">{message}</span>;
};

// used for password encryptions`
// export const encrypt = (text: any) => {
//   let iv = crypto.randomBytes(CONSTANTS.IV_LENGTH);
//   let cipher = crypto.createCipheriv(
//     "aes-256-cbc",
//     Buffer.from(CONSTANTS.ENCRYPTION_KEY),
//     iv
//   );
//   let encrypted = cipher.update(text);
//   encrypted = Buffer.concat([encrypted, cipher.final()]);
//   return iv.toString("hex") + ":" + encrypted.toString("hex");
// };

// used to get apiurl for different servers
export const getAPIURL = () => {
  let returnUrl = "";
  switch (window.location.hostname) {
    case "104.42.51.157":
      returnUrl = "104.42.51.157";
      break;

    case "103.76.253.131":
      returnUrl = "103.76.253.131";
      break;

    default:
      returnUrl = "103.76.253.131";
      break;
  }
  return returnUrl;
};

// used to convert timestamp to date
export const timeStampToDate = (timestamp: any) => {
  timestamp = timestamp.toString();
  timestamp = timestamp.slice(0, -3);
  timestamp = parseInt(timestamp);
  let momentDate = moment.unix(timestamp);
  return momentDate;
};
