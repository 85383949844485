import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  Input,
  LinearProgress,
  linearProgressClasses,
  OutlinedInput,
  styled,
  Switch,
  TextField,
} from "@mui/material";
// import { backgroundColor } from "../../styles/colorCode";
import { darkTheme, PsTheme } from "../../styles/psMuiTheme/colorCodes";

interface StyledTabProps {
  label: string;
}

export const PsInput = styled(TextField)(({ theme }) => ({
  borderColor: PsTheme.colors.surface.four,
  // borderStyle: "solid",
  // borderWidth: "1px",
  // borderRadius: "0.5rem",
  label: {
    color: PsTheme.colors.font.dark,
  },
  input: {
    color: PsTheme.colors.font.dark,
  },
  width: "100%",
}));

export const PsDatePicker = styled(OutlinedInput)(({ theme }) => ({
  // borderColor: PsTheme.colors.surface.four,
  // label: {
  //   color: PsTheme.colors.font.dark,
  // },
  // input: {
  //   color: PsTheme.colors.font.dark,
  // },
}));

export const PsBox = styled(Box)(({ theme }) => ({
  backgroundColor: darkTheme.colors.surface.five,
  color: darkTheme.colors.font.light,
  margin: "1px",
}));

export const PsMainContainer = styled(Container)((theme) => ({
  // backgroundColor: darkTheme.colors.surface.root,
  background: "red",
}));

export const BlackButton = styled(Button)(({ theme }) => ({
  backgroundColor: PsTheme.colors.surface.two,
  minHeight: "2.5rem",
}));

export const PsButton = {
  Primary: styled(Button)({
    backgroundColor: PsTheme.colors.button.primary,
    height: 48,
  }),
  Outlined: styled(Button)({
    height: 48,
    border: `1px solid ${PsTheme.colors.button.primary}`,
    borderColor: PsTheme.colors.button.primary,
    color: PsTheme.colors.button.primary,
    backgroundColor: "transparent",
    borderRadius: 4,
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 2.8,
  }),
  Custom: styled(Button)({
    backgroundColor: PsTheme.colors.button.primary,
    color: PsTheme.colors.font.light,
    borderColor: PsTheme.colors.button.primary,
    borderRadius: 4,
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 2.8,
    height: 48,
    "&:hover": {
      opacity: 0.9,
    },
  }),
  CustomTheme: styled(Button)({
    borderColor: `${darkTheme.colors.teacher.theme.purpule}`,
    color: `${darkTheme.colors.teacher.theme.purpule}`,
    height: 48,
    borderRadius: 4,
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 2.8,
  }),
  Custombg: styled(Button)({
    backgroundColor: `${darkTheme.colors.teacher.theme.custombg}`,
    height: 48,
    width: 30,
  }),
  Custompopup: styled(Button)({
    backgroundColor: `${darkTheme.colors.teacher.theme.Custompopup}`,
    height: 30,
  }),
  BgBlackTextWhite: styled(Button)({
    backgroundColor: darkTheme.colors.action.teritaryTwo,
    color: darkTheme.colors.font.light,
    borderRadius: 4,
    height: 48,
    //styleName: Button/Button 1;
    // font-family: Inter;
    fontSize: 16,
    fontWeight: 500,
    // lineHeight: "22.4px"99 ,
    // text-align: center;
    // text-underline-position: from-font;
    // text-decoration-skip-ink: none;
  }),
  CustomChartButton: styled(Button)({
    padding: "8px 16px",
    color: "white",
    background: `${darkTheme.colors.teacher.Dashboard.charts.tabactiveColor}`,
    border: `2px solid ${darkTheme.colors.teacher.Dashboard.charts.tabactiveColor} `,
    borderRadius: "5px",
    cursor: "pointer",
    textTransform: "capitalize",
  }),
};
export const TButton = {
  Primary: styled(Button)({
    backgroundColor: PsTheme.colors.action.primary,
    height: 48,
  }),
  Outlined: styled(Button)({
    height: 48,
  }),
  CustomTheme: styled(Button)({
    borderColor: `${darkTheme.colors.teacher.theme.purpule}`,
    color: `${darkTheme.colors.teacher.theme.purpule}`,
    height: 48,
  }),
  Custom: styled(Button)({
    backgroundColor: `${darkTheme.colors.teacher.theme.purpule}`,
    color: `${darkTheme.colors.teacher.theme.white}`,
    height: 48,
    "&:hover": {
      backgroundColor: `${darkTheme.colors.teacher.theme.purpule}`,
    },
  }),
};

export const OTPInput = styled(Input)(({ theme }) => ({
  borderColor: PsTheme.colors.surface.four,
  borderStyle: "solid",
  borderWidth: "1px",
  borderRadius: "0.5rem",
  label: {
    color: PsTheme.colors.font.dark,
  },
  input: {
    color: PsTheme.colors.font.dark,
    textAlign: "center",
  },
  width: "3.5rem",
  height: "3.5rem",
  marginRight: "1rem",
  fontFamily: "inter",
  fontSize: "3rem",
}));

export const StepBox = styled(Box)(({ theme }) => ({
  background: PsTheme.colors.surface.one,
  height: "33.625rem",
}));

export const PsCheckbox = styled(Checkbox)(({ theme }) => ({
  width: "10px",
  height: "10px",
}));

export const PsBackButtonLeftIos = styled(Button)(({ theme }) => ({
  color: PsTheme.colors.action.secondary,
  alignSelf: "flex-start",
  display: "flex",
}));

export const BoardCard = styled(Card)(({ theme }) => ({
  background: PsTheme.colors.surface.two,
  width: 207,
  height: 140,

  avatar: {
    width: 80,
    Height: 80,
  },
}));

export const GradeChip = styled(Chip)(({ theme }) => ({
  height: 90,
  width: 90,
  borderRadius: 45,
  fontSize: 40,
  color: PsTheme.colors.boardCard.gradeFontColor,
  fontWeight: 700,
  backgroundColor: PsTheme.colors.boardCard.avatar,
  // marginTop: 6,
}));

export const PsUploadButton = styled(Button)(({ theme }) => ({
  backgroundColor: PsTheme.colors.action.primary,
  color: `${darkTheme.colors.teacher.theme.white}`,
  textTransform: "none",
  borderRadius: "0.5rem",
  height: "48px",
  "&:hover": {
    backgroundColor: PsTheme.colors.action.primary,
  },
  "& input": {
    display: "none",
  },
}));

export const PsPDFButton = styled(Button)(({ theme }) => ({
  backgroundColor: PsTheme.colors.action.primary,
  color: `${darkTheme.colors.teacher.theme.white}`,
  textTransform: "none",
  borderRadius: "0.5rem",
  height: "48px",
  "&:hover": {
    backgroundColor: PsTheme.colors.action.primary,
  },
  "& input": {
    display: "none",
  },
}));

export const CardBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${darkTheme.colors.teacher.tasktable.tablebg}`,
  borderRadius: "10px",
  padding: "20px",
  marginBottom: "30px",
}));
export const PsSwitchButton = styled(Switch)(() => [
  {
    "&.MuiSwitch-root": {
      "& .MuiSwitch-switchBase": {
        "&.Mui-checked": {
          color: darkTheme.colors.orange,
        },
      },
      ".MuiSwitch-track": {
        "background-color": darkTheme.colors.orange,
      },
    },
  },
]);

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

// export const PsTabs = styled(Tabs)({
//   borderBottom: "1px solid #ffffff",
//   "& .MuiTabs-indicator": {
//     backgroundColor: darkTheme.colors.orange,
//     borderBottom: "4px solid " + darkTheme.colors.orange,
//   },
// });

// export const PsTab = styled((props: StyledTabProps) => (
//   <Tab disableRipple {...props} />
//   // <Tab disableripple {...props} />
// ))(({ theme }) => ({
//   textTransform: "none",
//   minWidth: 0,
//   [theme.breakpoints.up("sm")]: {
//     minWidth: 0,
//   },
//   fontWeight: theme.typography.fontWeightRegular,
//   marginRight: theme.spacing(2),
//   color: darkTheme.colors.font.light,
//   fontFamily: [
//     "Inter",
//     "-apple-system",
//     "BlinkMacSystemFont",
//     '"Segoe UI"',
//     "Roboto",
//     '"Helvetica Neue"',
//     "Arial",
//     "sans-serif",
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(","),
//   "&:hover": {
//     // color: "#40a9ff",
//     color: darkTheme.colors.orange,
//     opacity: 1,
//   },
//   "&.Mui-selected": {
//     color: darkTheme.colors.orange,
//     fontWeight: theme.typography.fontWeightMedium,
//   },
//   "&.Mui-focusVisible": {
//     // backgroundColor: "#d1eaff",
//     backgroundColor: darkTheme.colors.orange,
//   },
// }));
