import DescriptionIcon from "@mui/icons-material/Description";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Tooltip, Typography } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthorizationHooks } from "../../../../services/hooks/authorizationHooks";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
import { showHideSideMenu } from "../../../../services/storage/common";
import { RootState } from "../../../../services/storage/store";
import IconDrawerBack from "../../../assets/svg/menu/drawerback.svg";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";
import {
  SMListItemButton,
  SMListItemIcon,
} from "../../student/SideMenu/customItems";
import { PsLogoItem } from "../../student/SideMenu/MenuItems";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function Drawersection() {
  const theme = useTheme();
  const {
    common: { sideMenuShowHide },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const teachernavigation = useTeacherNavigation();
  const { logoutHandler } = useAuthorizationHooks();
  const navigate = useNavigate();
  const [active, setActive] = useState("/teacher/dashboard");
  const location = useLocation();
  const open: boolean = sideMenuShowHide;

  const handleDrawerOpen = () => {
    dispatch(showHideSideMenu(true));
  };

  const handleDrawerClose = () => {
    dispatch(showHideSideMenu(false));
  };

  // Custom styles for active menu item
  const activeStyle = {
    backgroundColor: `${darkTheme.colors.teacher.drawer.backgroundColor}`,
    borderRight: `4px solid ${darkTheme.colors.teacher.drawer.activestyle.active}`,
    color: `${darkTheme.colors.teacher.drawer.activestyle.deactive}`,
    "& .MuiListItemIcon-root": {
      color: `${darkTheme.colors.teacher.drawer.activestyle.deactive}`,
    },
  };

  const isActiveRoute = (path: any) => {
    return location.pathname === path;
  };

  const menuItems = [
    {
      title: "Summary",
      path: "/teacher/dashboard",
      icon: <DescriptionIcon />,
    },
    // Add more menu items as needed
  ];

  const fixedBottomStyle = {
    marginTop: "auto",
    backgroundColor: theme.palette.background.paper,
  };

  const handleNavigation = (path: string) => {
    if (path === "/teacher/profile") {
      setActive("");
    } else {
      setActive(path);
    }
    navigate(path);
  };

  const handleLogout = () => {
    logoutHandler();
  };
  const handleProfile = () => {
    teachernavigation.navigateToTeacherProfile();
  };

  return (
    <Box sx={{ display: "flex" }} className="studentSideMenu">
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={[
          {
            width: 65,
            display: "flex",
            alignSelf: "flex-start",
            left: 0,
            right: "auto",
          },
          open && { visibility: "hidden" },
        ]}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && { display: "none" },
            ]}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <img src={IconDrawerBack} />
          </IconButton>
          <PsLogoItem open={open} />
        </DrawerHeader>
        <Divider />

        <List>
          {menuItems.map((item) => (
            <Link
              to={item.path}
              key={item.title}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Tooltip title={item.title}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <SMListItemButton
                    sx={[
                      open
                        ? {
                            justifyContent: "initial",
                          }
                        : {
                            justifyContent: "center",
                          },
                      isActiveRoute(item.path) && activeStyle,
                    ]}
                  >
                    <SMListItemIcon
                      sx={[
                        open
                          ? {
                              mr: 3,
                            }
                          : {
                              mr: "auto",
                            },
                      ]}
                    >
                      {item.icon}
                    </SMListItemIcon>
                    <ListItemText
                      primary={item.title}
                      sx={[
                        open
                          ? {
                              opacity: 1,
                            }
                          : {
                              opacity: 0,
                            },
                      ]}
                    />
                  </SMListItemButton>
                </ListItem>
              </Tooltip>
            </Link>
          ))}
        </List>
        <Box sx={fixedBottomStyle}>
          <div onClick={handleProfile}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
                paddingBottom: 2,
              }}
            >
              <IconButton sx={{ pb: 0 }}>
                <Avatar alt="Teacher Avatar" />
              </IconButton>
              <Typography
                variant="h6"
                component="span"
                sx={{ ml: 0, fontWeight: "Normal", fontSize: 18 }}
              >
                {open ? "Indu Kumari" : "IK"}
              </Typography>
            </Box>
          </div>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
              cursor: "pointer",
              paddingBottom: 2,
            }}
            onClick={handleLogout}
          >
            <LogoutIcon sx={{ mr: 0, color: "red" }} />
            <Typography sx={{ color: "red" }}>
              {open ? "Logout" : ""}
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
