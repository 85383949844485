import { Box } from "@mui/material";
import { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import "./../../styles/index.scss";
// const theme = createTheme({
//   components: {
//     MuiContainer: {
//       styleOverrides: {
//         root: {
//           "&.MuiContainer-maxWidthXl": {
//             maxWidth: "1440px",
//           },
//         },
//       },
//     },
//   },
// });

export const PSContainer: React.FC = (): ReactElement => {
  return (
    <div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          transition: (theme) =>
            theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};
