export const message = {
  email: {
    require: "Please enter your email address",
    invalid: "Please enter a valid email address",
  },
  parentEmail: {
    required: "Please enter student's email address",
  },
  password: {
    require: "Please enter your password",
    newPassword: "Please enter new password",
    oldPassword: "Please enter current password",
    confirmNewPassword: "Please enter confirm new password",
    passwordPattern:
      "Password should contain one special character, one number, one lower and one upper case letter",
    same: "Mismatch new password and confirm new password",
    confirm: "Password and Confirm password do not match",
    foo_confirmation: "Password and Confirm password do not match",
  },
  firstName: {
    require: "Please enter first name",
    alphaOnly: "Please enter a valid first name",
  },
  lastName: {
    require: "Please enter last name",
    alphaOnly: "Please enter a valid last name",
  },
  schoolName: {
    require: "Please select school name",
  },
  companyName: {
    required: "Please enter company name",
    alphaOnly: "Please enter a valid company name",
  },
  address: {
    required: "Please enter address",
    alphaOnly: "Please enter a valid address",
  },
  fullName: {
    required: "Please enter name",
    alphaOnly: "Please enter a valid name",
  },
  mobile: {
    require: "Please enter mobile number",
    min10: "Contact number must be at least 10 digits",
    max10: "Contact number must be at most 10 digits",
    typeError: "Contact number must be a number",
    positive: "Contact number must be positive",
  },
  designation: {
    required: "Please enter designation",
    alphaOnly: "Please enter a valid designation",
  },
  OTP: {
    required: "Please enter OTP value",
  },
  acceptTermsCondition: {
    required: "Please accept terms and conditions",
  },
  birthdate: {
    require: "Birth date requires",
    min6YearOld: "Minimum 6 years old",
    max100YearOld: "Maximum 100 years old",
  },
  testName: {
    require: "Please enter test name",
    alphaNumbericUnderscore: "Special characters are not allow",
  },
  subject: {
    require: "Please select subject",
  },
  difficulty: {
    require: "Please select difficulty",
  },
  length: {
    require: "Please select length",
  },
  includePastBoardPapers: {
    require: "Please select the inclusion",
  },
  // focusOnPreviousMistakes: {
  //   require: 'Please select '
  // },

  day: { required: "Please select day" },
  month: { required: "Please select month" },
  year: { required: "Please select year" },
  city: { require: "Please enter city" },
  state: { require: "Please enter state" },
  country: { required: "Please enter country" },
  summary: { required: "Please enter summary" },
  instituteName: { required: "Please enter institute" },
  startDate: { required: "Please select date" },
  endDate: { required: "Please select date" },
  maxChar150: "Can not exceed 150 characters.",
  maxChar250: "Can not exceed 250 characters.",
  maxChar50: "Can not exceed 50 characters.",
  maxChar100: "Can not exceed 100 characters.",
  minChar8: "Minimum 8 characters require.",
  minChar2: "Minimum 2 characters require.",
  minChar4: "Minimum 4 characters require.",
};
