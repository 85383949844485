import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import IconEightyPercent from "../../../../assets/svg/student/eightyPercent.svg";
import IconHundradPercent from "../../../../assets/svg/student/hundredPercent.svg";
import IconThirtyPercent from "../../../../assets/svg/student/thirtyPercent.svg";
import IconTwentyPercent from "../../../../assets/svg/student/twentyPercent.svg";
import IconZeroPercent from "../../../../assets/svg/student/zeroPercent.svg";
import { CatCards } from "./CatCards";

const pastBoardInclusionList = [
  {
    icon: IconZeroPercent,
    text: "0%",
  },
  {
    icon: IconTwentyPercent,
    text: "10-30%",
  },
  {
    icon: IconThirtyPercent,
    text: "30-60%",
  },
  {
    icon: IconEightyPercent,
    text: "60-80%",
  },
  {
    icon: IconHundradPercent,
    text: "100%",
  },
];

interface PastBoardInclusionType {
  clickHander: (val: string) => void;
  formik: any;
}

const PastBoardInclusion: FunctionComponent<PastBoardInclusionType> = (
  props: PastBoardInclusionType,
) => {
  const { formik } = props;
  const handlerSetSelected = (val: string) => {
    return (
      String(formik.values.includePastBoardPapers).toLowerCase() ===
      String(val).toLowerCase()
    );
  };
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Inclusion of past board papers</Typography>
      <Stack direction="row" sx={{ mt: 2, gap: 4 }}>
        {pastBoardInclusionList.map((item) => (
          <CatCards
            icon={item.icon}
            text={item.text}
            clickHander={props.clickHander}
            selected={handlerSetSelected(item.text)}
          />
        ))}
      </Stack>
      {formik.errors.includePastBoardPapers && (
        <FormHelperText error>
          {formik.touched.includePastBoardPapers &&
          formik.errors.includePastBoardPapers
            ? formik.errors.includePastBoardPapers
            : ""}
        </FormHelperText>
      )}
    </Box>
  );
};

export { PastBoardInclusion };
