import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import MathJax from "react-mathjax";
import { PsButton, PsCheckbox } from "../../../../../lib/psElements/psElements";
// import { MathJax, MathJaxContext ,} from 'better-react-mathjax';
import { useFormik } from "formik";
import { TeacherAPIcalls } from "../../../../../services/api/teacher/ProfileAPI";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";

// Types remain the same
declare global {
  interface Window {
    MathJax: any;
  }
}

interface RightPanelProps {
  setCurrentTool: (tool: string) => void;
  capturedRegions: any;
  activeQuestion: any;
  activeSection: any;
  isRegionCapture: any;
  setIsRegionCapture: any;
  setCapturedRegions: (region: any) => void;
  handleQuestionClick: (region: any) => void;
  handleAccordionChange: (region: any) => void;
}

export const RightPanel: React.FC<RightPanelProps> = ({
  // capturedRegions,
  capturedRegions,
  setCapturedRegions,
  isRegionCapture,
  setIsRegionCapture,
  setCurrentTool,
}) => {
  const handleRegionDescriptionChange = (
    questionId: number,
    regionId: number,
    value: string,
  ) => {
    setCapturedRegions((prevRegions: any) =>
      prevRegions.map((section: any) => ({
        ...section,
        questions: section.questions.map((question: any) => {
          if (question.id === questionId) {
            return {
              ...question,
              regions: question.regions.map((region: any) =>
                region.id === regionId
                  ? { ...region, description: value }
                  : region,
              ),
            };
          }
          return question;
        }),
      })),
    );
  };
  const [open, setOpen] = useState<number | null>(null);
  // const [openSubqu, setOpenSubqu] = useState<number | null>(null);

  // const handleClickOpenSubqu = (id: number) => {
  //   setOpenSubqu((prevOpen) => (prevOpen === id ? null : id));
  // };
  const handleClickOpen = (id: number) => {
    setOpen((prevOpen) => (prevOpen === id ? null : id));
  };
  const tableData = ["1", "2", "3", "", "4", "5", "6", "7", "", ""];
  const TotalMarks = ["10", "10", "10", "", "0", "10", "0", "5", "", ""];
  const questionList: any[] = [
    {
      seqNo: 1,
      section: "A",
      questionNo: 1,
      subQuestionNo: "",
      questionType: "MCQ",
      totalMarks: 1,
      questionId: 12341233,
      questionText:
        "If $p_{1}$ and $p_{2}$ are two odd prime numbers such that $p_{1}>p_{2}$, then $p_{1}^{2}-p_{2}^{2}$ is\n(a) an even number\n(b) an odd number\n(c) an odd prime number\n(d) a prime number",
      questionTable: "",
      questionImage: "",
      answerText: "A",
      answerTable: "",
      answerImage: "",
    },
    {
      seqNo: 2,
      section: "B",
      questionNo: 2,
      subQuestionNo: "",
      questionType: "MCQ",
      totalMarks: 1,
      questionId: 12341234,
      questionText:
        "If the zeroes of the quadratic polynomial $x^{2}+(a+1) x+b$ are 2 and -3 , then\\\\\n(a) $a=-7, b=-1$\\\\\n(b) $a=5, b=-1$\\\\\n(c) $a=2, b=-6$\\\\\n(d) $a=0, b=-6$",
      questionTable: "",
      questionImage: "",
      answerText: "D",
      answerTable: "",
      answerImage: "",
    },
    {
      seqNo: 3,
      section: "C",
      questionNo: 3,
      subQuestionNo: "",
      questionType: "MCQ",
      totalMarks: 1,
      questionId: 12341236,
      questionText:
        "Two lines are given to be parallel. The equation of one of the lines is $4 x+3 y=14$, then the equation of the second line will be\\\\\n(a) $12 x+9 y=42$\\\\\n(b) $12 x+9 y=5$\\\\\n(c) $12 x+9 y=15$\\\\\n(d) $12 x+9 y=32$",
      questionTable: "",
      questionImage: "",
      answerText: "B",
      answerTable: "",
      answerImage: "",
    },
    {
      seqNo: 4,
      section: "C",
      questionNo: 4,
      subQuestionNo: "",
      questionType: "MCQ",
      totalMarks: 1,
      questionId: 1234123,
      questionText:
        "Two lines are given to be parallel. The equation of one of the lines is $4 x+3 y=14$, then the equation of the second line will be\\\\\n(a) $12 x+9 y=42$\\\\\n(b) $12 x+9 y=5$\\\\\n(c) $12 x+9 y=15$\\\\\n(d) $12 x+9 y=32$",
      questionTable: "",
      questionImage: "",
      answerText: "C",
      answerTable: "",
      answerImage: "",
    },
    {
      seqNo: 5,
      section: "D",
      questionNo: 5,
      subQuestionNo: "",
      questionType: "MCQ",
      totalMarks: 1,
      questionId: 12343,
      questionText: "HEllo",
      questionTable: "",
      questionImage: "",
      answerText: "C",
      answerTable: "",
      answerImage: "",
    },
    {
      seqNo: 6,
      section: "E",
      questionNo: 6,
      subQuestionNo: "",
      questionType: "SQ",
      totalMarks: 2,
      questionId: 121234,
      questionText:
        "If the perimeter of a circle is equal to that of a square, then the ratio of their areas (circle to square).\\",
      questionTable: "",
      questionImage: "",
      answerText: "( a^2 + b^2 = c^2 )",

      answerImage: "",
      mistakeInfo: [
        {
          mistakeType: "Computation",
          subMistakeType: "Addition",
        },
        {
          mistakeType: "Computation",
          subMistakeType: "Division",
        },
        {
          mistakeType: "Computation",
          subMistakeType: "Subtraction",
        },
      ],
    },
  ];

  // const inlineMath = "( a^2 + b^2 = c^2 )";
  // const blockMath = `[
  //   \\int_0^1 x^2 dx = \\frac{1}{3}
  // ]`;

  const [activeSection, setActiveSection] = useState("");
  const [activeQuestion, setActiveQuestion] = useState(null);

  const [openQuestionIds, setOpenQuestionIds] = useState<number[]>([]);
  // const { QnPaperDetails } = useSelector(
  //   (state: RootState) => state.TeacherprofileData,
  // );

  const { fetchQnpaperDetails } = TeacherAPIcalls();
  useEffect(() => {
    fetchQnpaperDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sections = questionList.reduce((acc, question) => {
    if (!acc[question.section]) {
      acc[question.section] = [];
    }
    acc[question.section].push(question);
    return acc;
  }, {});
  const handleAccordionChange = (section: any) => {
    setActiveSection(activeSection === section ? "" : section);
  };

  const handleQuestionClick = (questionId: any) => {
    setActiveQuestion(activeQuestion === questionId ? null : questionId);
    setOpenQuestionIds((prev) =>
      prev.includes(questionId)
        ? prev.filter((id) => id !== questionId)
        : [...prev, questionId],
    );
  };

  const initialValues = {
    mistakeType: "",
    mistakeSubType: "",
    modelAnswer: "",
    message: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log("Form Submitted Values:", values);
    },
  });
  useEffect(() => {
    if (window.MathJax && window.MathJax.Hub) {
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    } else {
      console.error("MathJax is not loaded or available");
    }
  }, [openQuestionIds]);

  const processQuestionText = useCallback((text: any) => {
    if (!text) return null;

    // Split text into LaTeX ($...$) and plain parts
    const parts = text.split(/(\$.*?\$|\([a-d]\))/g);

    return parts.map((part: any, index: any) => {
      if (!part) return null;

      if (part.match(/^\([a-d]\)/)) {
        return (
          <span key={index} className="inline-block w-8">
            {part}
          </span>
        );
      }

      if (part.startsWith("$") && part.endsWith("$")) {
        // Remove the $ symbols for MathJax processing
        const formula = part.slice(1, -1);
        return <MathJax.Node key={index} formula={formula} />;
      }

      return <span key={index}>{part}</span>;
    });
  }, []);

  return (
    <>
      <form
        id="right-panel-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <Box sx={{ width: "100%", maxWidth: 1200, mx: "auto", p: 2 }}>
          {/* Details section */}
          <Accordion sx={{ mb: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid2>
                <Box
                  sx={{ width: "full", height: "200px", borderRadius: "15px" }}
                >
                  <Box
                    sx={{
                      width: "full",
                      alignItems: "center",
                      display: "flex",
                      px: "5px",
                    }}
                  >
                    {/* Details of the question */}
                    <TableContainer>
                      {/* for CBSC */}
                      {/* <Table
                        sx={{
                          border: "1px solid white",
                          backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.backgroundcolr}`,
                        }}
                      >
                        <TableHead sx={{ borderBottom: "1px solid white" }}>
                          <TableRow>
                            <TableCell align="center">A</TableCell>
                            <TableCell align="center">B</TableCell>
                            <TableCell align="center">C</TableCell>
                            <TableCell align="center">D</TableCell>
                            <TableCell align="center">E</TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderLeft: "1px solid white",
                                borderRight: "1px solid white",
                              }}
                            >
                              Grand <br /> Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.bg}`,
                              borderTop: "none",
                              borderBottom: "none",
                            }}
                          >
                            <TableCell align="center">8</TableCell>
                            <TableCell align="center">15</TableCell>
                            <TableCell align="center">15</TableCell>
                            <TableCell align="center">10</TableCell>
                            <TableCell
                              align="center"
                              sx={{ borderRight: "1px solid white" }}
                            >
                              20
                            </TableCell>
                            <TableCell align="center">68</TableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          <TableRow
                            sx={{
                              borderBottom: "1px solid white",
                              backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.bg}`,
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={{ borderBottom: "1px solid white" }}
                            >
                              10
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ borderBottom: "1px solid white" }}
                            >
                              20
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ borderBottom: "1px solid white" }}
                            >
                              20
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ borderBottom: "1px solid white" }}
                            >
                              20
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderBottom: "1px solid white",
                                borderRight: "1px solid white",
                              }}
                            >
                              30
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ borderBottom: "1px solid white" }}
                            >
                              100
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table> */}
                      {/* for ICSE */}
                      <Table
                        sx={{
                          border: "1px solid white",
                          backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.backgroundcolr}`,
                        }}
                      >
                        <TableHead sx={{ borderBottom: "1px solid white" }}>
                          <TableRow>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderLeft: "1px solid white",
                                borderRight: "1px solid white",
                              }}
                            >
                              Selection <br /> total
                            </TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderLeft: "1px solid white",
                                borderRight: "1px solid white",
                              }}
                            >
                              Selection <br /> total
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                borderLeft: "1px solid white",
                                borderRight: "1px solid white",
                              }}
                            >
                              Grand <br /> Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            sx={{
                              backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.bg}`,
                              borderTop: "none",
                              borderBottom: "none",
                            }}
                          >
                            {tableData.map((value, index) => (
                              <TableCell key={index} align="center">
                                {value}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          <TableRow
                            sx={{
                              backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.bg}`,
                            }}
                          >
                            {TotalMarks.map((value, index) => (
                              <TableCell key={index} align="center">
                                {value}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </Grid2>
            </AccordionDetails>
          </Accordion>

          {/* Question section */}
          <MathJax.Provider>
            {Object.entries(sections).map(([sectionName, questions]) => (
              <Accordion
                key={sectionName}
                expanded={sectionName === activeSection}
                onChange={() => handleAccordionChange(sectionName)}
                sx={{ mb: 2 }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">Section {sectionName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2 spacing={3}>
                    {(questions as any[]).map((question: any) => (
                      <Grid2 sx={{ xs: 12 }} key={question.questionId}>
                        <Paper
                          sx={{
                            p: 2,
                            cursor: "pointer",
                            border:
                              question.questionId === activeQuestion
                                ? `2px solid ${darkTheme.colors.teacher.pdf.pdfheader.blue}`
                                : "none",
                          }}
                          onClick={() =>
                            handleQuestionClick(question.questionId)
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mb: 2,
                            }}
                          >
                            <Typography variant="subtitle1">
                              Q: {question.questionNo}
                            </Typography>
                            <PsButton.Custombg
                              variant="contained"
                              sx={{ height: "30px", minWidth: "40px" }}
                              onClick={() =>
                                handleClickOpen(question.questionId)
                              }
                            >
                              {open === question.questionId ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </PsButton.Custombg>
                          </Box>
                          {open === question.questionId && (
                            <>
                              {question.questionType === "MCQ" && (
                                <Box>
                                  <Grid2>
                                    <Paper
                                      id={question.questionId}
                                      sx={{
                                        p: 1,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: "600px",
                                          backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color2}`,
                                          mb: "10px",
                                        }}
                                      >
                                        <Grid2
                                          sx={{
                                            display: "grid",
                                            gridTemplateColumns:
                                              "repeat(3, 1fr)",
                                            px: "10px",
                                            py: "5px",
                                            gap: "10px",
                                          }}
                                        >
                                          <Grid2
                                            sx={{
                                              flexDirection: "column",
                                              gap: "10px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Question
                                            </Typography>
                                            <Typography sx={{ mt: "5px" }}>
                                              <div>
                                                {processQuestionText(
                                                  question.questionText,
                                                )}
                                              </div>
                                            </Typography>
                                          </Grid2>
                                          <Grid2
                                            sx={{
                                              flexDirection: "column",
                                              gap: "20px",
                                              textAlign: "Center",
                                              alignItems: "Center",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Correct Answer
                                            </Typography>
                                            <Typography sx={{ mt: "5px" }}>
                                              <div style={{ fontSize: "18px" }}>
                                                {question.answerText}
                                              </div>
                                            </Typography>
                                          </Grid2>
                                          <Grid2
                                            container
                                            spacing={2}
                                            sx={{
                                              flexDirection: "column",
                                              gap: "10px",
                                              textAlign: "Center",
                                              alignItems: "Center",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Questions
                                            </Typography>
                                            <PsCheckbox
                                              sx={{
                                                "&.Mui-checked": {
                                                  color: `${darkTheme.colors.teacher.pdf.pdfheader.color3}`,
                                                },
                                              }}
                                            />
                                          </Grid2>
                                        </Grid2>
                                      </Box>
                                    </Paper>
                                  </Grid2>
                                </Box>
                              )}

                              {question.questionType === "SQ" && (
                                <Box
                                  sx={{
                                    width: "600px",
                                    backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color2}`,
                                    mb: "10px",
                                    height: "360px",
                                  }}
                                >
                                  <Grid2
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns: "repeat(2 , 1fr)",
                                      gap: "5px",
                                      mx: "5px",
                                      pr: "0px",
                                      pb: "10px",
                                    }}
                                  >
                                    <Grid2
                                      sx={{
                                        width: "300px",
                                        height: "70px",
                                        backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color1}`,
                                        borderRadius: "5px",
                                        mt: "10px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          mt: "2px",
                                          mx: "10px",
                                        }}
                                      >
                                        Total marks
                                        <br />
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                          }}
                                        >
                                          {question.totalMarks}
                                        </span>
                                      </Typography>
                                    </Grid2>
                                    <Grid2
                                      sx={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3 , 1fr)",
                                        gap: "2px",
                                        mt: "10px",
                                      }}
                                    >
                                      <Grid2
                                        sx={{
                                          width: "90px",
                                          height: "70px",
                                          backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color1}`,
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "15px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Total marks
                                          <br />
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "24px",
                                            }}
                                          >
                                            04
                                          </span>
                                        </Typography>
                                      </Grid2>
                                      <Grid2
                                        sx={{
                                          width: "90px",
                                          height: "70px",
                                          backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color1}`,
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "15px",
                                            textAlign: "center",
                                          }}
                                        >
                                          obtained
                                          <br />
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "24px",
                                            }}
                                          >
                                            03
                                          </span>
                                        </Typography>
                                      </Grid2>
                                      <Grid2
                                        sx={{
                                          width: "90px",
                                          height: "70px",
                                          backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color1}`,
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "15px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Attempt
                                          <br />
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "24px",
                                            }}
                                          >
                                            Yes
                                          </span>
                                        </Typography>
                                      </Grid2>
                                    </Grid2>
                                    <Grid2
                                      sx={{
                                        width: "300px",
                                        height: "full",
                                        backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color1}`,
                                        borderRadius: "5px",
                                        mt: "10px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          mt: "2px",
                                          mx: "10px",
                                        }}
                                      >
                                        Qn Graphics <br />
                                        {question.questionText}
                                      </Typography>
                                    </Grid2>
                                    <Grid2
                                      sx={{
                                        display: "grid",
                                        gridTemplateRows: "repeat(2 , 1fr)",
                                        gap: "5px",
                                        mt: "10px",
                                      }}
                                    >
                                      <Grid2>
                                        <FormControl fullWidth>
                                          <InputLabel id="board-select-label">
                                            Mistake type
                                          </InputLabel>
                                          <Select
                                            labelId="board-select-label"
                                            id="board-select"
                                            label="Mistake type"
                                            name="mistakeType"
                                            value={formik.values.mistakeType}
                                            onChange={formik.handleChange}
                                            sx={{
                                              height: "42px",
                                              width: "280px",
                                            }}
                                          >
                                            <MenuItem value="Computation">
                                              Computation
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid2>
                                      <Grid2>
                                        <FormControl fullWidth>
                                          <InputLabel id="board-select-label">
                                            Mistake sub type
                                          </InputLabel>
                                          <Select
                                            labelId="board-select-label"
                                            id="board-select"
                                            label="Mistake sub type"
                                            name="mistakeSubType"
                                            value={formik.values.mistakeSubType}
                                            onChange={formik.handleChange}
                                            sx={{
                                              height: "42px",
                                              width: "280px",
                                            }}
                                          >
                                            <MenuItem value="Addition">
                                              Addition
                                            </MenuItem>
                                            <MenuItem value="Subtraction">
                                              Subtraction{" "}
                                            </MenuItem>
                                            <MenuItem value="Division">
                                              Division
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                      </Grid2>
                                    </Grid2>
                                    <Grid2
                                      sx={{
                                        width: "300px",
                                        height: "92px",
                                        backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color1}`,
                                        borderRadius: "5px",
                                        mt: "10px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          textAlign: "start",
                                          mt: "2px",
                                          mx: "10px",
                                        }}
                                      >
                                        Model answer <br />
                                      </Typography>
                                    </Grid2>
                                    <Grid2 sx={{ mt: "10px" }}>
                                      <FormControl fullWidth>
                                        <TextField
                                          label="Your Message"
                                          variant="outlined"
                                          name="message"
                                          value={formik.values.message}
                                          onChange={formik.handleChange}
                                          multiline
                                          rows={2.5}
                                          sx={{
                                            margin: "0px 0px 0px 0px",
                                            height: "92px",
                                            width: "280px",
                                          }}
                                        />
                                      </FormControl>
                                    </Grid2>
                                  </Grid2>
                                  <PsButton.CustomTheme
                                    variant="outlined"
                                    onClick={() => {
                                      setIsRegionCapture(!isRegionCapture);
                                      setCurrentTool("none");
                                    }}
                                    sx={{
                                      width: "550px",
                                      height: "36px",
                                      textAlign: "center",
                                      mx: "25px",
                                      mb: "0px",
                                    }}
                                  >
                                    Link in pdf area
                                  </PsButton.CustomTheme>
                                </Box>
                              )}
                            </>
                          )}
                          {question.regions && question.regions.length > 0 && (
                            <Grid2 spacing={2}>
                              {question.regions.map(
                                (region: any, index: any) => (
                                  <Grid2
                                    sx={{ xs: 12, md: 6 }}
                                    key={region.id || index}
                                  >
                                    <Card>
                                      <CardMedia
                                        component="img"
                                        image={region.image}
                                        alt={`Region ${index + 1}`}
                                        sx={{
                                          height: 200,
                                          objectFit: "contain",
                                        }}
                                      />
                                      <CardContent>
                                        <TextareaAutosize
                                          value={region.description || ""}
                                          onChange={(e: any) =>
                                            handleRegionDescriptionChange(
                                              question.id,
                                              region.id,
                                              e.target.value,
                                            )
                                          }
                                          placeholder="Add description"
                                          style={{
                                            width: "100%",
                                            padding: "8px",
                                            minHeight: "40px",
                                            border: `1px solid ${darkTheme.colors.teacher.pdf.pdfheader.color4}`,
                                            borderRadius: "4px",
                                            backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.color1}`,
                                            color: `${darkTheme.colors.teacher.pdf.pdfheader.white}`,
                                          }}
                                        />
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          sx={{
                                            mt: 1,
                                            display: "inline-flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          Page: {region.pageNumber} |{" "}
                                          {region.timestamp}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </Grid2>
                                ),
                              )}
                            </Grid2>
                          )}
                        </Paper>
                      </Grid2>
                    ))}
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            ))}
          </MathJax.Provider>
        </Box>
      </form>
    </>
  );
};

export interface RightPanelRef {
  scrollToImage: (imageId: number) => void;
}
