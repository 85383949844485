import { Box, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useState } from "react";
import { PsButton } from "../../../../../lib/psElements/psElements";
import { GoBackArrowIos } from "../../../../common/GoBackButton/GoBackButton";
import { FooterSticky } from "../Theme/PDFtheme";
import { useTeacherNavigation } from "../../../../../services/hooks/teacher/navigationHooks";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";

export const PDFfooter = () => {
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <FooterSticky>
      <Box
        sx={{
          width: "100%",
          height: "70px",
          boxShadow: "0px -8px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "flex-end",
            gap: "10px",
            px: "20px",
            py: "10px",
          }}
        >
          <PsButton.CustomTheme variant="outlined" sx={{ width: 120 }}>
            Save
          </PsButton.CustomTheme>
          <PsButton.Custom
            type="submit"
            form="right-panel-form"
            variant="contained"
            sx={{ width: 120 }}
            onClick={handleClickOpen}
          >
            Procced
          </PsButton.Custom>

          <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            sx={{
              textAlign: "center",
              Color: `${darkTheme.colors.teacher.pdf.pdfFooter}`,
            }}
          >
            <DialogTitle>
              <GoBackArrowIos handlerBackButton={handleClose} />
              Would you like to share final comments?
            </DialogTitle>
            <DialogContent>
              <PsButton.Custom
                variant="contained"
                color="secondary"
                sx={{ margin: "10px", width: "450px" }}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                No, I have added everything, go ahead and submit
              </PsButton.Custom>

              <PsButton.Custom
                variant="contained"
                color="secondary"
                sx={{ margin: "10px", width: "450px" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Oh yes!
              </PsButton.Custom>
              <PsButton.CustomTheme
                variant="outlined"
                onClick={handleClose}
                color="secondary"
                sx={{ width: "450px", margin: "10px" }}
              >
                Cancel
              </PsButton.CustomTheme>
            </DialogContent>
          </Dialog>
        </Stack>
      </Box>
    </FooterSticky>
  );
};
