import { ListItem, ListItemText, Tooltip } from "@mui/material";
import { Fragment, FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useStudentNaviagation } from "../../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../../services/storage/store";
import IconNews from "../../../assets/svg/menu/news.svg";
import { SMListItemButton, SMListItemIcon } from "./customItems";

interface subjectMenuTypes {
  open: boolean;
}

const SubjectMenu: FunctionComponent<subjectMenuTypes> = (
  props: subjectMenuTypes,
) => {
  const studentNavigate = useStudentNaviagation();
  const { open } = props;
  const {
    studentProfile: {
      profile: { subjects },
    },
  } = useSelector((state: RootState) => state);

  const subjectList: string[] = subjects || [];
  return (
    <Fragment>
      {subjectList.map((item: string) => {
        return (
          <Tooltip title={item}>
            <ListItem disablePadding sx={{ display: "block" }}>
              <SMListItemButton
                sx={[
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
                onClick={studentNavigate.navigateToDashboard}
              >
                <SMListItemIcon
                  sx={[
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  <img src={IconNews} className="icon24" />
                </SMListItemIcon>
                <ListItemText
                  primary={item}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </SMListItemButton>
            </ListItem>
          </Tooltip>
        );
      })}
    </Fragment>
  );
};

export { SubjectMenu };
