import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { RootState } from "../../services/storage/store";
import Drawersection from "../components/Teacher/Sidebar/Drawer";

const drawerWidth = 240;

export const TeacherContainer = () => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const {
    common: { sideMenuShowHide },
  } = useSelector((state: RootState) => state);

  const { Id } = useSelector((state: RootState) => state.TeacherprofileData);
  useEffect(() => {
    if (
      location.pathname.startsWith("/authorization") ||
      location.pathname.startsWith("/teacher/register") ||
      location.pathname.startsWith(`/teacher/evaluationnow/${Id}`)
    ) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [location.pathname]);
  let showHide: boolean = location.pathname === `/teacher/evaluationnow/${Id}`;
  return (
    <div
      style={{
        width: "100%",
        margin: "0px 0px 0px 0px ",
        padding: "0px 0px 0px 0px",
      }}
    >
      {!showHide && <Drawersection />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: showHide
            ? "0px"
            : sideMenuShowHide
              ? `${drawerWidth}px`
              : "70px",
          transition: (theme) =>
            theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            //  minHeight: "100vh",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};
