// components/DeleteConfirmationModal.tsx
import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
// import { Region } from "./types";
interface Region {
  id: number;
  image: string;
  timestamp: string;
  pageNumber: number;
  dimensions: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  description: string;
}

interface Question {
  id: number;
  questionNumber: string;
  totalMarks: number;
  inputMarks: number;
  regions: Region[];
}

interface Section {
  id: number;
  title: string;
  isExpanded: boolean;
  questions: Question[];
}

interface DeleteConfirmationModalProps {
  showDeleteConfirm: boolean;
  setShowDeleteConfirm: (show: boolean) => void;
  setRegionToDelete: (region: Region | null) => void;
  handleDeleteConfirm: () => void;
}

export const DeleteConfirmationModal: React.FC<
  DeleteConfirmationModalProps
> = ({
  showDeleteConfirm,
  setShowDeleteConfirm,
  setRegionToDelete,
  handleDeleteConfirm,
}) => {
  const handleClose = () => {
    setShowDeleteConfirm(false);
    setRegionToDelete(null);
  };

  return (
    <Dialog
      open={showDeleteConfirm}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Delete Captured Region</DialogTitle>
      <DialogContent>
        <Typography color="text.secondary">
          Are you sure you want to delete this captured region? This action
          cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button onClick={handleClose} variant="outlined" color="inherit">
          Cancel
        </Button>
        <Button
          onClick={handleDeleteConfirm}
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
