// components/PDFViewer/ToolPanel/index.tsx
import {
  Check as CheckIcon,
  Palette as ColorIcon,
  Crop as CropIcon,
  Close as CrossIcon,
  GetApp as DownloadIcon,
  FormatColorFill as EraserIcon,
  Create as PencilIcon,
  Redo as RedoIcon,
  TextFields as TextIcon,
  Undo as UndoIcon,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { StyledToolbar, ToolGroup } from "../Theme/PDFtheme";

interface ToolPanelProps {
  currentTool: string;
  setCurrentTool: (tool: string) => void;
  stampType: string;
  setStampType: (type: string) => void;
  textInput: string;
  setTextInput: (text: string) => void;
  currentColor: string;
  setCurrentColor: (color: string) => void;
  lineWidth: number;
  setLineWidth: (width: number) => void;
  fontSize: number;
  setFontSize: (size: number) => void;
  undo: () => void;
  redo: () => void;
  currentStep: number;
  historyLength: number;
  isRegionCapture: boolean;
  setIsRegionCapture: (capture: boolean) => void;
}
const menuItems = [
  { value: 1, label: "1px" },
  { value: 2, label: "2px" },
  { value: 3, label: "3px" },
  { value: 4, label: "4px" },
  { value: 5, label: "5px" },
  { value: 6, label: "6px" },
  { value: 8, label: "8px" },
  { value: 10, label: "10px" },
  { value: 12, label: "12px" },
  { value: 14, label: "14px" },
  { value: 16, label: "16px" },
  { value: 20, label: "20px" },
];
const FontSize = [
  { value: 12, label: "12" },
  { value: 14, label: "14" },
  { value: 16, label: "16" },
  { value: 18, label: "18" },
  { value: 20, label: "20" },
  { value: 24, label: "24" },
  { value: 28, label: "28" },
  { value: 32, label: "32" },
  { value: 36, label: "36" },
  { value: 40, label: "40" },
  { value: 48, label: "48" },
  { value: 56, label: "56" },
  { value: 64, label: "64" },
  { value: 72, label: "72" },
  { value: 96, label: "96" },
];
export const ToolPanel: React.FC<ToolPanelProps> = ({
  currentTool,
  setCurrentTool,
  stampType,
  setStampType,
  textInput,
  setTextInput,
  currentColor,
  setCurrentColor,
  lineWidth,
  setLineWidth,
  fontSize,
  setFontSize,
  undo,
  redo,
  currentStep,
  historyLength,
  isRegionCapture,
  setIsRegionCapture,
}) => (
  <AppBar
    position="sticky"
    color="default"
    elevation={0}
    sx={{ backgroundColor: "background.paper" }}
  >
    <StyledToolbar>
      {/* Drawing Tools */}
      <ToolGroup size="small">
        <Tooltip title="Select">
          <Button
            variant={currentTool === "none" ? "contained" : "outlined"}
            onClick={() => {
              setIsRegionCapture(false);
              setCurrentTool("none");
            }}
          >
            Select
          </Button>
        </Tooltip>
        <Tooltip title="Draw">
          <Button
            variant={currentTool === "scribble" ? "contained" : "outlined"}
            onClick={() => {
              setIsRegionCapture(false);
              setCurrentTool("scribble");
            }}
            startIcon={<PencilIcon />}
          >
            Draw
          </Button>
        </Tooltip>
        <Tooltip title="Erase">
          <Button
            variant={currentTool === "eraser" ? "contained" : "outlined"}
            onClick={() => {
              setIsRegionCapture(false);
              setCurrentTool("eraser");
            }}
            startIcon={<EraserIcon />}
          >
            Erase
          </Button>
        </Tooltip>
      </ToolGroup>

      <Divider orientation="vertical" flexItem />

      {/* Stamp Tools */}
      <ToolGroup size="small">
        <Tooltip title="Check Stamp">
          <Button
            variant={
              currentTool === "stamp" && stampType === "check"
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              setCurrentTool("stamp");
              setStampType("check");
              setIsRegionCapture(false);
            }}
            startIcon={<CheckIcon />}
          >
            Check
          </Button>
        </Tooltip>
        <Tooltip title="Cross Stamp">
          <Button
            variant={
              currentTool === "stamp" && stampType === "cross"
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              setCurrentTool("stamp");
              setStampType("cross");
              setIsRegionCapture(false);
            }}
            startIcon={<CrossIcon />}
          >
            Cross
          </Button>
        </Tooltip>
      </ToolGroup>

      <Divider orientation="vertical" flexItem />

      {/* Text Tool */}
      <Box display="flex" alignItems="center" gap={1}>
        <Tooltip title="Add Text">
          <Button
            variant={currentTool === "text" ? "contained" : "outlined"}
            onClick={() => {
              setIsRegionCapture(false);
              setCurrentTool("text");
            }}
            startIcon={<TextIcon />}
            size="small"
          >
            Text
          </Button>
        </Tooltip>
        {currentTool === "text" && (
          <TextField
            size="small"
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            placeholder="Enter text..."
            variant="outlined"
          />
        )}
      </Box>
      <Divider orientation="vertical" flexItem />
      {/* Region Capture */}
      <Tooltip title="Region Capture">
        <Button
          variant={isRegionCapture ? "contained" : "outlined"}
          onClick={() => {
            setIsRegionCapture(!isRegionCapture);
            setCurrentTool("none");
          }}
          startIcon={<CropIcon />}
          size="small"
        >
          {isRegionCapture ? "Cancel Capture" : "Capture Region"}
        </Button>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      {/* Style Controls */}
      <Box display="flex" alignItems="center" gap={1}>
        <ColorIcon sx={{ color: "action.active" }} />
        <input
          type="color"
          value={currentColor}
          onChange={(e) => setCurrentColor(e.target.value)}
          style={{
            width: 32,
            height: 32,
            padding: 0,
            border: "none",
            cursor: "pointer",
          }}
        />
        <Select
          size="small"
          value={lineWidth}
          onChange={(e) => setLineWidth(Number(e.target.value))}
        >
          {menuItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <Select
          size="small"
          value={fontSize}
          onChange={(e) => setFontSize(Number(e.target.value))}
        >
          {FontSize.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* History Controls */}
      <ToolGroup size="small">
        <Tooltip title="Undo">
          <span>
            <IconButton onClick={undo} disabled={currentStep <= 0} size="small">
              <UndoIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Redo">
          <span>
            <IconButton
              onClick={redo}
              disabled={currentStep >= historyLength - 1}
              size="small"
            >
              <RedoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </ToolGroup>

      <Divider orientation="vertical" flexItem />

      {/* Region Capture */}
    </StyledToolbar>
  </AppBar>
);
