import { createSlice } from "@reduxjs/toolkit";

const GetProfile: object[] = [];
const GetTask: [] = [];
const GetSubject: object[] = [];
const GetPDF: string = "";
const GetperticularTask: string = "";
const GetpdfID: string = "";
const GettestID: string = "";
const GetQnPaperFormat: object[] = [];
const ApiQnPapaerAnswersheet: object[] = [];
const GetPaperDetails: object[] = [];
const GetCertificate: object[] = [];
const timespent: string = "";
// const postpdf: object[] = [];
const initProfile = {
  firstName: "",
  lastname: "",
  email: "",
  country: "",
  State: "",
  city: "",
  Number: "",
  phone: "",
  Associate: "",
};
const profileSlice = createSlice({
  name: "TeacherprofileData",
  initialState: {
    profile: initProfile,
    experience: {
      Institutionname: "",
      Location: "",
      ExperienceType: "",
      Year: "",
      Mt: "",
      Workinghere: false,
    },
    education: {
      Degree: "",
      Subject: "",
      Year: "",
      Certificate: "",
    },
    enrollment: {
      Board: "",
      subject: "",
      grade: "",
    },
    schedule: {
      currently: "",
      allocatedInweek: "",
      allocatedInMonth: "",
    },
    Bankdetails: {
      Name: "",
      Accountnumber: "",
      IFSC: "",
      PANno: "",
    },
    ProfileData: GetProfile,
    Taskdata: GetTask,
    Subject: GetSubject,
    PDF: GetPDF,
    perticularTask: GetperticularTask,
    Id: GetpdfID,
    testID: GettestID,
    QnPaperFormat: GetQnPaperFormat,
    QnPapaerAnswersheet: ApiQnPapaerAnswersheet,
    QnPaperDetails: GetPaperDetails,
    Certificate: GetCertificate,
    Time: timespent,
    // PostPDF:postpdf
  },
  reducers: {
    PersonalInformation(state, action) {
      state.profile = action.payload;
    },
    EducationData(state, action) {
      state.education = action.payload;
    },
    ExperienceData(state, action) {
      state.experience = action.payload;
    },
    EnrollmentData(state, action) {
      state.enrollment = action.payload;
    },
    ScheduleData(state, action) {
      state.schedule = action.payload;
    },
    BankdetailsData(state, action) {
      state.Bankdetails = action.payload;
    },
    setProfileData(state, action) {
      state.ProfileData = action.payload;
    },
    setGetTask(state, action) {
      state.Taskdata = action.payload;
    },
    setQnPaperFormat(state, action) {
      state.QnPaperFormat = action.payload;
    },
    setQnPapaerAnswersheet(state, action) {
      state.QnPapaerAnswersheet = action.payload;
    },
    setSubject(state, action) {
      state.Subject = action.payload;
    },
    setPDF(state, action) {
      state.PDF = action.payload;
    },
    setpdfIDs(state, action) {
      state.Id = action.payload;
      console.log("PDF id reducer :::::", action.payload);
    },
    setGetDetails(state, action) {
      state.QnPaperDetails = action.payload;
    },
    // setPostPDF(state, action) {
    //   state.PostPDF=action.payload
    // }
    setCertificate(state, action) {
      state.Certificate = action.payload;
    },
    setGetperticularTask(state, action) {
      state.perticularTask = action.payload;
    },
    settestID(state, action) {
      state.testID = action.payload;
    },
    setTime(state, action) {
      state.Time = action.payload;
    },
  },
});

export const {
  PersonalInformation,
  EducationData,
  ExperienceData,
  EnrollmentData,
  ScheduleData,
  BankdetailsData,
  setProfileData,
  setGetTask,
  setQnPaperFormat,
  setQnPapaerAnswersheet,
  setSubject,
  setPDF,
  setpdfIDs,
  setGetDetails,
  setCertificate,
  setGetperticularTask,
  settestID,
  setTime,
} = profileSlice.actions;
export default profileSlice.reducer;
