import MenuIcon from "@mui/icons-material/Menu";
import { Tooltip } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import { useStudentNaviagation } from "../../../../services/hooks/student/navigationHooks";
import { showHideSideMenu } from "../../../../services/storage/common";
import { RootState } from "../../../../services/storage/store";
import IconDrawerBack from "../../../assets/svg/menu/drawerback.svg";
import IconNews from "../../../assets/svg/menu/news.svg";
import IconNotification from "../../../assets/svg/menu/notification.svg";
import IconPhysics from "../../../assets/svg/menu/physics.svg";
import { SMListItemButton, SMListItemIcon } from "./customItems";
import { LogoutItem, PsLogoItem, UserAvatar } from "./MenuItems";
import { SubjectMenu } from "./subjectsMenu";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const {
    common: { sideMenuShowHide },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const studentNavigation = useStudentNaviagation();
  const open: boolean = sideMenuShowHide;

  const handleDrawerOpen = () => {
    dispatch(showHideSideMenu(true));
  };

  const handleDrawerClose = () => {
    dispatch(showHideSideMenu(false));
  };

  return (
    <Box sx={{ display: "flex" }} className="studentSideMenu">
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={[
          {
            width: 65,
            display: "flex",
            alignSelf: "flex-start",
            left: 0,
            right: "auto",
          },
          open && { visibility: "hidden" },
        ]}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[
              {
                marginRight: 5,
              },
              open && { display: "none" },
            ]}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <img src={IconDrawerBack} />
          </IconButton>
          <PsLogoItem open={open} />
        </DrawerHeader>
        <Divider />

        <List>
          <SubjectMenu open={open} />
          {/* <Tooltip title="Dashbaord">
            <ListItem disablePadding sx={{ display: "block" }}>
              <SMListItemButton
                sx={[
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
                onClick={studentNavigation.navigateToDashboard}
              >
                <SMListItemIcon
                  sx={[
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  <img src={IconOverview} className="icon24" />
                </SMListItemIcon>
                <ListItemText
                  primary="Dashbaord"
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </SMListItemButton>
            </ListItem>
          </Tooltip> */}
          <Tooltip title="Test">
            <ListItem disablePadding sx={{ display: "block" }}>
              <SMListItemButton
                sx={[
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
                onClick={studentNavigation.navigateToCreateNewTestPage}
              >
                <SMListItemIcon
                  sx={[
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  <img src={IconNotification} className="icon24" />
                </SMListItemIcon>
                <ListItemText
                  primary="Test"
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </SMListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="Physics">
            <ListItem disablePadding sx={{ display: "block" }}>
              <SMListItemButton
                sx={[
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
              >
                <SMListItemIcon
                  sx={[
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  <img src={IconPhysics} className="icon24" />
                </SMListItemIcon>
                <ListItemText
                  primary="Physics"
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </SMListItemButton>
            </ListItem>
          </Tooltip>
          <Tooltip title="News">
            <ListItem disablePadding sx={{ display: "block" }}>
              <SMListItemButton
                sx={[
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
              >
                <SMListItemIcon
                  sx={[
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  <img src={IconNews} className="icon24" />
                </SMListItemIcon>
                <ListItemText
                  primary="News"
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </SMListItemButton>
            </ListItem>
          </Tooltip>
          {/*  {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <SMListItemButton
                sx={[
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
              >
                <SMListItemIcon
                  sx={[
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </SMListItemIcon>
                <ListItemText
                  primary={text}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </SMListItemButton>
            </ListItem>
          ))} */}
        </List>
        {/* <Divider /> */}
        {/*   <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={[
                  {
                    minHeight: 48,
                    px: 2.5,
                  },
                  open
                    ? {
                        justifyContent: "initial",
                      }
                    : {
                        justifyContent: "center",
                      },
                ]}
              >
                <ListItemIcon
                  sx={[
                    {
                      minWidth: 0,
                      justifyContent: "center",
                    },
                    open
                      ? {
                          mr: 3,
                        }
                      : {
                          mr: "auto",
                        },
                  ]}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={[
                    open
                      ? {
                          opacity: 1,
                        }
                      : {
                          opacity: 0,
                        },
                  ]}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        <UserAvatar open={open} />
        <Divider />
        <LogoutItem open={open} />
      </Drawer>
    </Box>
  );
}
