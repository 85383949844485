import { FunctionComponent } from "react";

import PaperCorrectionHistory from "../components/Teacher/Dashboard/PapercorrectionHsitory";
import EnhancedTable from "../components/Teacher/Dashboard/Tasktable";

const TeacherDashboard: FunctionComponent = () => {
  return (
    <>
      <div style={{ marginBottom: "60px" }}>
        <PaperCorrectionHistory />
        <EnhancedTable />
      </div>
    </>
  );
};
export default TeacherDashboard;
