import { useNavigate } from "react-router-dom";

export const useTeacherNavigation = () => {
  const navigate = useNavigate();

  const navigateToTeacherDashboard = () => {
    navigate("/teacher/dashboard");
  };
  const navigateToTeacherProfile = () => {
    navigate("/teacher/profile");
  };

  const navigationEvalutionNow = (id: any) => {
    navigate(`/teacher/evaluationnow/${id}`);
  };
  const navigateToTeacherPersonalinformation = () => {
    navigate("/teacher/register/personalinformation");
  };

  const navigateToTeachereducation = () => {
    navigate("/teacher/register/eduction");
  };

  const navigateToTeacherEnrollment = () => {
    navigate("/teacher/register/enrollment");
  };

  const navigateToTeacherSchedule = () => {
    navigate("/teacher/register/correctionschedule");
  };
  const navigateToTeacherExperience = () => {
    navigate("/teacher/register/experience");
  };

  const navigateToTeacherBankdetails = () => {
    navigate("/teacher/register/bankdetails");
  };

  const navigateTologOut = () => {
    navigate("/authorization/login");
  };

  return {
    navigateToTeacherDashboard,
    navigateToTeacherProfile,
    navigateToTeachereducation,
    navigateToTeacherPersonalinformation,
    navigateToTeacherEnrollment,
    navigateToTeacherSchedule,
    navigateToTeacherExperience,
    navigateToTeacherBankdetails,
    navigateTologOut,
    navigationEvalutionNow,
  };
};
