import { Box, Card, Typography } from "@mui/material";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import CalendarEventFillSvg from "../../../../assets/svg/student/calendar-event-fill.svg";
import RightArrowSvg from "../../../../assets/svg/student/rihtArraow.svg";

export const SubjectSummary = () => {
  return (
    <>
      <Box
        sx={{
          height: "22rem",
          width: "100%",
          backgroundColor: darkTheme.colors.surface.one,
          p: 4,
          borderRadius: 3,
        }}
      >
        <Box>
          <Typography sx={{ mb: 0.5 }}>Mathematics Summary</Typography>
          <Box
            sx={{
              gap: 2,
              display: "flex",
              direction: "row",
              overflow: "hidden",
              overflowX: "scroll",
              scrollbarWidth: "thin",
              width: "100%",
            }}
            overflow={"auto"}
          >
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #2D4190 0%, #257BD7 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #662181 0%, #A9316D 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #202121 0%, #626262 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #1F263C 0%, #1F263C 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #08293C 0%, #04505C 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>

            {/* Old code copy */}
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #2D4190 0%, #257BD7 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #662181 0%, #A9316D 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #202121 0%, #626262 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #1F263C 0%, #1F263C 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #08293C 0%, #04505C 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #2D4190 0%, #257BD7 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #662181 0%, #A9316D 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #202121 0%, #626262 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #1F263C 0%, #1F263C 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
            <Card
              sx={{
                minWidth: "14rem",
                height: "13.4375rem",
                background: "linear-gradient(360deg, #08293C 0%, #04505C 100%)",
                "box-shadow": "0px 8px 20px 0px rgba(0, 0, 0, 0.4)",
                alignContent: "center",
                justifyContent: "center",
                display: "flex",
                flexFlow: "column",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "center",
                  mt: 1.9,
                }}
              >
                <img width={20} height={20} src={CalendarEventFillSvg} />
                <Typography sx={{ ml: 1 }}>Last Test</Typography>
              </Box>
              <Box
                sx={{
                  width: "11.75rem",
                  height: "4.5rem",
                  background: darkTheme.colors.font.light,
                  margin: "auto",
                  mt: 2,
                }}
              ></Box>
              <Typography sx={{ justifyContent: "center", display: "flex" }}>
                4 days vs last time
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  Height: "2.75rem",
                  flexFlow: "row",
                  display: "flex",
                  borderTop: "1px solid #fff",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    mt: 1,
                    mb: 1.5,
                  }}
                >
                  Take Test
                </Typography>
                <img
                  width={20}
                  height={20}
                  src={RightArrowSvg}
                  style={{
                    marginTop: "0.5rem",
                    marginBottom: "0.75rem",
                    marginLeft: "0.25rem",
                  }}
                />
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};
