import { ListItemButton, ListItemIcon, styled } from "@mui/material";

export const SMListItemButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: "3rem",
  paddingLeft: "1.25rem",
  paddingRight: "1.25rem",
}));

export const SMListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  justifyContent: "center",
}));
