import { Box } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useAuthorizationHooks } from "../../services/hooks/authorizationHooks";
import { RootState } from "../../services/storage/store";
import { setUploadModalFlag } from "../../services/storage/student/test";
import SideMenu from "../components/student/SideMenu/SideMenu";
import UploadTestPaper from "../components/student/Test/UploadTestPaper/UploadTestPaper";

const Student: FunctionComponent = () => {
  const { checkUserTypeToNavigateOnDashboard } = useAuthorizationHooks();
  const {
    studentTest: { openUploadModal },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    checkUserTypeToNavigateOnDashboard();
  }, []);

  const handlerChangeUploadModalState = (value: boolean) => {
    dispatch(setUploadModalFlag(value));
  };

  return (
    <>
      <SideMenu />
      <Box sx={{ ml: 30 }}>
        <Outlet />
      </Box>
      <UploadTestPaper
        open={openUploadModal}
        openCallBack={handlerChangeUploadModalState}
      />
    </>
  );
};

export default Student;
