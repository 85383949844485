import { Box, Stack, SvgIcon } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserTypeEnum } from "../../../constants/types/userTypes";
import { BlackButton } from "../../../lib/psElements/psElements";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import applesvg from "../../assets/svg/authorization/apple.svg";
import facebooksvg from "../../assets/svg/authorization/facebook.svg";
import googlesvg from "../../assets/svg/authorization/google.svg";
import GoBackButton from "../../common/GoBackButton/GoBackButton";

const SocialMedia = () => {
  return (
    <Box className="socialMedia">
      <div className="hover orange">
        <img src={googlesvg} alt="google" />
      </div>
      <div className="hover orange">
        <img src={applesvg} alt="apple" />
      </div>
      <div className="hover orange">
        <img src={facebooksvg} alt="facebook" />
      </div>
    </Box>
  );
};

function SelectLoginType() {
  const { profile } = useSelector((state: RootState) => state.studentProfile);
  const dispatch = useDispatch();
  const studentNaviage = useStudentNaviagation();
  const userType = profile.userType;

  useEffect(() => {
    if (!userType) {
      dispatch(personalDetail({ ...profile, userType: UserTypeEnum.Student }));
    }
  });

  const handlerUserTypeSelection = (userType: UserTypeEnum) => {
    dispatch(personalDetail({ ...profile, userType }));
  };

  return (
    <Box className="selectLoginType">
      <GoBackButton />
      <Stack direction="column" spacing={"1rem"}>
        <h1 className="psHeading h1">Login</h1>
        <Stack direction="row" spacing={"1.5rem"}>
          <BlackButton
            className="btnUserType hoverOrange"
            variant="contained"
            style={
              String(userType).toLowerCase() ===
              String(UserTypeEnum.Student).toLowerCase()
                ? { border: "1px solid #FD9B18" }
                : {}
            }
            onClick={() => handlerUserTypeSelection(UserTypeEnum.Student)}
          >
            Student
          </BlackButton>
          <BlackButton
            variant="contained"
            className="btnUserType hoverOrange"
            style={
              String(userType).toLowerCase() ===
              String(UserTypeEnum.Teacher).toLowerCase()
                ? { border: "1px solid #FD9B18" }
                : {}
            }
            onClick={() => handlerUserTypeSelection(UserTypeEnum.Teacher)}
          >
            Teacher
          </BlackButton>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        sx={{ mt: "2.375rem", mb: "2.375rem", gap: "2.5rem", maxWidth: "100%" }}
      >
        <BlackButton
          className="btnSelectType hoverOrange"
          variant="contained"
          onClickCapture={studentNaviage.navigateToPhoneLogin}
          endIcon={
            <SvgIcon>
              {" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_163_1366)">
                  <path
                    d="M13.1687 12.0006L8.92566 7.75763C8.5352 7.36717 8.5352 6.7341 8.92566 6.34363C9.31613 5.95316 9.9492 5.95316 10.3397 6.34363L15.2896 11.2935C15.6801 11.684 15.6801 12.3172 15.2896 12.7077L10.3397 17.6576C9.9492 18.0481 9.31613 18.0481 8.92566 17.6576C8.5352 17.2672 8.5352 16.6341 8.92566 16.2436L13.1687 12.0006Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_163_1366">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
          }
        >
          By Phone Number
        </BlackButton>
        <BlackButton
          className="btnSelectType hoverOrange"
          variant="contained"
          endIcon={
            <SvgIcon>
              {" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_163_1366)">
                  <path
                    d="M13.1687 12.0006L8.92566 7.75763C8.5352 7.36717 8.5352 6.7341 8.92566 6.34363C9.31613 5.95316 9.9492 5.95316 10.3397 6.34363L15.2896 11.2935C15.6801 11.684 15.6801 12.3172 15.2896 12.7077L10.3397 17.6576C9.9492 18.0481 9.31613 18.0481 8.92566 17.6576C8.5352 17.2672 8.5352 16.6341 8.92566 16.2436L13.1687 12.0006Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_163_1366">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
          }
          onClickCapture={studentNaviage.navigateToEmailLogin}
        >
          By Email
        </BlackButton>
        <div className="orSeparator" style={{ textAlign: "center" }}>
          <span>
            <hr />
            &nbsp;&nbsp;OR
            <hr />
          </span>
        </div>
      </Stack>

      <SocialMedia />
    </Box>
  );
}
export default SelectLoginType;
