import { FunctionComponent } from "react";
import { SubjectSummary } from "./SubjectSummary";

const TestWiseView: FunctionComponent = () => {
  return (
    <>
      <SubjectSummary />
    </>
  );
};

export default TestWiseView;
