import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { BorderLinearProgress } from "../../../../../lib/psElements/psElements";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import crossLineSVG from "../../../../assets/svg/student/close-line.svg";
import fileIconSVG from "../../../../assets/svg/student/fileIcon.svg";

interface LinearProgressTypes {
  fileName: string;
  progress: number;
}

const LinearProgress: FunctionComponent<LinearProgressTypes> = (
  props: LinearProgressTypes,
) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexFlow: "row",
        p: 2,
        width: "95%",
        height: 72,
        alignItems: "center",
        background: darkTheme.colors.surface.two,
      }}
    >
      <img src={fileIconSVG} width={38} height={38} />
      <Box sx={{ flexFlow: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", ml: 1.5 }}>
          <Typography sx={{ fontSize: 14, fontWeight: 40 }}>
            {props.fileName}
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 40 }}>
            Uploding... {props.progress}%{" "}
          </Typography>
        </Box>
        <BorderLinearProgress
          sx={{ width: 400, ml: 1.5 }}
          variant="determinate"
          value={props.progress}
        />
      </Box>

      <Box
        sx={{
          ml: 3,
          borderLeft: `2px solid ${darkTheme.colors.chip.white}`,
          height: 40,
          width: 44,
          display: "flex",
          alignItems: "flex-end",
          flexFlow: "row",
        }}
      >
        <img
          src={crossLineSVG}
          width={24}
          height={24}
          style={{
            margin: "auto",
            color: darkTheme.colors.font.dark,
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
};

export default LinearProgress;
