import {
  Box,
  Button,
  ButtonGroup,
  Card,
  LinearProgress,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";

export const LeftSection = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  overflowY: "auto",
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
}));

export const PdfNavigationSection = styled(Box)(({ theme }) => ({
  width: "22%",
  height: "100vh",
  overflowY: "auto",
  borderRight: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0),
  scrollbarWidth: "thin", // For Firefox
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`, // For Firefox
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.background.paper,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const RightSection = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
}));

export const ToolPanelContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  position: "sticky",
  top: "-25px",
  zIndex: 10,
}));

export const ContentAreaMain = styled(Box)(({ theme }) => ({
  // flexGrow: 1,
  overflowY: "auto",
  padding: theme.spacing(3),
}));

export const FooterSticky = styled(Box)(({ theme }) => ({
  position: "sticky",
  bottom: 0,
  padding: theme.spacing(1),
  zIndex: 10,
  backgroundColor: theme.palette.background.paper,
}));

export const LoadingOverlay = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(6),
}));

export const ProgressIndicator = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  padding: theme.spacing(2),
  width: 320,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  backgroundColor: theme.palette.background.paper,
  zIndex: theme.zIndex.drawer + 1,
}));

export const PDFContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing(4),
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  flexWrap: "wrap",
  minHeight: 64,
  padding: theme.spacing(4, 2),
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const ToolGroup = styled(ButtonGroup)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    borderColor: theme.palette.divider,
  },
}));

export const PageContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

export const CanvasContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  marginTop: theme.spacing(2),
}));

export const StyledCanvas = styled("canvas")<{
  $tool: string;
  $isRegionCapture: boolean;
}>(({ $tool, $isRegionCapture }) => ({
  maxWidth: "100%",
  cursor: $isRegionCapture
    ? "crosshair"
    : $tool === "scribble" || $tool === "eraser"
      ? "crosshair"
      : $tool === "stamp" || $tool === "text"
        ? "pointer"
        : "default",
}));

export const SelectionRect = styled(Box)(({ theme }) => ({
  position: "absolute",
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.primary.main + "1A", // 10% opacity
  pointerEvents: "none",
}));

export const UploadContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  padding: theme.spacing(4),
  backgroundColor: theme.palette.grey[50],
  border: `2px dashed ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius * 2,
  transition: "border-color 0.2s ease",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

export const UploadButton = styled(Button)(({ theme }) => ({
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.grey[200],
  "& .MuiLinearProgress-bar": {
    borderRadius: 4,
  },
}));

export const FileDetails = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

export const FileProperty = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  "& .label": {
    fontWeight: 600,
  },
}));

export const PageNavigation = styled(Box)({
  maxHeight: "99vh",
  overflowY: "auto",
  zIndex: 100,
  backgroundColor: `${darkTheme.colors.teacher.theme.white}`,
  borderRadius: 8,
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  padding: "12px 2px",
  overflowX: "hidden",
});

export const PageThumb = styled(Paper)(({ active }: { active?: boolean }) => ({
  cursor: "pointer",
  margin: "8px 0",
  padding: "2px",
  border: `2px solid ${active ? `${darkTheme.colors.teacher.theme.blue}` : `${darkTheme.colors.teacher.theme.white}`}`,
}));

export const StyledPanel = styled(Box)(({ theme }) => ({
  height: "100vh",
  width: 320,
  backgroundColor: theme.palette.background.paper,
  borderLeft: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  overflowY: "auto",
  position: "fixed",
  right: 0,
  top: 0,
}));

export const Header = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.background.paper,
  paddingBottom: theme.spacing(2),
  zIndex: 10,
}));

export const ContentArea = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[50],
}));

export const RegionCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: theme.transitions.create(["box-shadow", "transform"], {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    transform: "translateY(-4px)",
    boxShadow: theme.shadows[8],
  },
}));

export const ImageInfo = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
}));

export const MainSection = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100vh",
  display: "flex",
  // flexDirection: "column",
  alignItems: "center",
}));
