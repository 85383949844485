import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  CardContent,
  Grid2,
  Grow,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BoardCard,
  GradeChip,
  PsButton,
  StepBox,
} from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/student/studentProfileAPIMethods";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../services/storage/store";
import { personalDetail } from "../../../services/storage/student/profile";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const GradeDetails: FunctionComponent = () => {
  const studentNaviage = useStudentNaviagation();
  const { getGradeDetails } = useStudentProfileAPICalls();
  const { listOfGradeNumbers, profile } = useSelector(
    (state: RootState) => state.studentProfile,
  );
  const [paginationCount, setPaginationCount] = useState(0);
  const [boardNames, setBoardNames] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const grade = profile.grade;

  useEffect(() => {
    getGradeDetails();
  }, []);

  useEffect(() => {
    const count = listOfGradeNumbers.length / 8;
    if (count !== paginationCount) setPaginationCount(count);
    changeListOnUserViews(currentPage);
  }, [listOfGradeNumbers]);

  useEffect(() => {
    changeListOnUserViews(currentPage);
  }, [currentPage]);

  const changeListOnUserViews = (page: number) => {
    const tempList: any = _.slice(listOfGradeNumbers, (page - 1) * 8, page * 8);
    console.log("tempList", ...[tempList]);
    setBoardNames(tempList);
  };

  const handlerPageChange = (e: any, page: number) => {
    setCurrentPage(page);
  };

  const handlerGradeCardClick = (grade: string) => {
    dispatch(personalDetail({ ...profile, grade }));
  };

  const isBoardNameSelected = (_grade: string) => {
    if (grade === String(_grade).toLowerCase()) {
      return true;
    }
    return false;
  };

  return (
    <div className="schoolDetails">
      <StepsBar currentStep={4} />
      <StepBox>
        <GoBackArrowIos
          handlerBackButton={studentNaviage.navigateToLanguageDetails}
          sx={{ ml: 2, mt: 2, mb: 1 }}
        />
        <Typography
          align="left"
          sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
          variant="h5"
        >
          For which grade are you preparing?
        </Typography>
        <Grow in={boardNames.length > 0}>
          <Grid2
            container
            spacing={{ xs: 2, md: 2.5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ m: 3, mb: 21.75 }}
          >
            {boardNames.map((item) => {
              return (
                <Grid2 size={{ md: 3 }} sx={{ mt: 1.5 }}>
                  <BoardCard
                    variant="outlined"
                    onClick={() => handlerGradeCardClick(item)}
                    style={
                      isBoardNameSelected(item)
                        ? { border: "1px solid #FD9B18" }
                        : {}
                    }
                  >
                    <CardContent
                      sx={{
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <GradeChip label={item} sx={{ mt: 1 }} />
                    </CardContent>
                  </BoardCard>
                </Grid2>
              );
            })}
          </Grid2>
        </Grow>
        {listOfGradeNumbers.length > 8 && (
          <Pagination
            count={paginationCount}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
                color="primary"
                shape="circular"
                selected={false}
              />
            )}
            onChange={handlerPageChange}
          />
        )}
      </StepBox>
      <Stack direction={"row"} gap={1} sx={{ mt: 4.5 }} alignSelf={"flex-end"}>
        <PsButton.Outlined
          variant="outlined"
          sx={{ width: 120 }}
          onClick={studentNaviage.navigateToDashboard}
        >
          Cancel
        </PsButton.Outlined>
        <PsButton.Primary
          variant="contained"
          sx={{ width: 240 }}
          onClick={studentNaviage.navigateToExaminationDate}
        >
          Proceed
        </PsButton.Primary>
      </Stack>
    </div>
  );
};
export default GradeDetails;
