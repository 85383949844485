// components/ImagePreviewModal.tsx
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  Fade,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    maxWidth: "90vw",
    maxHeight: "90vh",
    overflow: "hidden",
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

interface Region {
  id: number;
  image: string;
  timestamp: string;
  pageNumber: number;
  dimensions: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  description: string;
}

interface Question {
  id: number;
  questionNumber: string;
  totalMarks: number;
  inputMarks: number;
  regions: Region[];
}

interface Section {
  id: number;
  title: string;
  isExpanded: boolean;
  questions: Question[];
}

const ImageContainer = styled(Paper)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.paper,
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.error.main,
  "&:hover": {
    backgroundColor: theme.palette.error.dark,
  },
  zIndex: 1,
}));

const ImageInfo = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

interface ImagePreviewModalProps {
  selectedImage: Region | null;
  setSelectedImage: (image: Region | null) => void;
}

export const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({
  selectedImage,
  setSelectedImage,
}) => {
  const handleClose = () => {
    setSelectedImage(null); 
    
  }

  return (
    <StyledDialog
      open={!!selectedImage}
      onClose={handleClose}
      maxWidth={false}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      onClick={(e) => e.stopPropagation()}
    >
      {selectedImage && (
        <ImageContainer elevation={0}>
          <CloseButton size="small" onClick={handleClose} aria-label="close">
            <CloseIcon fontSize="small" />
          </CloseButton>  

          <DialogContent sx={{ p: 0 }}>
            <Box
              component="img"
              src={selectedImage.image}
              alt={`Screenshot ${selectedImage.timestamp}`}
              sx={{
                display: "block",
                maxWidth: "100%",
                maxHeight: "calc(90vh - 100px)",
                objectFit: "contain",
              }}
            />

            <ImageInfo>
              <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
                Page {selectedImage.pageNumber}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {selectedImage.timestamp}
              </Typography>
            </ImageInfo>
          </DialogContent>
        </ImageContainer>
      )}
    </StyledDialog>
  );
};
