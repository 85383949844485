import { Box } from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { CardBox } from "../../../../lib/psElements/psElements";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";
import ApexChart from "./Chart";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: `${darkTheme.colors.teacher.Dashboard.paperHistory.whitebackgroundColor}`,
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: `${darkTheme.colors.teacher.Dashboard.paperHistory.backgroundColor}`,
  }),
}));

const PaperCorrectionHistory = () => {
  const data = [
    {
      title: "In last week",
      background: `${darkTheme.colors.teacher.Dashboard.paperHistory.linear1}`,
      details: {
        "Number of papers": "10",
        "Time Spent": "3 Hrs",
        Income: "Rs. 2000",
      },
    },
    {
      title: "In last month",
      background: `${darkTheme.colors.teacher.Dashboard.paperHistory.linear2}`,
      details: {
        "Number of papers": "40",
        "Time Spent": "12 Hrs",
        Income: "Rs. 8000",
      },
    },
    {
      title: "In this academic year",
      background: `${darkTheme.colors.teacher.Dashboard.paperHistory.linear3}`,
      details: {
        "Number of papers": "200",
        "Time Spent": "60 Hrs",
        Income: "Rs. 50000",
      },
    },
    {
      title: "Forecast (AY:2024-25)",
      background: `${darkTheme.colors.teacher.Dashboard.paperHistory.linear4}`,
      details: {
        "Number of papers": "500",
        "Time Spent": "150 Hrs",
        Income: "Rs. 120000",
        "Annualized regular income": "Rs. 550000",
      },
    },
  ];
  return (
    <Box sx={{ p: 2, width: "100%", mt: 5 }}>
      <CardBox>
        <Typography sx={{ fontWeight: "bold", fontSize: 30, mb: 5 }}>
          Paper Correction History
        </Typography>

        <Box sx={{ flexGrow: 1, mt: "30px", mb: "30px" }}>
          <Grid2 container spacing={1}>
            {data.map((item, index) => (
              <Grid2
                key={index}
                component="div"
                size={{ xs: 12, sm: 10, md: 6, xl: 3 }}
              >
                <Item
                  sx={{
                    height: "200px",
                    backgroundImage: item.background,
                    color: `${darkTheme.colors.teacher.Dashboard.paperHistory.whitebackgroundColor}`,
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                      fontWeight: "bold",
                      color: `${darkTheme.colors.teacher.Dashboard.paperHistory.whitebackgroundColor}`,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "2px",
                      backgroundColor: `${darkTheme.colors.teacher.Dashboard.paperHistory.whitebackgroundColor}`,
                      marginY: 2,
                    }}
                  />
                  {Object.entries(item.details).map(([key, value]) => (
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        textWrap: "nowrap",
                        mt: 1,
                      }}
                    >
                      <Typography>{key}</Typography>
                      <Typography>{value}</Typography>
                    </Box>
                  ))}
                </Item>
              </Grid2>
            ))}
          </Grid2>
        </Box>
      </CardBox>
      <CardBox>
        <ApexChart />
      </CardBox>
    </Box>
  );
};
export default PaperCorrectionHistory;
