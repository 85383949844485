import {
  Box,
  Card,
  Checkbox,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import _, { forEach } from "lodash";
import * as React from "react";
import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  PsButton,
  PsInput,
  PsSwitchButton,
} from "../../../../../lib/psElements/psElements";
import { useStudentTestAPICalls } from "../../../../../services/api/student/studentTestAPIMthods";
import { useStudentNaviagation } from "../../../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../../../services/storage/store";
import { setDataToGenerateTestPaper } from "../../../../../services/storage/student/test";
import validation from "../../../../../services/validation";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import { GoBackArrowIos } from "../../../../common/GoBackButton/GoBackButton";
import { Difficulty } from "./Difficulty";
import { Length } from "./Length";
import { PastBoardInclusion } from "./PastBoardInclusion";
import { Subject } from "./Subject";

interface ChapterItemType {
  value: string;
  label: string;
  selected: boolean;
}

interface initValuesType {
  testName: string;
  subject: string;
  difficulty: string;
  length: string;
  includePastBoardPapers: string;
  focusOnPreviousMistakes: boolean;
  viewSelected: boolean;
  selectedChapters: ChapterItemType | never[];
}

const initailValueConst: initValuesType = {
  testName: "",
  subject: "",
  difficulty: "",
  length: "",
  includePastBoardPapers: "",
  focusOnPreviousMistakes: false,
  viewSelected: false,
  selectedChapters: [],
};

const GenerateTestPaper: FunctionComponent = () => {
  const [chapters, setChapters] = useState<ChapterItemType[]>([]);
  const [tempState, setTempState] = useState(initailValueConst);
  const [selectedChaptersCount, setSelectedChaptersCount] = useState(0);
  const {
    studentTest,
    studentProfile: { profile },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const studentNavigation = useStudentNaviagation();
  const { getSubjectList, getChaptersList, generateTestPaper } =
    useStudentTestAPICalls();

  useEffect(() => {
    updateTheSelectedChaptersCount();
  }, [chapters]);

  useEffect(() => {
    const { testPaper, subjectList, chaptersList } = studentTest;
    // alert(JSON.stringify(_.concat(chapters, testPaper.selectedChapters), null, 2));
    setTempState({ ...testPaper });
    mergeSelectedChapterToShowSelected();
    getSubjectList();

    // setChapters(_.concat(chapters, testPaper.selectedChapters);
  }, []);

  useEffect(() => {
    const chapterList: ChapterItemType[] = [];
    _.each(studentTest.chaptersList, (item) => {
      chapterList.push({
        value: item,
        label: item,
        selected: false,
      });
    });
    setChapters([...chapterList]);
  }, [studentTest.chaptersList]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: tempState,
    onSubmit: (values: initValuesType) => {
      const {
        testName,
        subject,
        difficulty,
        length,
        includePastBoardPapers,
        focusOnPreviousMistakes,
        viewSelected,
        selectedChapters,
      } = values;
      dispatch(
        setDataToGenerateTestPaper({
          testName,
          subject,
          difficulty,
          length,
          includePastBoardPapers,
          focusOnPreviousMistakes,
          viewSelected,
          selectedChapters,
        }),
      );
      const chapterList = _.map(
        selectedChapters,
        (item: ChapterItemType) => item.value,
      );
      generateTestPaper({
        chapters: chapterList,
        difficulty: difficulty,
        length: length,
        subject: subject,
        board: profile.board,
        grade: profile.grade,
        focusOnPreviousMistakes: focusOnPreviousMistakes,
        pastPaperInclusion: includePastBoardPapers,
      });
    },
    validationSchema: Yup.object({
      testName: validation.schema.studentTestGeneration.testName,
      subject: validation.schema.studentTestGeneration.subject,
      difficulty: validation.schema.studentTestGeneration.difficulty,
      length: validation.schema.studentTestGeneration.length,
      includePastBoardPapers:
        validation.schema.studentTestGeneration.includePastBoardPapers,
    }),
  });

  const mergeSelectedChapterToShowSelected = () => {
    let chapterListWithSelection: ChapterItemType[] = [];
    const { testPaper } = studentTest;
    _.forEach(chapters, (item: ChapterItemType) => {
      _.forEach(testPaper.selectedChapters, (item1: ChapterItemType) => {
        if (
          String(item.value).toLowerCase() === String(item1.value).toLowerCase()
        ) {
          item.selected = true;
        }
      });
      chapterListWithSelection.push({ ...item });
    });
    setChapters([...chapterListWithSelection]);
  };

  const handlerSubjectClick = (val: string) => {
    formik.setValues({ ...formik.values, subject: val });
    getChaptersList({ subjectName: val, groupName: "Dummy" });
  };
  const handlerDifficultyClick = (val: string) => {
    formik.setValues({ ...formik.values, difficulty: val });
  };
  const handlerLengthClick = (val: string) => {
    formik.setValues({ ...formik.values, length: val });
  };
  const handlerIncludePastBoardPapersClick = (val: string) => {
    formik.setValues({ ...formik.values, includePastBoardPapers: val });
  };

  const handlerSelectChapter = (
    e: any,
    checked: boolean,
    item: any,
    index: number,
  ): void => {
    const temp = [...chapters];
    temp[index] = { ...temp[index], selected: checked };
    setChapters(temp);
  };

  const handlerSelectUnSelectAllChapters = (
    e: SyntheticEvent<Element, Event>,
    checked: boolean,
  ): void => {
    const temp = [...chapters].map((item) => {
      return { ...item, selected: checked };
      // item.selected = checked;
      // return item;
    });
    setChapters(temp);
  };

  // let handlerSwitchViewSelected: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean)  => void;
  const handlerSwitchViewSelected = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean,
  ): void => {
    console.log(checked);
    dispatch(setDataToGenerateTestPaper({ viewSelected: checked }));
  };

  const updateTheSelectedChaptersCount = () => {
    let counter = 0;
    let newSelectedChaptersList: any = [];
    forEach(chapters, (item: ChapterItemType): void => {
      if (item.selected) {
        counter += 1;
        newSelectedChaptersList.push(item);
      }
    });
    setSelectedChaptersCount(counter);
    formik.setValues({
      ...formik.values,
      selectedChapters: newSelectedChaptersList,
    });
  };

  const handlerViewSelectedOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    formik.setValues({ ...formik.values, viewSelected: checked });
  };

  const handlerFocusOnPreviousMistakesOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    formik.setValues({ ...formik.values, focusOnPreviousMistakes: checked });
  };
  return (
    <Fade in={true}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            mt: 4,
            ml: 4,
            mr: 3,
            mb: 5,
            width: 1184,
            // backgroundColor: darkTheme.colors.surface.five,
          }}
        >
          <GoBackArrowIos
            handlerBackButton={studentNavigation.navigateToDashboard}
          />
          <Typography variant="h2" sx={{ width: 680, mb: 1.5 }}>
            Setup your test paper
          </Typography>
          <Typography variant="subtitle1" sx={{ width: 680, mb: 4 }}>
            Our engine will churn our most suitable questions based on your
            preferences from our vast database of 10,000 question bank
          </Typography>
          <Box
            sx={{
              backgroundColor: darkTheme.colors.surface.one,
              p: 3,
              borderRadius: 2,
            }}
          >
            <PsInput
              label="Test Name"
              sx={{ width: 484, height: 54 }}
              {...formik.getFieldProps("testName")}
              error={!!formik.errors.testName}
              helperText={
                formik.touched.testName && formik.errors.testName
                  ? formik.errors.testName
                  : ""
              }
            />
            <Subject formik={formik} clickHander={handlerSubjectClick} />
            <Difficulty formik={formik} clickHander={handlerDifficultyClick} />
            <Length formik={formik} clickHander={handlerLengthClick} />
            <PastBoardInclusion
              formik={formik}
              clickHander={handlerIncludePastBoardPapersClick}
            />
            <FormGroup sx={{ mt: 3.5 }}>
              <FormControlLabel
                control={
                  <PsSwitchButton
                    onChange={handlerFocusOnPreviousMistakesOnChange}
                  />
                }
                label="Focus on previous mistakes"
              />
            </FormGroup>
          </Box>
          <Box
            sx={{
              backgroundColor: darkTheme.colors.surface.one,
              p: 3,
              borderRadius: 2,
              mt: 2.375,
            }}
          >
            <Typography variant="h6">Chapters</Typography>
            <FormGroup sx={{ mt: 3.2, ml: 0 }} row>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 1 }}
                    checked={selectedChaptersCount === chapters.length}
                  />
                }
                label={`Selected (${selectedChaptersCount}/${chapters.length})`}
                labelPlacement="end"
                onChange={handlerSelectUnSelectAllChapters}
              />
              <FormControlLabel
                control={
                  <PsSwitchButton onChange={handlerViewSelectedOnChange} />
                }
                label="View Selected"
                labelPlacement="end"
                sx={{ ml: 3 }}
                onChange={handlerSwitchViewSelected}
              />
            </FormGroup>
            <Grid2 container spacing={3} sx={{ mt: 2 }}>
              {chapters.map((item: ChapterItemType, index: number) => {
                if (formik.values.viewSelected && item.selected === false)
                  return <></>;
                else
                  return (
                    <Grid2 size={3} key={_.uniqueId()}>
                      <Card
                        sx={{
                          width: 266,
                          height: 40,
                          alignItems: "center",
                          textAlign: "center",
                          borderRadius: 1,
                          backgroundColor: darkTheme.colors.surface.six,
                          display: "flex",
                        }}
                      >
                        <Stack
                          direction="row"
                          gap={1}
                          sx={{ ml: 2, mt: 1.25, mb: 1.25, mr: 1.25 }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(e, checked) =>
                                  handlerSelectChapter(e, checked, item, index)
                                }
                                checked={item.selected}
                              />
                            }
                            label={item.label}
                            labelPlacement="end"
                          />
                        </Stack>
                      </Card>
                    </Grid2>
                  );
              })}
            </Grid2>
          </Box>
          <Box
            sx={{
              backgroundColor: darkTheme.colors.surface.one,
              mt: 5,
              p: 2.25,
              pr: 3,
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: "flex-end" }}
            >
              <PsButton.CustomTheme
                variant="outlined"
                onClick={studentNavigation.navigateToDashboard}
              >
                Cancel
              </PsButton.CustomTheme>
              <PsButton.Custom variant="outlined" type="submit">
                Generate my test paper
              </PsButton.Custom>
            </Stack>
          </Box>
        </Box>
      </form>
    </Fade>
  );
};

export default GenerateTestPaper;
