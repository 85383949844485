import { Autocomplete, Grid2, Grow, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { PsButton, PsInput, StepBox } from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/student/studentProfileAPIMethods";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { RootState } from "../../../services/storage/store";
import { schoolDetails } from "../../../services/storage/student/profile";
import validation from "../../../services/validation";
import { GoBackArrowIos } from "../../common/GoBackButton/GoBackButton";
import StepsBar from "./StepsBar";

const SchoolDetails: FunctionComponent = () => {
  const {
    getSchoolList,
    getSchoolBranchList,
    getCoachingClassList,
    getCoachingClassBranchList,
  } = useStudentProfileAPICalls();
  const studentNavigation = useStudentNaviagation();
  const {
    listOfSchoolName,
    profile,
    listOfSchoolBranch,
    listOfCoachingClassName,
    listOfCoachingClassBranch,
  } = useSelector((state: RootState) => state.studentProfile);

  const tempState = {
    schoolName: "",
    schoolBranch: "",
    className: "",
    classBranch: "",
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: tempState,
    onSubmit: (values) => {
      const { schoolName, schoolBranch, className, classBranch } = values;
      dispatch(
        schoolDetails({
          ...profile,
          school: { ...profile.school, name: schoolName, branch: schoolBranch },
          class: { ...profile.class, name: className, branch: classBranch },
        }),
      );
      studentNavigation.navigateToBoardDetails();
    },
    validationSchema: Yup.object({
      schoolName: validation.schema.schoolName,
    }),
  });

  useEffect(() => {
    getSchoolList();
    getCoachingClassList();
  }, []);

  useEffect(() => {
    resetTheValuesFromReducerState();
  }, [profile.school]);

  const resetTheValuesFromReducerState = () => {
    formik.setValues({
      ...formik.values,
      schoolName: profile.school.name,
      schoolBranch: profile.school.branch,
      className: profile.class.name,
      classBranch: profile.class.branch,
    });
  };

  useEffect(() => {
    getSchoolBranchList(formik.values.schoolName);
  }, [formik.values.schoolName]);

  useEffect(() => {
    getCoachingClassBranchList(formik.values.className);
  }, [formik.values.className]);

  const handlerSchoolListOnChange = (e: any) => {
    const schoolName = e.target.innerText;
    formik.setValues({ ...formik.values, schoolName });

    dispatch(
      schoolDetails({
        ...profile,
        school: { ...profile.school, name: schoolName },
      }),
    );
  };

  const handlerSchoolBranchOnChange = (e: any) => {
    const schoolBranch = e.target.innerText;
    formik.setValues({ ...formik.values, schoolBranch });
    dispatch(
      schoolDetails({
        ...profile,
        school: { ...profile.school, branch: schoolBranch },
      }),
    );
  };

  const handlerCoachingClassListOnChange = (e: any) => {
    const className = e.target.innerText;
    formik.setValues({ ...formik.values, className });

    dispatch(
      schoolDetails({
        ...profile,
        class: { ...profile.class, name: className },
      }),
    );
  };

  const handlerCoachingClassBranchOnChange = (e: any) => {
    const classBranch = e.target.innerText;
    formik.setValues({ ...formik.values, classBranch });
    dispatch(
      schoolDetails({
        ...profile,
        class: { ...profile.class, branch: classBranch },
      }),
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="schoolDetails">
        <StepsBar currentStep={1} />
        <StepBox>
          <GoBackArrowIos
            handlerBackButton={studentNavigation.navigateToStep1PersonalDetails}
            sx={{ ml: 2, mt: 2, mb: 1 }}
          />
          <Typography
            align="left"
            sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
            variant="h5"
          >
            School
          </Typography>
          <Grow in={true}>
            <Grid2
              container
              spacing={{ xs: 2, md: 2.5 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ m: 3, mb: 21.75 }}
            >
              <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  options={listOfSchoolName}
                  onChange={(e) => handlerSchoolListOnChange(e)}
                  value={formik.values.schoolName}
                  renderInput={(params) => (
                    <PsInput
                      {...params}
                      error={!!formik.errors.schoolName}
                      // {...formik.getFieldProps("schoolName")}
                      label="School"
                      helperText={
                        formik.touched.schoolName && formik.errors.schoolName
                          ? formik.errors.schoolName
                          : ""
                      }
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  options={listOfSchoolBranch}
                  onChange={(e) => handlerSchoolBranchOnChange(e)}
                  value={formik.values.schoolBranch}
                  renderInput={(params) => (
                    <PsInput
                      {...params}
                      error={!!formik.errors.schoolBranch}
                      {...formik.getFieldProps("schoolBranch")}
                      label="School Branch"
                      helperText={
                        formik.touched.schoolBranch &&
                        formik.errors.schoolBranch
                          ? formik.errors.schoolBranch
                          : ""
                      }
                    />
                  )}
                />
              </Grid2>

              <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  options={listOfCoachingClassName}
                  onChange={(e) => handlerCoachingClassListOnChange(e)}
                  value={formik.values.className}
                  renderInput={(params) => (
                    <PsInput
                      {...params}
                      error={!!formik.errors.className}
                      {...formik.getFieldProps("className")}
                      label="Attended any tuition or coaching classes"
                      helperText={
                        formik.touched.className && formik.errors.className
                          ? formik.errors.className
                          : ""
                      }
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ md: 12 }} sx={{ mt: 1.5 }}>
                <Autocomplete
                  disablePortal
                  options={listOfCoachingClassBranch}
                  onChange={(e) => handlerCoachingClassBranchOnChange(e)}
                  value={formik.values.classBranch}
                  renderInput={(params) => (
                    <PsInput
                      {...params}
                      error={!!formik.errors.classBranch}
                      {...formik.getFieldProps("classBranch")}
                      label="Coaching Classs Branch"
                      helperText={
                        formik.touched.classBranch && formik.errors.classBranch
                          ? formik.errors.classBranch
                          : ""
                      }
                    />
                  )}
                />
              </Grid2>
            </Grid2>
          </Grow>
        </StepBox>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ mt: 4.5 }}
          alignSelf={"flex-end"}
        >
          <PsButton.Outlined
            variant="outlined"
            sx={{ width: 120 }}
            onClick={studentNavigation.navigateToDashboard}
          >
            Cancel
          </PsButton.Outlined>
          <PsButton.Primary
            variant="contained"
            sx={{ width: 240 }}
            type="submit"
          >
            Proceed
          </PsButton.Primary>
        </Stack>
      </div>
    </form>
  );
};
export default SchoolDetails;
