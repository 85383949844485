import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resolveSoa } from "dns";




const ApiSlice = createSlice({
  name: "ApiData",
  initialState: {
    data: [],
    loading: false,
    error: false
  },
  reducers: {},
 
});

export default ApiSlice.reducer;
