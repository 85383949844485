import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBox, PsButton } from "../../../../lib/psElements/psElements";
// import { fetchtask } from '../../../../services/api/ApiStore';
import { TeacherAPIcalls } from "../../../../services/api/teacher/ProfileAPI";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
import { AppDispatch, RootState } from "../../../../services/storage/store";
import {
  setpdfIDs,
  settestID,
} from "../../../../services/storage/teacher/TProfile";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";

export default function EnhancedTable() {
  const [activeTab, setActiveTab] = React.useState<
    "All" | "Pending Evaluation"
  >("All");
  const teachernavigation = useTeacherNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const { Taskdata } = useSelector(
    (state: RootState) => state.TeacherprofileData,
  );
  const { fetchtask } = TeacherAPIcalls();

  React.useEffect(() => {
    fetchtask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickevolutionnow = (id: number, testId: any) => {
    dispatch(setpdfIDs(id));
    dispatch(settestID(testId));
    console.log(testId, "testId");
    teachernavigation.navigationEvalutionNow(id);
  };
  const tabs: {
    id: "All" | "Pending Evaluation";
    label: string;
    activeColor: string;
  }[] = [
    {
      id: "All",
      label: "All",
      activeColor: `${darkTheme.colors.teacher.Dashboard.charts.activecolor}`,
    },
    {
      id: "Pending Evaluation",
      label: "Pending Evaluation",
      activeColor: `${darkTheme.colors.teacher.Dashboard.charts.activecolor}`,
    },
  ];

  const formatDateTime = (isoString: any) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <CardBox sx={{ marginInline: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mt: "0px", fontSize: "30px", px: "24px" }}>
            Tasks
          </Typography>
          <Box sx={{ display: "flex", gap: "8px", px: "24px" }}>
            {tabs.map((tab) => (
              <PsButton.CustomChartButton
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                sx={{
                  color:
                    activeTab === tab.id
                      ? tab.activeColor
                      : `${darkTheme.colors.teacher.Dashboard.task.white}`,
                  borderColor:
                    activeTab === tab.id
                      ? tab.activeColor
                      : `${darkTheme.colors.teacher.Dashboard.charts.tabactiveColor}`,
                }}
              >
                {tab.label}
              </PsButton.CustomChartButton>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "100%", px: "17px", mt: "10px" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: `${darkTheme.colors.teacher.Dashboard.task.backgroundColor}`,
                      borderRadius: "10px",
                      padding: "20px",
                      margin: "30px",
                    }}
                  >
                    <TableCell align="center">S.No.</TableCell>
                    <TableCell align="center">Paper ID</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Grade</TableCell>
                    <TableCell align="center">Subject</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Taskdata.map((data: any, index: any) => (
                    <TableRow
                      hover
                      key={data.testId}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{data.testId}</TableCell>
                      <TableCell align="center">
                        {formatDateTime(data.createdDate)}
                      </TableCell>
                      <TableCell align="center">{data.grade}</TableCell>
                      <TableCell align="center">{data.subject}</TableCell>
                      <TableCell align="center">
                        <PsButton.Outlined
                          sx={{
                            color: `${darkTheme.colors.teacher.theme.purpule}`,
                            width: "160px",
                            height: "40px",
                            textWrap: "nowrap",
                            border: `2px solid ${darkTheme.colors.teacher.theme.purpule}`,
                          }}
                          onClick={() => {
                            handleClickevolutionnow(data.testId, data.testId);
                          }}
                        >
                          Evaluation now
                        </PsButton.Outlined>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </CardBox>
    </>
  );
}
