import { useEffect, useState } from "react";
import { setTime } from "../../../services/storage/teacher/TProfile";
import { useDispatch } from "react-redux";

//This is function for the pdf timeer
export const CorrectionTime = () => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => {
        if (prev === 59) {
          setMinutes((prevMinutes) => prevMinutes + 1);
          return 0;
        }
        return prev + 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      const formattedTime = `${formatTime(minutes)}:${formatTime(seconds)}`;
      dispatch(setTime(formattedTime));
    };
  }, [seconds, minutes]);

  const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

  return {
    seconds: formatTime(seconds),
    minutes: formatTime(minutes),
  };
};
