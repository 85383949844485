import moment from "moment";
import * as Yup from "yup";
import regExp from "../../constants/regExpressions";
import { message } from "./constant";

const email = Yup.string()
  .max(150, message.maxChar150)
  .min(8, message.minChar8)
  .email(message.email.invalid)
  .matches(regExp.email, message.email.invalid)
  .required(message.email.require);

const password = Yup.string()
  .min(8, message.minChar8)
  .max(50, message.maxChar50)
  .required(message.password.require);

const firstName = Yup.string()
  .min(2, message.minChar2)
  .max(100, message.maxChar100)
  .required(message.firstName.require);

const lastName = Yup.string()
  .min(2, message.minChar2)
  .max(50, message.maxChar50)
  .required(message.lastName.require);

const registerPassword = Yup.string()
  .min(8, message.minChar8)
  .max(50, message.maxChar50)
  .required(message.password.require)
  .matches(regExp.password, message.password.passwordPattern);

const mobile = Yup.number()
  .typeError(message.mobile.typeError)
  .positive(message.mobile.positive)
  .min(1000000000, message.mobile.min10)
  .max(9999999999, message.mobile.min10)
  .required(message.mobile.require);

const city = Yup.string()
  .min(2, message.minChar2)
  .max(100, message.maxChar100)
  .required(message.city.require);

const state = Yup.string()
  .min(2, message.minChar2)
  .max(100, message.maxChar100)
  .required(message.state.require);

const birthDateMin6Year = Yup.date()
  .max(
    moment().subtract(6, "years").format("YYYY-MM-DD"),
    message.birthdate.min6YearOld,
  )
  .min(
    moment().subtract(100, "years").format("YYYY-MM-DD"),
    message.birthdate.max100YearOld,
  )
  .required(message.birthdate.require);

const schoolName = Yup.string()
  .min(2, message.minChar2)
  .max(250, message.maxChar250)
  .required(message.schoolName.require);

const studentTestGeneration = {
  testName: Yup.string()
    .min(4, message.minChar4)
    .max(100, message.maxChar100)
    .required(message.testName.require)
    .matches(
      regExp.alphanumericunderscore,
      message.testName.alphaNumbericUnderscore,
    ),
  subject: Yup.string().required(message.subject.require),
  difficulty: Yup.string().required(message.difficulty.require),
  length: Yup.string().required(message.length.require),
  includePastBoardPapers: Yup.string().required(
    message.includePastBoardPapers.require,
  ),
};
export {
  birthDateMin6Year,
  city,
  email,
  firstName,
  lastName,
  mobile,
  password,
  registerPassword,
  schoolName,
  state,
  studentTestGeneration,
};
