import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import IconDifficultHigh from "../../../../assets/svg/student/high.svg";
import IconDifficultLow from "../../../../assets/svg/student/low.svg";
import IconDifficultMedium from "../../../../assets/svg/student/medium.svg";
import { CatCards } from "./CatCards";

const difficultyList = [
  {
    icon: IconDifficultLow,
    text: "Low",
  },
  {
    icon: IconDifficultMedium,
    text: "Medium",
  },
  {
    icon: IconDifficultHigh,
    text: "High",
  },
];

interface DifficultyType {
  clickHander: (val: string) => void;
  formik: any;
}

const Difficulty: FunctionComponent<DifficultyType> = (
  props: DifficultyType,
) => {
  const { formik } = props;
  const handlerSetSelected = (val: string) => {
    return (
      String(formik.values.difficulty).toLowerCase() ===
      String(val).toLowerCase()
    );
  };
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6">Difficulty</Typography>
      <Stack direction="row" sx={{ mt: 2, gap: 4 }}>
        {difficultyList.map((item) => (
          <CatCards
            icon={item.icon}
            text={item.text}
            clickHander={props.clickHander}
            selected={handlerSetSelected(item.text)}
          />
        ))}
      </Stack>
      {formik.errors.difficulty && (
        <FormHelperText error>
          {formik.touched.difficulty && formik.errors.difficulty
            ? formik.errors.difficulty
            : ""}
        </FormHelperText>
      )}
    </Box>
  );
};

export { Difficulty };
