import axios from "axios";
import { ApiJson } from "./apiJSON";
// import ApiJson from "./apiConfig";
// import ApiJson from "./apiConfig";
import _ from "lodash";
import { toast } from "react-toastify";
import AppConfig from "../appConfig";
import {
  getLocalStorage,
  showErrorToast,
  showSuccessToast,
} from "../commonFunctions";

let apiFailCounter = 0;

//Set default URL of API
axios.defaults.baseURL = AppConfig.API_URL_JAVA;
console.log("API url", AppConfig.API_URL_JAVA);

axios.interceptors.request.use(
  function (config: any) {
    console.log("config", config);
    console.log(
      "_.includes(config.url, s3.amazonaws.com)",
      _.includes(config.url, "s3.amazonaws.com"),
    );
    if (!_.includes(config.url, "s3.amazonaws.com")) {
      let userInfo: any = getLocalStorage("loggedInUser");
      // console.log('api service userinfo', userInfo);
      if (userInfo) {
        if (userInfo?.tokenValue) {
          let authorization = userInfo?.tokenValue;
          config.headers.Authorization = "Bearer " + authorization;
        }
      }
    }
    config.headers["Content-Type"] = "application/json";
    config.headers.Accept = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    // debugger;
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  },
);

const prepareDataObject = (_data_: any, paramObj: any) => {
  for (let key in _data_) {
    if (paramObj[key] || paramObj[key] === false) {
      _data_[key] = paramObj[key];
    } else {
      if (typeof _data_[key] !== "object") _data_[key] = "";
    }
  }
  return _data_;
};

const injectParamsToUrl = (_url_: any, paramObj: any) => {
  var url = _url_;
  for (let key in paramObj) {
    url = url.replace(":" + key, paramObj[key]);
  }
  return url;
};

// const clearStorageAndReload

const handleErrorByStatus = (error: any, autoCloseFlag: any) => {
  console.log("handle error ", error);
  // const message = error.message;
  //       showErrorToast(message);
  let message = "";
  try {
    message = error.message;
  } catch (e) {
    try {
      message = error.data.message;
    } catch (e1) {}
  }
  switch (error.status) {
    case 403:
      try {
        toast.dismiss();
        showErrorToast(message, autoCloseFlag);
        localStorage.clear();
        setTimeout(() => {
          // window.location.reload();
          window.open(window.location.origin, "_self");
        }, 500);
      } catch (error) {}
      break;
    // case 400:
    //   try {
    //     const message = error.message;
    //     showErrorToast(message);
    //   } catch (error) {}
    //   try {
    //     const message = error.message;
    //     showErrorToast(message);
    //   } catch (error) {}
    //   break;

    case 500:
      try {
        showErrorToast(message, autoCloseFlag);
      } catch (error) {}
      break;

    default:
      showErrorToast(message, autoCloseFlag);
      break;
  }
};

const downloadTheFile = (apiKeyName: any, data: any) => {
  console.log("download the file ", apiKeyName, data);
  // Code to fetch authorization token
  let userInfo: any = getLocalStorage("userInfo");
  // console.log('api service userinfo', userInfo);
  if (userInfo) {
    if (userInfo?.authorization) {
      // data.authorization = userInfo?.authorization;
    }
  }

  let token = btoa(JSON.stringify(data));
  let apiDetails = ApiJson[apiKeyName];
  let requestObject = Object.assign({}, apiDetails);
  // if (typeof data === 'object' && data.length && data.length >= 0) {
  //   requestObject.data = data;
  // } else {
  //   requestObject.data = prepareDataObject(requestObject.data, data);
  // }

  requestObject.url = injectParamsToUrl(requestObject.url, { token });
  window.open(AppConfig.API_URL_JAVA + requestObject.url);
  console.log(token, requestObject);
};

const parseJsonByKeyValue = (ApiJson: object, apiKeyName: any) => {
  let keyNameArray = apiKeyName.split(".");
  let apiDetailObject: any = { ...ApiJson };
  if (keyNameArray.length > 0) {
    keyNameArray.forEach((key: string) => {
      apiDetailObject = apiDetailObject[key];
    });
  }
  return apiDetailObject;
};

const psApiService = (
  apiKeyName: string | number,
  data: any,
  downloadFlag: boolean = false,
) => {
  console.log(apiKeyName, data, downloadFlag);
  //If download flag set then go to download the file function to skipp rest of statement
  if (downloadFlag) downloadTheFile(apiKeyName, data);
  // let apiDetails = ApiJson[apiKeyName];

  let apiDetails = parseJsonByKeyValue(ApiJson, apiKeyName);
  console.log("apiDetails", apiDetails);
  let autoCloseFlag = true;
  if (apiDetails && apiDetails.autoClose && apiDetails.autoClose === false) {
    autoCloseFlag = false;
  }

  if (!apiDetails) {
    console.log(
      "Api configuration do not found in api-json, please check api-json.js",
    );
    throw new Error(
      "Api configuration do not found in api-json, please check api-json.js",
    );
  }

  let requestObject = Object.assign({}, apiDetails);
  if (typeof data === "object" && data.length && data.length >= 0) {
    requestObject.data = data;
  } else {
    requestObject.data = prepareDataObject(requestObject.data, data);
  }

  requestObject.url = injectParamsToUrl(requestObject.url, data);

  // try {
  return axios(requestObject)
    .then(function (result) {
      console.log("axios result", result);

      apiFailCounter = 0;
      if (result?.data && result?.data.status === 200) {
        if (result?.data?.message) {
          const message = result.data.message;
          if (requestObject.showResultMessage === true)
            showSuccessToast(message);
        }
      } else {
        handleErrorByStatus(result?.data?.status, autoCloseFlag);
      }
      // debugger;
      return new Promise((resolve, reject) => {
        resolve(result);
      });
    })
    .catch(function (error) {
      console.log("error", JSON.stringify(error));
      if (error) {
        // if (requestObject.showErrorMessage === true);
        handleErrorByStatus(error, autoCloseFlag);
      }

      // if (
      //   error.config.maxContentLength - 1 &&
      //   error.toString().indexOf('Network Error') > -1
      // ) {
      //   apiFailCounter++;
      //   if (apiFailCounter >= 3) {
      //     localStorage.clear();
      //     window.open(window.location.origin, '_self');
      //   }
      // }
      return error.response;
    });
  // } catch (error) {
  //   console.log("API call try catch error ", error);
  // }
};

export default psApiService;
