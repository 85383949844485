import { useNavigate } from "react-router-dom";

export const useStudentNaviagation = () => {
  const navigate = useNavigate();

  const navigateToCongratulations = () => {
    navigate("/Congratulations");
  };

  const navigateToStudentDashboard = () => {
    navigate("/student");
  };

  const navigateToProfileSteps = () => {
    navigate("/register/steps/personaldetails");
  };

  const navigateToSchoolDetails = () => {
    navigate("/register/steps/schooldetails");
  };
  const navigateToDashboard = () => {
    navigate("/student/dashboard");
  };

  const navigateToStep1PersonalDetails = () => {
    navigate("/register/steps/personaldetails");
  };

  const navigateToBoardDetails = () => {
    navigate("/register/steps/boarddetails");
  };

  const navigateToGradeDetails = () => {
    navigate("/register/steps/gradedetails");
  };

  const navigateToLanguageDetails = () => {
    navigate("/register/steps/languagedetails");
  };

  const navigateToExaminationDate = () => {
    navigate("/register/steps/examinationdetails");
  };

  const navigateToLoginSelection = () => {
    navigate("/");
  };

  const navigateToEmailLogin = () => {
    navigate("/authorization/email");
  };
  const navigateToPhoneLogin = () => {
    navigate("/authorization/phone");
  };
  const navigateToTestPage = () => {
    navigate("/student/test");
  };
  const navigateToCreateNewTestPage = () => {
    navigate("/student/new-test");
  };
  return {
    navigateToCongratulations,
    navigateToStudentDashboard,
    navigateToSchoolDetails,
    navigateToProfileSteps,
    navigateToDashboard,
    navigateToStep1PersonalDetails,
    navigateToBoardDetails,
    navigateToGradeDetails,
    navigateToLanguageDetails,
    navigateToExaminationDate,
    navigateToLoginSelection,
    navigateToEmailLogin,
    navigateToPhoneLogin,
    navigateToTestPage,
    navigateToCreateNewTestPage,
  };
};

export const useTeacherNaviagation = () => {
  const navigate = useNavigate();

  const navigateToTeacherDashboard = () => {
    navigate("/teacher/dashboard");
  };

  return { navigateToTeacherDashboard };
};
