import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import {
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { PsCheckbox, PsInput } from "../../../../lib/psElements/psElements";
import InfoIcon from "@mui/icons-material/Info";
import { FormikProvider, FieldArray } from "formik";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";
interface Props {
  formik: any;
  handleSubmit: (region: any) => void;
  handlerInputChange: (region: any) => void;
}

export const ExperienceDetails: React.FC<Props> = ({
  formik,
  handleSubmit,
  handlerInputChange,
}) => {
  const years = Array.from({ length: 12 }, (_, i) => i + 1);
  const month = Array.from({ length: 12 }, (_, i) => i + 1);
  return (
    <div style={{ margin: "0px 0px 10px 0px " }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ fontSize: "20px" }}
        >
          Experience <InfoIcon sx={{ mx: "5px" }} />
        </AccordionSummary>

        <FormikProvider value={formik}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <FieldArray name="experience">
              {({ push, remove }) => (
                <>
                  {(formik.values.experience || []).map(
                    (experience: any, index: number) => (
                      <>
                        <Typography sx={{ mb: "5px", px: "30px" }}>
                          InstitutionName {index + 1}
                        </Typography>
                        <Grid2
                          key={index}
                          sx={{
                            px: 5,
                            display: "grid",
                            gridTemplateColumns: {
                              lg: "repeat(3, 1fr)",
                              md: "repeat(1, 1fr)",
                            },
                            gap: "10px",
                            mb: "20px",
                          }}
                        >
                          <Grid2>
                            <FormControl fullWidth>
                              <PsInput
                                label={`Institution Name ${index + 1}`}
                                name={`experience[${index}].Institutionname`}
                                value={
                                  formik.values.experience[index]
                                    .Institutionname
                                }
                                onChange={handlerInputChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.experience?.[index]
                                    ?.Institutionname &&
                                  Boolean(
                                    formik.errors.experience?.[index]
                                      ?.Institutionname,
                                  )
                                }
                              />
                              <FormHelperText
                                sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                              >
                                {formik.touched.experience?.[index]
                                  ?.Institutionname &&
                                  formik.errors.experience?.[index]
                                    ?.Institutionname}
                              </FormHelperText>
                            </FormControl>
                          </Grid2>
                          <Grid2>
                            <FormControl fullWidth>
                              <PsInput
                                label={`Location ${index + 1}`}
                                name={`experience[${index}].Location`}
                                value={formik.values.experience[index].Location}
                                onChange={handlerInputChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.experience?.[index]
                                    ?.Location &&
                                  Boolean(
                                    formik.errors.experience?.[index]?.Location,
                                  )
                                }
                              />
                              <FormHelperText
                                sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                              >
                                {formik.touched.experience?.[index]?.Location &&
                                  formik.errors.experience?.[index]?.Location}
                              </FormHelperText>
                            </FormControl>
                          </Grid2>
                          <Grid2>
                            <FormControl fullWidth sx={{ display: "flex" }}>
                              <Grid2
                                sx={{
                                  display: "inline-flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <Typography>Duration :</Typography>
                                <Grid2
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <FormControl
                                    fullWidth
                                    error={
                                      formik.touched.experience?.[index]
                                        ?.DurationYear &&
                                      Boolean(
                                        formik.errors.experience?.[index]
                                          ?.DurationYear,
                                      )
                                    }
                                  >
                                    <InputLabel
                                      id={`duration-year-label-${index}`}
                                    >
                                      Year
                                    </InputLabel>
                                    <Select
                                      id="duration-year"
                                      label="Year"
                                      labelId={`duration-year-label-${index}`}
                                      name={`experience[${index}].DurationYear`}
                                      value={
                                        formik.values.experience[index]
                                          .DurationYear
                                      }
                                      onChange={handlerInputChange}
                                      onBlur={formik.handleBlur}
                                      error={
                                        formik.touched.experience?.[index]
                                          ?.DurationYear &&
                                        Boolean(
                                          formik.errors.experience?.[index]
                                            ?.DurationYear,
                                        )
                                      }
                                      sx={{ width: "80px" }}
                                    >
                                      {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                          {year}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <Typography>Yr</Typography>
                                </Grid2>

                                <Grid2
                                  sx={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <FormControl
                                    fullWidth
                                    error={
                                      formik.touched.experience?.[index]
                                        ?.DuartionMonth &&
                                      Boolean(
                                        formik.errors.experience?.[index]
                                          ?.DuartionMonth,
                                      )
                                    }
                                  >
                                    <InputLabel id="year-select-label">
                                      Month
                                    </InputLabel>
                                    <Select
                                      labelId="year-select-label"
                                      id="year-select"
                                      label="Month"
                                      name={`experience[${index}].DuartionMonth`}
                                      sx={{ width: "80px" }}
                                      value={
                                        formik.values.experience[index]
                                          .DuartionMonth
                                      }
                                      onChange={handlerInputChange}
                                      onBlur={formik.handleBlur}
                                      error={
                                        formik.touched.experience?.[index]
                                          ?.DuartionMonth &&
                                        Boolean(
                                          formik.errors.experience?.[index]
                                            ?.DuartionMonth,
                                        )
                                      }
                                    >
                                      {month.map((month) => (
                                        <MenuItem key={month} value={month}>
                                          {month}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <Typography>Month</Typography>
                                </Grid2>
                              </Grid2>
                            </FormControl>
                          </Grid2>
                          <Grid2
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "20px",
                              alignItems: "center",
                            }}
                          >
                            <FormControl
                              fullWidth
                              error={
                                formik.touched.experience?.[index]
                                  ?.ExperienceType &&
                                Boolean(
                                  formik.errors.experience?.[index]
                                    ?.ExperienceType,
                                )
                              }
                            >
                              <InputLabel id={`experience-type-label-${index}`}>
                                Experience Type
                              </InputLabel>
                              <Select
                                label="Experience Type"
                                labelId={`experience-type-label-${index}`}
                                name={`experience[${index}].ExperienceType`}
                                value={
                                  formik.values.experience[index].ExperienceType
                                }
                                onChange={handlerInputChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.experience?.[index]
                                    ?.ExperienceType &&
                                  Boolean(
                                    formik.errors.experience?.[index]
                                      ?.ExperienceType,
                                  )
                                }
                              >
                                <MenuItem value="Full-time">Full-time</MenuItem>
                                <MenuItem value="half-time">half-time</MenuItem>
                              </Select>
                              <FormHelperText
                                sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                              >
                                {formik.touched.experience?.[index]
                                  ?.ExperienceType &&
                                  formik.errors.experience?.[index]
                                    ?.ExperienceType}
                              </FormHelperText>
                            </FormControl>
                          </Grid2>
                          <Grid2>
                            <FormControl fullWidth>
                              <Grid2
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "",
                                  gap: "15px",
                                  mt: "10px",
                                }}
                              >
                                <Typography>Working here</Typography>
                                <PsCheckbox
                                  sx={{
                                    "&.Mui-checked": {
                                      color: `${darkTheme.colors.teacher.theme.yellow}`,
                                    },
                                  }}
                                  name={`experience[${index}].Workinghere`}
                                  checked={
                                    formik.values.experience[index].Workinghere
                                  }
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      `experience[${index}].Workinghere`,
                                      e.target.checked,
                                    );
                                  }}
                                />
                              </Grid2>
                            </FormControl>{" "}
                          </Grid2>
                          <Grid2></Grid2>
                          {index > 0 && (
                            <Grid2>
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => remove(index)}
                              >
                                Remove Experience {index + 1}
                              </Button>
                            </Grid2>
                          )}
                        </Grid2>
                      </>
                    ),
                  )}
                  <Grid2 sx={{ px: 5, margin: "0px 0px 10px 0px" }}>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={() =>
                        push({
                          Institutionname: "",
                          Location: "",
                          DurationYear: "",
                          DuartionMonth: "",
                          ExperienceType: "",
                          Workinghere: "Yes",
                        })
                      }
                    >
                      + Add another Experience
                    </Button>
                  </Grid2>
                </>
              )}
            </FieldArray>
          </form>
        </FormikProvider>
      </Accordion>
    </div>
  );
};
