import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import {
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { BlackButton } from "../../../../lib/psElements/psElements";
interface Props {
  formik: any;
  handleSubmit: (region: any) => void;
  handlerInputChange: (region: any) => void;
}
export const CorrectionschedulDetails: React.FC<Props> = ({
  formik,
  handleSubmit,
  handlerInputChange,
}) => {
  const statusData = [
    {
      value: "Active",
      label: "Active",
    },
    {
      value: "Inactive",
      label: "Inactive",
    },
  ];
  return (
    <div style={{ margin: "0px 0px 10px 0px " }}>
      <form onSubmit={formik.handleSubmit}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            sx={{ fontSize: "20px" }}
          >
            Correction Schedule <InfoIcon sx={{ mx: "5px" }} />
          </AccordionSummary>
          <Grid2
            sx={{
              px: 2,
              display: "inline-flex",
              flexDirection: "column",
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid2
              sx={{ display: "inline-flex", alignItems: "center", gap: "30px" }}
            >
              <Typography>You are currently:</Typography>

              {statusData.map(({ value, label }) => (
                <BlackButton
                  key={value}
                  className="btnUserType hoverOrange"
                  variant="contained"
                  sx={{
                    width: "90px",
                    border:
                      formik.values.currently === value
                        ? "2px solid orange"
                        : "none",
                  }}
                  onChange={handlerInputChange}
                  onClick={() => {
                    formik.setFieldValue("currently", value);
                  }}
                >
                  <Stack sx={{ alignItems: "center" }}>{label}</Stack>
                </BlackButton>
              ))}
            </Grid2>

            <Grid2 sx={{ display: "inline-flex", gap: "30px" }}>
              <Typography sx={{ textWrap: "nowrap", mt: "15px" }}>
                {" "}
                Number of papers to be allocated in a week:
              </Typography>
              <FormControl fullWidth>
                <Grid2
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Grid2 sx={{ display: "inline-block" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.allocatedInweek &&
                        Boolean(formik.errors.allocatedInweek)
                      }
                    >
                      <InputLabel id="board-select-label">Select</InputLabel>
                      <Select
                        labelId="board-select-label"
                        id="board-select"
                        label="Select"
                        name="allocatedInWeek"
                        sx={{ width: "100px" }}
                        value={formik.values.allocatedInWeek || ""}
                        onChange={handlerInputChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.allocatedInweek &&
                          Boolean(formik.errors.allocatedInweek)
                        }
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                      </Select>
                      <FormHelperText
                        sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                      >
                        {formik.touched.allocatedInweek &&
                          formik.errors.allocatedInweek}
                      </FormHelperText>
                    </FormControl>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
            <Grid2 sx={{ display: "inline-flex", gap: "25px" }}>
              <Typography sx={{ textWrap: "nowrap", mt: "15px" }}>
                Number of papers to be allocated in a month:
              </Typography>
              <FormControl fullWidth>
                <Grid2
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <Grid2 sx={{ display: "inline-block" }}>
                    <FormControl
                      fullWidth
                      error={
                        formik.touched.allocatedInMonth &&
                        Boolean(formik.errors.allocatedInMonth)
                      }
                    >
                      <InputLabel id="board-select-label">Select</InputLabel>
                      <Select
                        labelId="board-select-label"
                        id="board-select"
                        label="Select"
                        name="allocatedInMonth"
                        sx={{ width: "100px" }}
                        value={formik.values.allocatedInMonth || ""}
                        onChange={handlerInputChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.allocatedInMonth &&
                          Boolean(formik.errors.allocatedInMonth)
                        }
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                      </Select>
                      <FormHelperText
                        sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                      >
                        {formik.touched.allocatedInMonth &&
                          formik.errors.allocatedInMonth}
                      </FormHelperText>
                    </FormControl>
                  </Grid2>
                </Grid2>
              </FormControl>
            </Grid2>
            <Grid2>
              <Typography>
                (You are likely to earn Rs 12,000 monthly)
              </Typography>
            </Grid2>
          </Grid2>
        </Accordion>
      </form>
    </div>
  );
};
