import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GoBackArrowIos } from "../../../common/GoBackButton/GoBackButton";
import {
  FormControl,
  FormHelperText,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import {
  PsButton,
  PsInput,
  StepBox,
} from "../../../../lib/psElements/psElements";
import Stepsbar from "./Stepbar";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { RootState, AppDispatch } from "../../../../services/storage/store";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
// import { Postprofile } from '../../../../services/api/ApiStore'
export const Bankdetails = () => {
  const teachernavigation = useTeacherNavigation();
  const handlerInputChange = (e: any) => {
    formik.handleChange(e);
    const { name, value } = e.target;
  };
  const dispatch = useDispatch<AppDispatch>();
  const { Bankdetails } = useSelector(
    (state: RootState) => state.TeacherprofileData,
  );

  const navigate = useNavigate();

  const navigateToCorrectionschedule = () => {
    teachernavigation.navigateToTeacherSchedule();
  };
  const validationSchema = Yup.object().shape({
    Name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters")
      .max(50, "Name must be less than 50 characters"),

    Accountnumber: Yup.string()
      .required("Account number is required")
      .matches(/^[0-9]+$/, "Account number must only contain digits")
      .min(9, "Account number must be at least 9 digits")
      .max(18, "Account number must not exceed 18 digits"),

    IFSC: Yup.string()
      .required("IFSC code is required")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code format"),

    PANno: Yup.string()
      .required("PAN number is required")
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN number format"),
  });

  const formik = useFormik({
    initialValues: {
      Name: Bankdetails.Name || "",
      Accountnumber: Bankdetails.Accountnumber || "",
      IFSC: Bankdetails.IFSC || "",
      PANno: Bankdetails.PANno || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log("Form Submitted Values:", values);
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        padding: "0px 20px 0px 20px",
      }}
    >
      <Stepsbar currentStep={5} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <StepBox sx={{ px: "30px", height: "500px" }}>
          <GoBackArrowIos
            sx={{ ml: 2, mt: 2, mb: 1 }}
            handlerBackButton={() => {
              navigateToCorrectionschedule();
            }}
          />

          <Typography
            align="left"
            sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
            variant="h5"
          >
            Bank Details
          </Typography>
          <Grid2
            container
            spacing={{ xs: 2, md: 2.5 }}
            columns={{ xs: 4, sm: 10, md: 12 }}
            sx={{ m: 3, display: "grid" }}
          >
            <Grid2 size={{ md: 6 }} sx={{ display: "flex", gap: "90px" }}>
              <FormControl fullWidth>
                <PsInput
                  label="Bank Name"
                  value={formik.values.Name}
                  onChange={handlerInputChange}
                  name="Name"
                  onBlur={formik.handleBlur}
                  error={formik.touched.Name && Boolean(formik.errors.Name)}
                  sx={{}}
                />{" "}
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.Name &&
                    typeof formik.errors.Name === "string" &&
                    formik.errors.Name}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ display: "flex", gap: "90px" }}>
              <FormControl fullWidth>
                <PsInput
                  label="IFSC Code"
                  value={formik.values.IFSC}
                  onChange={handlerInputChange}
                  name="IFSC"
                  onBlur={formik.handleBlur}
                  error={formik.touched.IFSC && Boolean(formik.errors.IFSC)}
                  sx={{}}
                />{" "}
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.IFSC &&
                    typeof formik.errors.IFSC === "string" &&
                    formik.errors.IFSC}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ display: "flex", gap: "10px" }}>
              <FormControl fullWidth>
                <PsInput
                  label="Account Number"
                  value={formik.values.Accountnumber}
                  onChange={handlerInputChange}
                  name="Accountnumber"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Accountnumber &&
                    Boolean(formik.errors.Accountnumber)
                  }
                />
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.Accountnumber &&
                    typeof formik.errors.Accountnumber === "string" &&
                    formik.errors.Accountnumber}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 6 }} sx={{ display: "flex", gap: "70px" }}>
              <FormControl fullWidth>
                <PsInput
                  label="PAN number"
                  value={formik.values.PANno}
                  onChange={handlerInputChange}
                  name="PANno"
                  onBlur={formik.handleBlur}
                  error={formik.touched.PANno && Boolean(formik.errors.PANno)}
                  sx={{}}
                />
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.PANno &&
                    typeof formik.errors.PANno === "string" &&
                    formik.errors.PANno}
                </FormHelperText>
              </FormControl>
            </Grid2>
          </Grid2>
        </StepBox>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ mt: 4.5, justifyContent: "flex-end", mb: 2 }}
        >
          <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
            Cancel
          </PsButton.Outlined>
          <PsButton.Primary
            variant="contained"
            sx={{ width: 240 }}
            type="submit"
          >
            Save
          </PsButton.Primary>
        </Stack>
      </form>
    </div>
  );
};
