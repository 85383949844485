import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuthorizationHooks } from "../../../services/hooks/authorizationHooks";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import leftBottomImage from "../../assets/images/authorization/boyGirlReadingBook.svg";
import logo from "../../assets/svg/logo.svg";

const RegistrationContainer: FunctionComponent = () => {
  const location = useLocation();
  const { pathname } = location;
  const leftBottomImageRef = useRef(null);
  const [leftBottomImageFlag, setLeftBottomImageFlag] = useState(false);
  const studentNaviage = useStudentNaviagation();
  const { checkUserTypeToNavigateOnDashboard } = useAuthorizationHooks();

  useEffect(() => {
    checkUserTypeToNavigateOnDashboard();
  }, []);

  useEffect(() => {
    if (pathname === "/register/congratulations") {
      setLeftBottomImageFlag(true);
    } else {
      setLeftBottomImageFlag(false);
    }
  }, [pathname]);

  const handlerLogoClick = () => {
    studentNaviage.navigateToDashboard();
  };

  return (
    <div className="registrationContainer">
      <img
        className="logo cursorPointer"
        src={logo}
        alt="Pariksathi logo"
        onClick={handlerLogoClick}
      />
      <img
        ref={leftBottomImageRef}
        className={`sideImage ${leftBottomImageFlag ? "psHide" : ""}`}
        src={leftBottomImage}
        alt="girlBoyReading"
      />
      <Outlet />
    </div>
  );
};

export default RegistrationContainer;
