import { Box, Stack, Typography } from "@mui/material";
import { FunctionComponent, useEffect } from "react";
import { PsButton } from "../../../lib/psElements/psElements";
import { useStudentProfileAPICalls } from "../../../services/api/student/studentProfileAPIMethods";
import { useAuthorizationHooks } from "../../../services/hooks/authorizationHooks";
import { useStudentNaviagation } from "../../../services/hooks/student/navigationHooks";
import { PsTheme } from "../../../styles/psMuiTheme/colorCodes";
import greenCircle from "../../assets/svg/authorization/green-Success-dark.svg";
import logo from "../../assets/svg/logo.svg";

const Congratulations: FunctionComponent = () => {
  const studentNaviage = useStudentNaviagation();
  const { getStudentProfileDetails } = useStudentProfileAPICalls();
  const { checkUserTypeToNavigateOnDashboard } = useAuthorizationHooks();

  useEffect(() => {
    getStudentProfileDetails();
  }, []);

  useEffect(() => {
    checkUserTypeToNavigateOnDashboard();
  });

  return (
    <div
      className="congratulationContainer"
      style={{ background: PsTheme.colors.surface.five }}
    >
      <img className="logo" src={logo} alt="Pariksathi logo" />
      <Box className="congratulations">
        <Stack
          direction="column"
          className="centerCircle"
          gap={"1.5rem"}
          alignItems={"center"}
          sx={{ width: "42.4375rem" }}
        >
          <img src={greenCircle} alt="green circle" />
          <h1>Congratulations</h1>
          <Typography className="helpingText">
            On taking the first step towards preparing for your important exams
            sit back and relax, we have you covered.
          </Typography>
          <PsButton.Primary
            variant="contained"
            onClick={studentNaviage.navigateToStep1PersonalDetails}
            sx={{ height: "3rem", width: "25rem" }}
          >
            Continue
          </PsButton.Primary>
        </Stack>
      </Box>
    </div>
  );
};

export default Congratulations;
