import {
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  PsButton,
  PsInput,
  StepBox,
} from "../../../../lib/psElements/psElements";
import { TeacherAPIcalls } from "../../../../services/api/teacher/ProfileAPI";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
import { AppDispatch, RootState } from "../../../../services/storage/store";
import { PersonalInformation } from "../../../../services/storage/teacher/TProfile";
import { GoBackArrowIos } from "../../../common/GoBackButton/GoBackButton";
import { NumData } from "../../../common/TeacherDatajson/countryNum";
import { statesData } from "../../../common/TeacherDatajson/StateName";
import Stepsbar from "./Stepbar";

export const Personalinformation = () => {
  const { getCityList, getStateList, getCountryList } = TeacherAPIcalls();
  const teachernavigation = useTeacherNavigation();
  // const dispatch = useDispatch()
  // const { profile  } = useSelector(
  //   (state: RootState) => state.TeacherprofileData,
  // );
  const {
    TeacherprofileData: { profile },
    // location,
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    getCityList("India", formik.values.State);
    getStateList("India");
    getCountryList(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const selectTeacherProfileData = (state: RootState) =>
  //   state.TeacherprofileData;
  // const teacherProfileData = useSelector(selectTeacherProfileData);
  const dispatch = useDispatch<AppDispatch>();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    Number: Yup.string().required("Select your country code"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    country: Yup.string().required("Country is required"),
    State: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    Associate: Yup.string().required("Associate is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: profile.firstName || "",
      lastname: profile.lastname || "",
      email: profile.email || "",
      Number: profile.Number || "",
      phone: profile.phone || "",
      country: profile.country || "",
      State: profile.State || "",
      city: profile.city || "",
      Associate: profile.Associate || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        dispatch(PersonalInformation(values));
        teachernavigation.navigateToTeachereducation();
      } catch (error) {
        console.error("Submission error:", error);
        toast.error("An unexpected error occurred");
      }
    },
  });

  const handlerInputChange = (e: any) => {
    formik.handleChange(e);
  };

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        padding: "0px 20px 0px 20px",
      }}
    >
      <Stepsbar currentStep={0} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <StepBox sx={{ px: "50px", height: "100%", pb: "30px" }}>
          <GoBackArrowIos sx={{ ml: 2, mt: 2, mb: 1 }} />

          <Typography
            align="left"
            sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
            variant="h5"
          >
            Personal Information
          </Typography>
          <Grid2
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              },
              gap: "10px",
              mb: "20px",
            }}
          >
            <Grid2>
              <FormControl fullWidth>
                <PsInput
                  label="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={handlerInputChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                />
                <FormHelperText sx={{ color: "red" }}>
                  {formik.touched.firstName &&
                    typeof formik.errors.firstName === "string" &&
                    formik.errors.firstName}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2>
              {" "}
              <FormControl fullWidth>
                <PsInput
                  label="Last Name"
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={handlerInputChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastname && Boolean(formik.errors.lastname)
                  }
                />
                <FormHelperText sx={{ color: "red" }}>
                  {formik.touched.lastname &&
                    typeof formik.errors.lastname === "string" &&
                    formik.errors.lastname}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <PsInput
                  label="Email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={handlerInputChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                <FormHelperText sx={{ color: "red" }}>
                  {formik.touched.email &&
                    typeof formik.errors.email === "string" &&
                    formik.errors.email}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(2, 1fr)",
                },
                mb: "20px",
              }}
            >
              <Grid2>
                <FormControl
                  fullWidth
                  sx={{ width: "100%", display: "flex", alignItems: "start" }}
                >
                  <InputLabel id="board-select-label">Code</InputLabel>
                  <Select
                    labelId="board-select-label"
                    id="board-select"
                    label="Code"
                    name="Number"
                    value={formik.values.Number}
                    onChange={handlerInputChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.Number && Boolean(formik.errors.Number)
                    }
                    sx={{ width: "140px" }}
                  >
                    {NumData.map((Num) => (
                      <MenuItem key={Num.dial_code} value={Num.dial_code}>
                        {Num.dial_code}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                  >
                    {formik.touched.Number &&
                      typeof formik.errors.Number === "string" &&
                      formik.errors.Number}
                  </FormHelperText>
                </FormControl>
              </Grid2>

              <Grid2>
                <FormControl sx={{ width: "100%" }}>
                  <PsInput
                    label="Phone Number"
                    name="phone"
                    value={formik.values.phone}
                    onChange={handlerInputChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    sx={{ width: "100%" }}
                  />
                  <FormHelperText sx={{ color: "red" }}>
                    {formik.touched.phone &&
                      typeof formik.errors.phone === "string" &&
                      formik.errors.phone}
                  </FormHelperText>
                </FormControl>
              </Grid2>
            </Grid2>

            <Grid2
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(2, 1fr)",
                },
                mb: "20px",
              }}
            >
              <Grid2>
                <FormControl
                  sx={{ display: "inline-block", alignItems: "center" }}
                >
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    labelId="country-select-label"
                    id="country-select"
                    name="country"
                    value={formik.values.country}
                    label="Country"
                    onChange={handlerInputChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    sx={{ width: "120px" }}
                  >
                    <MenuItem value="India">India</MenuItem>
                  </Select>
                  <FormHelperText
                    sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                  >
                    {formik.touched.country &&
                      typeof formik.errors.country === "string" &&
                      formik.errors.country}
                  </FormHelperText>
                </FormControl>
              </Grid2>

              <Grid2>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="country-select-label">State</InputLabel>
                  <Select
                    labelId="country-select-label"
                    id="country-select"
                    name="State"
                    value={formik.values.State}
                    label="State"
                    onBlur={formik.handleBlur}
                    onChange={handlerInputChange}
                    error={formik.touched.State && Boolean(formik.errors.State)}
                  >
                    {statesData.map((state) => (
                      <MenuItem key={state.code} value={state.code}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                  >
                    {formik.touched.State &&
                      typeof formik.errors.State === "string" &&
                      formik.errors.State}
                  </FormHelperText>
                </FormControl>
              </Grid2>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <PsInput
                  label="Your City"
                  name="city"
                  value={formik.values.city}
                  onChange={handlerInputChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                />
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.city &&
                    typeof formik.errors.city === "string" &&
                    formik.errors.city}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2>
              <FormControl fullWidth>
                <InputLabel id="associate-select-label">Associate</InputLabel>
                <Select
                  labelId="associate-select-label"
                  id="associate-select"
                  name="Associate"
                  value={formik.values.Associate}
                  label="Associate"
                  onChange={handlerInputChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.Associate && Boolean(formik.errors.Associate)
                  }
                >
                  <MenuItem value="School">School</MenuItem>
                  <MenuItem value="Tution">Tution</MenuItem>
                  <MenuItem value="Sole">Sole</MenuItem>
                </Select>
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.Associate &&
                    typeof formik.errors.Associate === "string" &&
                    formik.errors.Associate}
                </FormHelperText>
              </FormControl>
            </Grid2>
          </Grid2>
        </StepBox>

        <Stack
          direction="row"
          gap={1}
          sx={{ mt: 4.5, mb: 2 }}
          justifyContent="flex-end"
        >
          <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
            Cancel
          </PsButton.Outlined>

          <PsButton.Primary
            variant="contained"
            sx={{ width: 240 }}
            type="submit"
          >
            Proceed
          </PsButton.Primary>
        </Stack>
      </form>
    </div>
  );
};
