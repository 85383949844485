// import { Box } from "@mui/material";
// import leftImage from "../../assets/images/authorization/leftSectionCompressed.jpeg";
// import logo from "../../assets/svg/logo.svg";
// function LeftSection() {
//   return (
//     <Box className="leftSection">
//       <img className="background" src={leftImage} alt="background" style={{objectFit: "cover" , height: "80%" , width: "100%"}}/>
//       {/* <img className="logo" src={logo} alt="Pariksathi" style={{objectFit: "cover" , height: "30%" , width: "30%"}} /> */}
//     </Box>
//   );
// }

// export default LeftSection;

import { Box } from "@mui/material";
import leftImage from "../../assets/images/authorization/leftSectionCompressed.jpeg";
import logo from "../../assets/svg/logo.svg";

function LeftSection() {
  return (
    <Box className="leftSection" >
      <img
        className="background"
        src={leftImage}
        alt="background"
        
      />
      {/* <img
        className="logo"
        src={logo}
        alt="Pariksathi"
        style={{ width: '150px', height: 'auto' }} 
      /> */}
    </Box>
  );
}

export default LeftSection;
