import { Dashboard } from "@mui/icons-material";
import { backdropClasses, colors } from "@mui/material";
import { yellow } from "@mui/material/colors";

export const darkTheme = {
  colors: {
    font: {
      light: "#F8F8F8",
      dark: "#C4C4C4",
    },
    surface: {
      root: "#131313",
      one: "#222222",
      two: "#2A2A2A",
      three: "#434343",
      four: "#494949",
      five: "#181818",
      six: "#333333",
    },
    orange: "#FD9B18",
    action: {
      primary: "#984AF2",
      secondary: "#fd9b18",
      teritaryOne: "#f24e1e",
      teritaryTwo: "#3a3a3a",
    },
    chip: {
      green: "#0BAB48",
      blue: "#0B3D9F",
      violet: "#371648",
      brown: "#473319",
      red: "#751B1B",
      white: "#e2e2e2",
    },
    alert: {
      green: "#02B223",
      red: "#E33F3F",
      blue: "#3587DC",
      orange: "#FD9B18",
    },
    button: {
      primary: "#984AF2",
      black: "#000000",
      // gray:rgba(255, 255, 255, 0.08),
      textWhite: "#FFFFFF",

      hoverBackground: "#FD9B1833",
      hoverBorderColor: "#FD9B18",
      textBlue: "#3587DC",
    },
    boardCard: {
      avatar: "#D9D9D9",
      gradeFontColor: "#3f7df9",
    },
    menu: {
      background: "#1c1c1c",
      logout: "#e25b2c",
    },
    studentDashboard: {
      catCard: {
        selected: "#FD9B1833",
      },
    },
    teacher: {
      theme: {
        purpule: "#9754FF",
        white: "#fff",
        blue: "#1565C0",
        yellow: "#FD9B18",
        grey: "#121212",
        custombg: "#FD9B1833",
        Custompopup: "#007FFF33",
      },
      tasktable: {
        tablebg: "#222222",
      },
      drawer: {
        activestyle: {
          active: "#9F7AEA",
          deactive: "#9754FF",
        },
        backgroundColor: "#007FFF1A",
      },
      Dashboard: {
        charts: {
          optioncolor: "#02B223",
          color: "#FFFFFF",
          activecolor: "#FD9B18",
          backgroundcolor: "#333",
          borderColor: "#444",
          tabactiveColor: "#555",
        },
        paperHistory: {
          whitebackgroundColor: "#fff",
          backgroundColor: "#1A2027",
          linear1: "linear-gradient(to bottom, #257BD7, #2D4190)",
          linear2: "linear-gradient(to bottom, #A9316D, #662181)",
          linear3: "linear-gradient(to bottom, #A9316D, #662181)",
          linear4: "linear-gradient(to bottom, #075E6B, #0A2C41)",
        },
        task: {
          backgroundColor: "#2A2A2A",
          color: "#007FFF",
          white: "#fff",
        },
      },
      pdf: {
        pdfFooter: {
          color: "#1C1C1C",
        },
        pdfheader: {
          backgroundcolr: "#222222",
          color: "#BF40BF",
          initialColor: "#FF0000",
          white: "#f5f5f5",
          bg: "#2C2C2C",
          color1: "#1C1C1C",
          color2: "#2A2A2A",
          color3: "#FD9B18",
          color4: "#ddd",
          blue: "#1976d2",
          purpule: "#371648",
        },
      },
    },
  },
};

export const PsTheme = darkTheme;
/** Action colors will use for buttons and actions */
/* $action-primary-color: #007fff;
$action-secondary-color: #fd9b18;
$action-teritary-one-color: #f24e1e;
$action-teritary-two-color: #3a3a3a; */

/** Surface colors */
/* $surface-dark-one-color: #222222;
$surface-dark-two-color: #2A2A2A;
$surface-dark-three-color: #434343;
$surface-dark-four-color: #494949;
$surface-dark-five-color:  #181818;
; */

/** Typography colors */
/* $typography-white-one-color: #F8F8F8;
$typography-white-two-color: #C4C4C4;
 */
/** Chip colors */
/* $chip-green-color: #0BAB48;
$chip-blue-color: #0B3D9F;
$chip-violet-color: #371648;
$chip-brown-color: #473319;
$chip-red-color: #751B1B; */

/** Alert colors */
/* $alert-color-green: #02B223;
$alert-color-red: #E33F3F;
$alert-color-blue: #3587DC;
$alert-color-orange: #FD9B18; */

/** button colors */
/* $btn-primay-color: #1976D2;
$black-color: #000000;
$btn-gray-color:rgba(255, 255, 255, 0.08);
$btn-text-white: #FFFFFF;
$btn-hover-background: #FD9B1833;
$btn-hover-borderColor: #FD9B18; */

/** root background color */
/* $root-background: #131313; */
