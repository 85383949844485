import {
  Button,
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  PsButton,
  PsCheckbox,
  PsInput,
  StepBox,
} from "../../../../lib/psElements/psElements";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
import { AppDispatch, RootState } from "../../../../services/storage/store";
import { ExperienceData } from "../../../../services/storage/teacher/TProfile";
import { GoBackArrowIos } from "../../../common/GoBackButton/GoBackButton";
import Stepsbar from "./Stepbar";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";

export const Experience = () => {
  const teachernavigation = useTeacherNavigation();
  // const dispatch = useDispatch();
  const dispatch = useDispatch<AppDispatch>();
  const { experience } = useSelector(
    (state: RootState) => state.TeacherprofileData,
  );

  const validationSchema = Yup.object().shape({
    experiences: Yup.array().of(
      Yup.object().shape({
        Institutionname: Yup.string().required(
          "Institutionname name is required",
        ),
        Location: Yup.string().required("Location is required"),
        ExperienceType: Yup.string().required("ExperienceType is required"),
      }),
    ),
  });
  const navigate = useNavigate();

  const navigateToEduction = () => {
    teachernavigation.navigateToTeachereducation();
  };
  const formik = useFormik({
    initialValues: {
      experiences: [
        {
          Institutionname: experience.Institutionname || "",
          Location: experience.Location || "",
          Year: experience.Year || "",
          Mt: experience.Mt || "",
          Workinghere: experience.Workinghere || "Yes",
          ExperienceType: experience.ExperienceType,
        },
      ],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        dispatch(ExperienceData(values));
        teachernavigation.navigateToTeacherEnrollment();
      } catch (error) {
        console.error("Submission error:", error);
        toast.error("An unexpected error occurred");
      }
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        padding: "0px 20px 0px 20px",
      }}
    >
      <Stepsbar currentStep={2} />
      <FormikProvider value={formik}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit(e);
          }}
        >
          <StepBox sx={{ px: "30px", height: "550px" }}>
            <GoBackArrowIos
              sx={{ ml: 2, mt: 2, mb: 1 }}
              handlerBackButton={() => {
                navigateToEduction();
              }}
            />
            <Typography
              align="left"
              sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
              variant="h5"
            >
              Experience
            </Typography>
            <FieldArray name="experience">
              {({ push, remove }) => (
                <>
                  {formik.values.experiences.map(
                    (experience: any, index: number) => (
                      <Grid2
                        sx={{
                          display: "grid",
                          gridTemplateColumns: {
                            xs: "repeat(1, 1fr)",
                            sm: "repeat(1, 1fr)",
                            md: "repeat(2, 1fr)",
                            lg: "repeat(3, 1fr)",
                          },
                          gap: "10px",
                          mb: "10px",
                        }}
                      >
                        <Grid2
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Grid2>
                            <FormControl fullWidth>
                              <PsInput
                                label="InstitutionName"
                                value={experience.Institutionname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name={`experiences[${index}].Institutionname`}
                                sx={{ width: "400px" }}
                                error={
                                  formik.touched.experiences?.[index]
                                    ?.Institutionname &&
                                  Boolean(
                                    (
                                      formik.errors
                                        .experiences as FormikErrors<{
                                        Institutionname: string;
                                        Location: string;
                                        Year: string;
                                        Mt: string;
                                        ExperienceType: string;
                                      }>[]
                                    )?.[index]?.Institutionname,
                                  )
                                }
                              />
                              <FormHelperText
                                sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                              >
                                {formik.touched.experiences?.[index]
                                  ?.Institutionname &&
                                  (
                                    formik.errors.experiences as FormikErrors<{
                                      Institutionname: string;
                                      Location: string;
                                      Year: string;
                                      Mt: string;
                                      ExperienceType: string;
                                    }>[]
                                  )?.[index]?.Institutionname}
                              </FormHelperText>
                            </FormControl>
                          </Grid2>
                        </Grid2>
                        <Grid2
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Grid2>
                            <FormControl fullWidth>
                              <PsInput
                                label="Location"
                                value={experience.Location}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name={`experiences[${index}].Location`}
                                sx={{ width: "400px" }}
                                error={
                                  formik.touched.experiences?.[index]
                                    ?.Location &&
                                  Boolean(
                                    (
                                      formik.errors
                                        .experiences as FormikErrors<{
                                        Institutionname: string;
                                        Location: string;
                                        Year: string;
                                        Mt: string;
                                        ExperienceType: string;
                                      }>[]
                                    )?.[index]?.Location,
                                  )
                                }
                              />
                              <FormHelperText
                                sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                              >
                                {formik.touched.experiences?.[index]
                                  ?.Location &&
                                  (
                                    formik.errors.experiences as FormikErrors<{
                                      Institutionname: string;
                                      Location: string;
                                      Year: string;
                                      Mt: string;
                                      ExperienceType: string;
                                    }>[]
                                  )?.[index]?.Location}
                              </FormHelperText>
                            </FormControl>
                          </Grid2>
                        </Grid2>

                        <Grid2
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          Duration
                          <Grid2>
                            <FormControl fullWidth>
                              <PsInput
                                label="Year"
                                value={experience.Year}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name={`experiences[${index}].Year`}
                                // error={formik.touched.experiences?.[index]?.Year && Boolean((formik.errors.experiences as FormikErrors<{ Institutionname: string; Location: string; Year: string; Mt: string; ExperienceType: string }>[])?.[index]?.Year)}
                              />
                            </FormControl>
                          </Grid2>
                          <Typography sx={{}}>Year</Typography>
                          <Grid2>
                            <FormControl fullWidth>
                              <PsInput
                                label="Mt"
                                onChange={formik.handleChange}
                                value={experience.Mt}
                                onBlur={formik.handleBlur}
                                name={`experiences[${index}].Mt`}
                                // error={formik.touched.experiences?.[index]?.Mt && Boolean((formik.errors.experiences as FormikErrors<{ Institutionname: string; Location: string; Year: string; Mt: string; ExperienceType: string }>[])?.[index]?.Mt)}
                              />
                            </FormControl>
                            {/* <FormHelperText sx={{ color: "red", margin: '3px 0px 0px 0px' }}>
                            {formik.touched.experiences?.[index]?.Mt &&
                              (formik.errors.experiences as FormikErrors<{ Institutionname: string; Location: string; Year: string; Mt: string[]; ExperienceType: string }>[])?.[index]?.Mt}
                          </FormHelperText> */}
                          </Grid2>
                          <Typography sx={{}}>Mt</Typography>
                        </Grid2>

                        <Grid2
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel id="board-select-label">
                              Experience Type
                            </InputLabel>
                            <Select
                              labelId="board-select-label"
                              id="board-select"
                              label="Experience Type"
                              name={`experiences[${index}].ExperienceType`}
                              sx={{ width: "400px" }}
                              value={experience.ExperienceType}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.experiences?.[index]
                                  ?.ExperienceType &&
                                Boolean(
                                  (
                                    formik.errors.experiences as FormikErrors<{
                                      Institutionname: string;
                                      Location: string;
                                      Year: string;
                                      Mt: string;
                                      ExperienceType: string;
                                    }>[]
                                  )?.[index]?.ExperienceType,
                                )
                              }
                            >
                              <MenuItem value="School">School</MenuItem>
                              <MenuItem value="Tution">Tution</MenuItem>
                            </Select>
                            <FormHelperText
                              sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                            >
                              {formik.touched.experiences?.[index]
                                ?.ExperienceType &&
                                (
                                  formik.errors.experiences as FormikErrors<{
                                    Institutionname: string;
                                    Location: string;
                                    Year: string;
                                    Mt: string;
                                    ExperienceType: string;
                                  }>[]
                                )?.[index]?.ExperienceType}
                            </FormHelperText>
                          </FormControl>
                        </Grid2>
                        <Grid2
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Grid2 sx={{ display: "inline-flex", gap: "0px" }}>
                            <Typography>Working here</Typography>
                            <Grid2
                              sx={{
                                display: "inline-block",
                                textWrap: "nowrap",
                              }}
                            >
                              <PsCheckbox
                                sx={{
                                  px: "45px",
                                  "&.Mui-checked": {
                                    color: `${darkTheme.colors.teacher.theme.yellow}`,
                                  },
                                }}
                                name={`experiences[${index}].Workinghere`}
                                checked={experience.Workinghere === "Yes"}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  formik.setFieldValue(
                                    `experiences[${index}].Workinghere`,
                                    isChecked ? "Yes" : "No",
                                  );
                                }}
                              />
                            </Grid2>
                          </Grid2>
                        </Grid2>
                        <Grid2></Grid2>
                        {index > 0 && (
                          <Grid2>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => remove(index)}
                            >
                              Remove Experience {index + 1}
                            </Button>
                          </Grid2>
                        )}
                      </Grid2>
                    ),
                  )}
                  <Grid2 sx={{ px: 0, margin: "40px 0px 10px 0px " }}>
                    <PsButton.Outlined
                      variant="outlined"
                      type="button"
                      onClick={() =>
                        push({
                          Institutionname: "",
                          Location: "",
                          Year: "",
                          Mt: "",
                          WorkingType: "",
                          Workinghere: [],
                        })
                      }
                    >
                      + Add another Education
                    </PsButton.Outlined>
                  </Grid2>
                </>
              )}
            </FieldArray>
          </StepBox>

          <Stack
            direction={"row"}
            gap={1}
            sx={{ mt: 4.5, justifyContent: "flex-end", mb: 2 }}
          >
            <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
              Cancel
            </PsButton.Outlined>
            <PsButton.Primary
              variant="contained"
              sx={{ width: 240 }}
              type="submit"
            >
              Proceed
            </PsButton.Primary>
          </Stack>
        </form>{" "}
      </FormikProvider>
    </div>
  );
};
