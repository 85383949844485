import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import HeaderComponent from "../common/Header";
import CreateAccount from "../components/Authorization/CreateAccount";
import LoginByEmail from "../components/Authorization/LoginByEmail";
import LoginByPhoneNumber from "../components/Authorization/LoginByPhoneNumber";
import SelectLoginType from "../components/Authorization/SelectLoginType";
import { JudgeDashboardView } from "../views/jdsh";
import { Authorization } from "./Authorization";
// import Congratulations from "../components/RegistrationSteps/Congratulations";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import Congratulations from "../components/Congratulations/Congratulation";
import BoardDetails from "../components/RegistrationSteps/BoardDetails";
import ExaminationDetails from "../components/RegistrationSteps/ExaminationDetails";
import GradeDetails from "../components/RegistrationSteps/GradeDetails";
import LanguageDetails from "../components/RegistrationSteps/LanguageDetails";
import PersonalDetails from "../components/RegistrationSteps/PersonalDetails";
import RegistrationContainer from "../components/RegistrationSteps/RegistrationContainer";
import SchoolDetails from "../components/RegistrationSteps/SchoolDetails";
import StepContainer from "../components/RegistrationSteps/StepsContainer";
// import StudentDashboard from "../components/student/Dashboard/Dashboard";

import { useSelector } from "react-redux";
import { RootState } from "../../services/storage/store";
import StudentDashboard from "../components/student/Dashboard/Dashboar";
import GenerateTestPaper from "../components/student/Test/GenerateTestPaper/GenerateTestPaper";
import ViewTestPaper from "../components/student/Test/ViewTestPaper/ViewTestPaper";
import { PdfViewer } from "../components/Teacher/PdfDocument/Index/PdfViewer";
import { Profile } from "../components/Teacher/Profile/Profile";
import { Bankdetails } from "../components/Teacher/RegisterProfile/Bankdetails";
import { Eduction } from "../components/Teacher/RegisterProfile/Eduction";
import { Enrollment } from "../components/Teacher/RegisterProfile/Enrollment";
import { Experience } from "../components/Teacher/RegisterProfile/Experience";
import { Personalinformation } from "../components/Teacher/RegisterProfile/Personalinformation";
import { ScheduleCorrection } from "../components/Teacher/RegisterProfile/ScheduleCorrection";
import TeacherProfile from "../components/Teacher/RegisterProfile/TeacherProfile";
import { PSContainer } from "../views/Container";
import { TeacherContainer } from "../views/TeacherContainer";
import Student from "./Student";
import TeacherDashboard from "./TeacherDashboard";

// const drawerWidth = 200;
// const drawerWidth = 200;

// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
//   open?: boolean;
// }>(({ theme, open }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(0),
//   transition: theme.transitions.create("margin", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: `-${drawerWidth}px`,
//   ...(open && {
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: 0,
//   }),
// }));

export const router: any = createBrowserRouter([
  {
    path: "/",
    element: <PSContainer />,
    // errorElement: <ErrorComponent />,
    children: [
      { index: true, element: <Navigate to="/authorization/login" replace /> },
      {
        path: "authorization",
        element: <Authorization />,
        children: [
          {
            path: "login",
            element: <SelectLoginType />,
          },
          {
            path: "email",
            element: <LoginByEmail />,
          },
          {
            path: "phone",
            element: <LoginByPhoneNumber />,
          },
          {
            path: "create",
            element: <CreateAccount />,
          },
        ],
      },
      { path: "Congratulations", element: <Congratulations /> },
      {
        path: "register",
        element: <RegistrationContainer />,
        children: [
          // { path: "congratulations", element: <Congratulations /> },

          {
            path: "steps",
            element: <StepContainer />,
            children: [
              { path: "personaldetails", element: <PersonalDetails /> },
              { path: "schooldetails", element: <SchoolDetails /> },
              { path: "boarddetails", element: <BoardDetails /> },
              { path: "languagedetails", element: <LanguageDetails /> },
              { path: "gradedetails", element: <GradeDetails /> },
              { path: "examinationdetails", element: <ExaminationDetails /> },
            ],
          },
        ],
      },

      {
        path: "student",
        element: <Student />,
        children: [
          {
            path: "dashboard",
            element: <StudentDashboard />,
          },
          {
            path: "new-test",
            element: <GenerateTestPaper />,
          },
          {
            path: "test",
            element: <ViewTestPaper />,
          },
        ],
      },
      {
        element: <JudgeDashboardView />,
        children: [
          {
            path: "dashboard",
            element: <HeaderComponent />,
            children: [],
          },
        ],
      },
      //Teacher section
      {
        path: "teacher",
        element: <TeacherContainer />,
        children: [
          {
            path: "register",
            element: <TeacherProfile />,
            children: [
              { path: "personalinformation", element: <Personalinformation /> },
              { path: "eduction", element: <Eduction /> },
              { path: "experience", element: <Experience /> },
              { path: "enrollment", element: <Enrollment /> },
              { path: "correctionschedule", element: <ScheduleCorrection /> },
              { path: "bankdetails", element: <Bankdetails /> },
            ],
          },
          {
            path: "profile",
            element: <Profile />,
          },
          {
            path: `evaluationnow/:id`,
            element: <PdfViewer />,
          },
          {
            path: "dashboard",
            element: <TeacherDashboard />,
          },
        ],
      },
    ],
  },
]);

const PsRoutes = () => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const [open, setOpen] = useState(!isMobile);
  const {
    common: { loaderShowHide },
  } = useSelector((state: RootState) => state);
  return (
    <>
      <Backdrop
        sx={(theme: { zIndex: { drawer: number } }) => ({
          color: "#fff",
          zIndex: theme.zIndex.drawer + 1,
        })}
        open={loaderShowHide}
        // onClick={handleClose}
      >
        <CircularProgress
          color="inherit"
          variant="indeterminate"
          value={50}
        ></CircularProgress>
      </Backdrop>

      <Box>
        {/* <LeftSection/> */}
        <RouterProvider router={router} />
        {/* <Main> */}
        {/* <DrawerHeader /> */}
        {/* </Main> */}
      </Box>
    </>
  );
};

export default PsRoutes;
