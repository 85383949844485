import TimerIcon from "@mui/icons-material/Timer";
import { Box, Divider, Typography } from "@mui/material";
import { useTeacherNavigation } from "../../../../../services/hooks/teacher/navigationHooks";
import { darkTheme } from "../../../../../styles/psMuiTheme/colorCodes";
import { GoBackArrowIos } from "../../../../common/GoBackButton/GoBackButton";
import { CorrectionTime } from "../../../../common/hook/correctionTime";
export const PDFheader = ({ perticularTask }: any) => {
  const teachernavigation = useTeacherNavigation();
  const { seconds, minutes } = CorrectionTime();
  const handleClickback = () => {
    teachernavigation.navigateToTeacherDashboard();
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ px: "20px", py: "20px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <GoBackArrowIos handlerBackButton={handleClickback} />
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box
                sx={{
                  width: "150px",
                  height: "40px",
                  backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.backgroundcolr}`,
                  borderRadius: "5px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TimerIcon
                  sx={{
                    color: `${darkTheme.colors.teacher.pdf.pdfheader.color}`,
                  }}
                />
                <Typography sx={{ fontWeight: "normal" }}>
                  Pending:
                  <span style={{ fontWeight: "600" }}> 8</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "220px",
                  height: "40px",
                  backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.purpule}`,
                  borderRadius: "5px",
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TimerIcon />
                <Typography sx={{ fontWeight: "normal" }}>
                  Correction Time:
                  <span style={{ fontWeight: "600" }}>
                    {`${minutes}:${seconds}`}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "full ",
              height: "full",
              mt: "10px",
              backgroundColor: `${darkTheme.colors.teacher.pdf.pdfheader.backgroundcolr}`,
              borderRadius: "5px",
              display: "flex",
              gap: "5px",
              alignItems: "center",
              px: "15px",
            }}
          >
            {Array.isArray(perticularTask) &&
              perticularTask.map((item: any) => (
                <Box key={item.testID}>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <Typography sx={{ fontWeight: "700" }}>
                      22/1/2025
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ mx: "15px", my: "5px" }}
                    />
                    <Typography sx={{ fontWeight: "700" }}>
                      {item.testId}
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ mx: "15px", my: "5px" }}
                    />
                    <Typography sx={{ fontWeight: "700" }}>
                      {item.difficulty}
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ mx: "15px", my: "5px" }}
                    />
                    <Typography sx={{ fontWeight: "700" }}>
                      {item.board}
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ mx: "15px", my: "5px" }}
                    />
                    <Typography sx={{ fontWeight: "700" }}>
                      {item.subject}
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ mx: "15px", my: "5px" }}
                    />
                    <Typography sx={{ fontWeight: "700" }}>
                      {item.grade}
                    </Typography>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ mx: "15px", my: "5px" }}
                    />
                    <Typography sx={{ fontWeight: "700", textWrap: "wrap" }}>
                      Chapters:
                      {item.chapterList.join(" , ")}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
