import {
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  BlackButton,
  PsButton,
  StepBox,
} from "../../../../lib/psElements/psElements";
import { AppDispatch, RootState } from "../../../../services/storage/store";
import { ScheduleData } from "../../../../services/storage/teacher/TProfile";
import { GoBackArrowIos } from "../../../common/GoBackButton/GoBackButton";
import Stepsbar from "./Stepbar";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
// import { Postprofile } from '../../../../services/api/ApiStore'
export const ScheduleCorrection = () => {
  const teachernavigation = useTeacherNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const validationSchema = Yup.object().shape({
    allocatedInweek: Yup.string().required("  required"),
    allocatedInMonth: Yup.string().required(" required"),
  });

  const handlerInputChange = (e: any) => {
    const { name } = e.target;
    formik.handleChange(e);
    const value = name === "active" ? "Active" : "Inactive";
    formik.setFieldValue("currently", value);
  };

  const { schedule } = useSelector(
    (state: RootState) => state.TeacherprofileData,
  );
  // useEffect(() => {
  //   setTeacherSchedule({ ...schedule });
  // }, []);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      currently: schedule.currently || "Active",
      allocatedInweek: schedule.allocatedInweek || "",
      allocatedInMonth: schedule.allocatedInMonth || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = async (values: any) => {
    try {
      dispatch(ScheduleData(values));
      teachernavigation.navigateToTeacherBankdetails();
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  const navigateToEnrollment = () => {
    navigate("/teacher/register/enrollment");
  };
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        padding: "0px 20px 0px 20px",
      }}
    >
      <Stepsbar currentStep={4} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <StepBox sx={{ px: "30px", height: "470px" }}>
          <GoBackArrowIos
            sx={{ ml: 2, mt: 2, mb: 1 }}
            handlerBackButton={() => {
              navigateToEnrollment();
            }}
          />
          <Typography
            align="left"
            sx={{ ml: 3, fontWeight: 700, mb: 2.5 }}
            variant="h5"
          >
            Correction Schedule
          </Typography>

          <Grid2
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
                md: "repeat(1, 1fr)",
                lg: "repeat(1, 1fr)",
              },
              gap: "10px",
            }}
          >
            <Grid2 sx={{ display: "inline-flex", gap: "10px" }}>
              <Typography sx={{ textWrap: "nowrap", mt: "5px" }}>
                You are currently:
              </Typography>
              <BlackButton
                className="btnUserType hoverOrange"
                variant="contained"
                sx={{
                  width: "90px",
                  border:
                    formik.values.currently === "Active"
                      ? "2px solid orange"
                      : "none",
                }}
                onChange={handlerInputChange}
                onClick={() => {
                  formik.setFieldValue("currently", "Active");
                }}
              >
                <Stack sx={{ alignItems: "center" }}>Active</Stack>
              </BlackButton>
              <BlackButton
                className="btnUserType hoverOrange"
                variant="contained"
                sx={{
                  width: "90px",
                  border:
                    formik.values.currently === "Inactive"
                      ? "2px solid orange"
                      : "none",
                }}
                onChange={handlerInputChange}
                onClick={() => {
                  formik.setFieldValue("currently", "Inactive");
                }}
              >
                <Stack sx={{ alignItems: "center" }}>Inactive</Stack>
              </BlackButton>
            </Grid2>

            <Grid2 sx={{ display: "inline-flex", gap: "10px" }}>
              <Typography sx={{ textWrap: "nowrap", mt: "20px" }}>
                {" "}
                Number of papers to be allocated in a week:
              </Typography>
              <FormControl>
                <InputLabel id="board-select-label">Select</InputLabel>
                <Select
                  labelId="board-select-label"
                  id="board-select"
                  label="Select"
                  name="allocatedInweek"
                  sx={{ width: "100px" }}
                  value={formik.values.allocatedInweek}
                  onChange={handlerInputChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.allocatedInweek &&
                    Boolean(formik.errors.allocatedInweek)
                  }
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </Select>
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.allocatedInweek &&
                    typeof formik.errors.allocatedInweek === "string" &&
                    formik.errors.allocatedInweek}
                </FormHelperText>
              </FormControl>
            </Grid2>

            <Grid2 sx={{ display: "inline-flex", gap: "10px" }}>
              <Typography sx={{ textWrap: "nowrap", mt: "20px" }}>
                {" "}
                Number of papers to be allocated in a month:
              </Typography>
              <FormControl>
                <InputLabel id="board-select-label">Select</InputLabel>
                <Select
                  labelId="board-select-label"
                  id="board-select"
                  label="Select"
                  name="allocatedInMonth"
                  sx={{ width: "100px" }}
                  value={formik.values.allocatedInMonth}
                  onChange={handlerInputChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.allocatedInMonth &&
                    Boolean(formik.errors.allocatedInMonth)
                  }
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </Select>
                <FormHelperText
                  sx={{ color: "red", margin: "3px 0px 0px 0px" }}
                >
                  {formik.touched.allocatedInMonth &&
                    typeof formik.errors.allocatedInMonth === "string" &&
                    formik.errors.allocatedInMonth}
                </FormHelperText>
              </FormControl>
            </Grid2>
            <Grid2>
              <Typography>
                {" "}
                (You are likely to earn Rs 12,000 monthly)
              </Typography>
            </Grid2>
          </Grid2>
        </StepBox>
        <Stack
          direction={"row"}
          gap={1}
          sx={{ mt: 4.5, justifyContent: "flex-end", mb: 2 }}
        >
          <PsButton.Outlined variant="outlined" sx={{ width: 120 }}>
            Cancel
          </PsButton.Outlined>
          <PsButton.Primary
            variant="contained"
            sx={{ width: 240 }}
            type="submit"
          >
            Proceed
          </PsButton.Primary>
        </Stack>
      </form>
    </div>
  );
};
