import { Fragment, ReactElement } from "react";
import { Outlet } from "react-router-dom";

export const JudgeDashboardView: React.FC = (): ReactElement => {
  return (
    <Fragment>
      <div>thsi is the test page</div>
      <Outlet />
    </Fragment>
  );
};
