import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ApiSlice from "../api/ApiStore";
import common from "../storage/common";
import error from "../storage/error";
import location from "../storage/student/location";
import studentProfile from "../storage/student/profile";
import studentTest from "./student/test";
import TeacherprofileData from "./teacher/TProfile";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  studentProfile: studentProfile,
  studentTest: studentTest,
  error: error,
  common: common,
  location: location,
  TeacherprofileData: TeacherprofileData,
  Teacherlocation: location,
  // ApiData: ApiSlice,
  ApiData: ApiSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const psStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }),
});

export type RootState = ReturnType<typeof psStore.getState>;
export type AppDispatch = typeof psStore.dispatch;
export const persistor = persistStore(psStore);
