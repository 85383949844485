import { Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { PsButton, TButton } from "../../../../lib/psElements/psElements";
import { TeacherAPIcalls } from "../../../../services/api/teacher/ProfileAPI";
import { useTeacherNavigation } from "../../../../services/hooks/teacher/navigationHooks";
import { RootState } from "../../../../services/storage/store";
import validationSchema from "../../../../services/validation/teacherValidation";
import { darkTheme } from "../../../../styles/psMuiTheme/colorCodes";
import { BankDetail } from "./BankDetail";
import { CorrectionschedulDetails } from "./CorrectionschedulDetails";
import { EducationDetails } from "./EducationDetails";
import { ExperienceDetails } from "./ExperienceDetails";
import { SubjectsTaught } from "./subjectsTaught";
export const Profile = () => {
  const teachernavigation = useTeacherNavigation();
  const {
    profile,
    // experience,
    // education,
    // Bankdetails,
    schedule,
    enrollment,
    ProfileData,
    Subject,
  }: any = useSelector((state: RootState) => state.TeacherprofileData);
  // const dispatch = useDispatch();
  const { fetchProfileget, updateProfile, fetchSubject, Getcertificate } =
    TeacherAPIcalls();
  useEffect(() => {
    fetchProfileget();
    fetchSubject();
  }, []);

  const handleCancel = () => {
    teachernavigation.navigateToTeacherDashboard();
  };

  const formik = useFormik({
    initialValues: {
      firstname: profile.firstName || "",
      lastname: profile.lastname || "",
      email: profile.email || "",
      Number: profile.countrycode || "",
      phone: profile.phone || "",
      Country: profile.country || "",
      State: profile.State || "",
      city: profile.city || "",
      Associate: profile.Associate || "School",
      currently: schedule.currently || "Active",
      allocatedInMonth: ProfileData?.schedule?.dailyLimit || "",
      allocatedInWeek: ProfileData?.schedule?.weeklyLimit || "",
      bankingName: ProfileData?.teacherBankingInfo?.bankingName || "",
      bankName: ProfileData?.teacherBankingInfo?.bankName || "",
      IFSC: ProfileData?.teacherBankingInfo?.ifscCode || "",
      branch: ProfileData?.teacherBankingInfo?.branch || "",
      AccountNumber: ProfileData?.teacherBankingInfo?.accountNo || "",
      PANno: ProfileData?.teacherBankingInfo?.panNo || "",
      PANCard: profile.pancard || "",
      panblobURL: profile.pancardBlobURL || "",
      educations: ProfileData?.educationHistory?.map((education: any) => {
        return {
          Degree: education.degree || "",
          Subject: education.subject || "",
          Year: education.year || "",
          certificateBlobURL: education.certificateBlobURL || "",
        };
      }),
      subjectTaught: ProfileData?.subjectsTaught || [],
      experience: ProfileData?.experienceHistory?.map((experience: any) => {
        return {
          Institutionname: experience.institutionName || "",
          Location: experience.location || "",
          DurationYear: experience.years || "",
          DuartionMonth: experience.months || "",
          ExperienceType: experience.type || "",
          Workinghere: experience.isWorkingHere || false,
        };
      }),
      enrollment: [
        {
          Board: enrollment.Board || "",
          Subject: enrollment.subject || "",
          grade: enrollment.grade || "9",
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      updateProfile(values);
    },
  });
  const handleSubmit = async (values: any) => {};

  const handleFileUploadChange = async (
    files: File | File[] | null,
    i: any,
  ) => {
    if (!files) {
      console.log("No files selected.");
    }

    const CertificateLink = await Getcertificate(files);

    console.log("CertificateLink", CertificateLink, i);
    const updatedEducations = formik.values.educations.map(
      (education: any, index: any) => {
        if (index === i) {
          return {
            ...education,
            certificateBlobURL: CertificateLink,
          };
        }
        return education;
      },
    );
    console.log("updatedEducations", updatedEducations);
    formik.setFieldValue("educations", updatedEducations);
    console.log("formik", formik);
    console.log(CertificateLink, "CertificateLink certificate");
  };

  const handlePanCardFile = async (files: File | File[] | null) => {
    if (files) {
      if (files) {
        console.log("files selected ");
      }
    } else {
      console.log("No files selected.");
    }
    const CertificateLink = await Getcertificate(files);
    console.log("CertificateLink pan ", CertificateLink);
    const updatedPan = formik.values.panblobURL;
    console.log(updatedPan, "updatedPan");
    formik.setFieldValue("panblobURL", CertificateLink);
  };
  console.log(formik.values.panblobURL, "panblobURL");

  const handlerInputChange = (e: any) => {
    formik.handleChange(e);
    const { name, checked } = e.target;
    if (name === "School" && checked) {
      formik.setFieldValue("WorkingType", "School");
    } else if (name === "Tution" && checked) {
      formik.setFieldValue("WorkingType", "Tution");
      // }
      // else if (name === "Workinghere") {
      //   formik.setFieldValue("Workinghere", checked ? "true" : "false");
    }
    // else if (name.startsWith("grade") && checked) {
    //   const grade = name.replace("grade", "");
    //   formik.setFieldValue("grade", grade);
    // }
  };

  return (
    <div
      style={{
        margin: "0px 0px 30px 0px ",
        padding: "50px",
        height: "100vh",
      }}
    >
      <Typography
        sx={{
          fontSize: "30px",
          textAlign: "start",
          margin: "20px 0px 0px 0px",
        }}
        fontWeight={400}
        variant="h6"
      >
        Profile Information
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <div
          style={{ backgroundColor: `${darkTheme.colors.teacher.theme.grey}` }}
        >
          {/* <PersonlDetailsForm
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
          /> */}
          {/* <Location
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
          /> */}
          {/* <Associate
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
          /> */}
          <EducationDetails
            formik={formik}
            handleFileUploadChange={handleFileUploadChange}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
            Subject={Subject}
            Getcertificate={Getcertificate}
          />
          <ExperienceDetails
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
          />
          <SubjectsTaught
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
            Subject={Subject}
          />
          {/* <EnrollmentDetails
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
          /> */}
          <CorrectionschedulDetails
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
          />
          <BankDetail
            formik={formik}
            handlerInputChange={handlerInputChange}
            handleSubmit={handleSubmit}
            handlePanCardFile={handlePanCardFile}
          />
        </div>
        <Stack
          direction={"row"}
          gap={1}
          sx={{
            mt: 4.5,
            justifyContent: "flex-end",
            px: 0,
            position: "sticky",
            bottom: "40px",
            zIndex: 1000,
            padding: "10px",
          }}
        >
          <PsButton.Outlined
            variant="contained"
            sx={{ width: 120 }}
            onClick={handleCancel}
          >
            Cancel
          </PsButton.Outlined>
          <TButton.Custom variant="contained" sx={{ width: 240 }} type="submit">
            Save
          </TButton.Custom>
        </Stack>
      </form>
    </div>
  );
};
